import React, { useEffect, useState } from "react";
import { Form, Table, Collapse } from 'react-bootstrap';
import { Loading, ModalContent, Pagination } from "../../../../common";

import { format } from "date-fns";

import StoriesDetail from './StoriesDetail';

import { url } from "../../../../config";
const axios = require("axios").default;

export default function Stories() {
    const [stories, setStories] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    const [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);

    const [detailData, setDetailData] = useState({});

    const [showFilter, setShowFilter] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        loadStories(0);
    }, [])

    const loadStories = (page, search = "") => {
        axios.get(url + "/private/rest/admin/story/all?page=" + page + "&size=10&search=" + search)
            .then(res => {
                setStories(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const openDetail = (data) => {
        if (data.id === detailData.id) {
            setDetailData({});
        }
        else {
            setDetailData(data);
        }
    }

    return (
        <>
            <button className="btn-kuzel secondary w-100 mb-3" onClick={() => setShowFilter(!showFilter)}>Filter</button>
            <div>
                <Collapse in={showFilter} className="w-100">
                    <div>
                        <div className="w-100 p-2 pt-0 mb-2">
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Názov</Form.Label>
                                <Form.Control size="md" type="text" required="{true}" name="name"
                                    onChange={e => {
                                        loadStories(0, e.currentTarget.value);
                                        setFilterValue(e.currentTarget.value);
                                    }} />
                            </Form.Group>
                        </div>
                    </div>
                </Collapse>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th className="col"></th>
                        <th className="col-4">Kužeľník</th>
                        <th className="col-4">Datum vytvorenia</th>
                        <th className="col-4">Popis</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='170px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            stories.data.map((story, index) => {
                                return (
                                    <>
                                        <tr key={story.id} onClick={() => openDetail(story)} role="button">
                                            <td>{index + 1 + stories.page * 10}.</td>
                                            <td>{story.creator.nickname}</td>
                                            <td>{format(new Date(story.created), 'dd.MM.yyyy HH:mm')}</td>
                                            <td>{story.description}</td>
                                        </tr>
                                        <tr key={story.id + "opc"} className="opacityHoverNone">
                                            <td colSpan="100%" className="border-0 p-0">
                                                <Collapse in={story.id === detailData.id} className="w-100">
                                                    <div>
                                                        {
                                                            story.id === detailData.id && (
                                                                <StoriesDetail initDetailData={detailData} loadStories={() => loadStories(stories.page, filterValue)} closeDetail={() => setDetailData({})}></StoriesDetail>
                                                            )
                                                        }
                                                    </div>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>

            <div className="d-flex justify-content-center">
                <Pagination page={stories.page} totalPages={stories.totalPage} onPageChange={page => loadStories(page, filterValue)} />
            </div>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}