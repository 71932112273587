import Rules from "../../../../resources/enums/rules";

export default function RulesModal() {
    return (
        <>
            <h4>Základné pojmy</h4>
            <ul>
                <li><b>kužeľ</b> - prezývaný aj kónus je <b>špicatý predmet s kruhovou základňou</b>, ktorej všetky body sa zbiehajú do jedného bodu</li>
                <li><b>kužeľníci</b> - všetci <b>lovci kužeľov</b>, ktorí sa zapojili do kužeľovej výzvy</li>
                <li><b>veľkužeľník</b> - zakladajúci členovia kužeľovej výzvy a zároveň členovia <b>Veľkužeľníckej rady</b> - <i> D.Fančovič, E.Hanzel, D.Dudek, L.Martinus</i></li>
                <li><b>kužeľová udalosť</b> - udalosť, pri ktorej kužeľník odfotením sa s kužeľom <b>obsadí územie</b> daného mesta/obce, v ktorom sa nachádza</li>
            </ul>
            <h4>Vytvorenie kužeľovej udalosti</h4>
            <ul>
                {
                    Rules.filter((val) => !val.hiddenRule).map((rule, index) => {
                        return (
                            <li key={index}>
                                <span dangerouslySetInnerHTML={{ __html: rule.text }}></span>
                            </li>
                        )
                    })
                }
            </ul>
            <h4>Právomoci Veľkužeľníckej rady</h4>
            <ul>
                <li><b>meniť a dopĺňať pravidlá</b> za účelom skvalitnenia kužeľovej výzvy</li>
                <li><b>vymazať kužeľovú udalosť</b>, ak nespĺňa niektoré z vyššie uvedených pravidiel</li>
                <li>vylúčiť kužeľníka z kužeľovej výzvy pri úmyselnom <b>porušení pravidiel</b></li>
            </ul>
        </>
    );
}