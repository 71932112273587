import React, { useState } from "react";
import { Form, Image, Modal } from 'react-bootstrap';
import { Store, Loading, ModalContent, Crop, getCroppiedImage } from "../../../../common";

import RulesModal from "../modals/RulesModal";

import Select, { StylesConfig } from "react-select";

import { url } from "../../../../config";
const axios = require("axios").default;

const styles: StylesConfig<ColourOption, true> = {
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    },
};

export default function AddCone({ lat, lng, city, country, setMarkersWithNewMarker, closeAddCone }) {
    let loggedKuzelnik = {
        id: Store.getObject("loggedUser").id,
        value: Store.getObject("loggedUser").id,
        nickname: Store.getObject("loggedUser").nickname,
        name: Store.getObject("loggedUser").name,
        isFixed: true
    };

    let [saveData, setSaveData] = useState({
        name: "",
        lng,
        lat,
        country,
        city,
        kuzelniks: [loggedKuzelnik.id],
        groups: [],
        imageFile: "",
        imageName: "",
        orientation: "PORTRAIT",
    });

    let [activeStep, setActiveStep] = useState(1);

    let [imageData, setImageData] = useState({ image: null, fileName: '', crop: null });

    let [modalConfig, setModalConfig] = useState({ show: false });

    let [loading, setLoading] = useState(false);

    let [groupsLoading, setGroupsLoading] = useState(true);
    let [groups, setGroups] = useState([]);

    let [kuzelniks, setKuzelniks] = useState([]);

    let [archivedKuzelniks, setArchivedKuzelniks] = useState([loggedKuzelnik]);
    let [actualValue, setActualValue] = useState('');

    const fetchKuzelniks = (text) => {
        if (text.length >= 2) {
            axios
                .get(url + `/private/rest/kuzelnik/find/` + text)
                .then((res) => {
                    let filtered = res.data.filter((e) => {
                        return e.id != loggedKuzelnik.value;
                    });
                    setKuzelniks(filtered);
                    setArchivedKuzelniks([...archivedKuzelniks, ...res.data]);
                    setActualValue(text);
                })
                .catch((err) => { });
        }
        else {
            setKuzelniks([]);
            setActualValue(text);
        }
    };

    const onImageCroppiedHandler = (result) => {
        setSaveData({
            ...saveData,
            imageFile: result,
            imageName: imageData.fileName,
            orientation: (imageData.crop.aspect === (4 / 3)) ? "LANDSCAPE" : "PORTRAIT",
        });

        setActiveStep(2);

        axios
            .get(url + `/private/rest/kuzel-group/available-groups/${saveData.kuzelniks}/${saveData.country}/${saveData.city}`)
            .then((res) => {
                setGroups(res.data);
                setGroupsLoading(false);
            })
            .catch((err) => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Chyba nahrávania kužeľovej udalosti !</div>,
                    footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                });
            })
    }

    const saveKuzel = () => {
        if (saveData.kuzelniks.length === 0) {
            setModalConfig({
                show: true,
                headerContent: null,
                bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nebol vybraný žiadny kužeľník !</div>,
                footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
            });
        }
        else if (imageData.image === null) {
            setModalConfig({
                show: true,
                headerContent: null,
                bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Chýba fotodokumentácia kužeľovej udalosti !</div>,
                footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
            });
        }
        else {
            getCroppiedImage(imageData, onImageCroppiedHandler, setModalConfig);
        }
    };

    const postKuzel = (saveData) => {
        if (saveData.groups.length) {
            setLoading('Ukladám');

            let selectedGroup = Store.getObject("selectedGroup").id > 0 ? Store.getObject("selectedGroup").id : '';

            axios
                .post(url + `/private/rest/kuzel?selectedGroupId=${selectedGroup}`, saveData)
                .then((res) => {
                    setMarkersWithNewMarker();
                    closeAddCone();
                })
                .catch((err) => {
                    setModalConfig({
                        show: true,
                        headerContent: null,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nepodarilo sa nahlásiť kužeľovú udalosť !</div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                })
                .then(() => {
                    setLoading(false);
                });
        } else {
            setModalConfig({
                show: true,
                headerContent: null,
                bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Treba zvoliť minimálne jednu komunitu !</div>,
                footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
            });
        }
    };

    const openRulesModal = () => {
        setModalConfig({
            show: true,
            size: 'xl',
            fullscreen: "md-down",
            headerContent: null,
            bodyContent: <RulesModal />,
            footerContent: <button className="btn-kuzel secondary w-100" onClick={() => setModalConfig({ show: false })}>Zavrieť</button>
        });
    };

    const goBackHandler = () => {
        setActiveStep(1);
        setGroupsLoading(true);
    };

    return (
        <Modal
            show={true}
            size='md'
            backdrop={false}
            className='fullHeight'
            fullscreen="sm-down"
            scrollable="false"
            centered>
            <Modal.Header className="py-2">
                <Modal.Title className="text-center w-100 color-kuzel h5">
                    Hlásenie kužeľovej udalosti
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="row mx-0">
                        <div className={"w-50 " + (activeStep == 1 ? 'bg-kuzel' : 'bg-light')} style={{ height: '5px' }}></div>
                        <div className={"w-50 " + (activeStep == 1 ? 'bg-light' : 'bg-kuzel')} style={{ height: '5px' }}></div>
                    </div>

                    <div className={(activeStep === 1) ? "" : "d-none"}>
                        <Form.Group className="my-2">
                            <Form.Label>Lokalizácia</Form.Label>
                            <Form.Control size="sm" value={city + ', ' + country.toUpperCase()} disabled />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Zúčastnení kužeľníci</Form.Label>
                            <Select
                                onChange={(e) => {
                                    setSaveData({ ...saveData, kuzelniks: e.map((anga) => anga.id) });
                                }}
                                placeholder={'Zadaj prezývku alebo meno'}
                                closeMenuOnSelect={false}
                                isMulti
                                getOptionLabel={(option) => (option.nickname + (option.name ? (' (' + option.name + ')') : ''))}
                                getOptionValue={(option) => option.id}
                                defaultValue={loggedKuzelnik}
                                isClearable={kuzelniks.some((v) => !v.isFixed)}
                                styles={styles}
                                options={kuzelniks}
                                onInputChange={(val) => fetchKuzelniks(val)}
                                noOptionsMessage={() => (actualValue.length >= 2) ? 'Žiadne výsledky vyhľadávania' : 'Zadaj minimálne 2 znaky pre vyhľadanie kužeľníka !'}
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Fotodokumentácia</Form.Label>
                            <Crop onImageChange={(imageData) => setImageData({ image: imageData.image, fileName: imageData.name, crop: imageData.crop })}></Crop>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Popis</Form.Label>
                            <Form.Control size="sm" onChange={(e) => setSaveData({ ...saveData, description: e.target.value })} maxLength="70" />
                        </Form.Group>
                    </div>

                    <div className={(activeStep === 2) ? "" : "d-none"}>
                        <div className="text-center my-2">
                            <Image src={saveData.imageFile}
                                className={saveData.orientation === "PORTRAIT" ? "col-9 col-md-6" : "col-12"}
                                alt="kuzel"
                                thumbnail
                            />
                        </div>
                        <div className="text-center" style={{ fontSize: '15px' }}><b>Lokalizácia: </b> {saveData.city + ', ' + saveData.country.toUpperCase()} </div>
                        <div className="text-center" style={{ fontSize: '15px' }}><b>Kužeľníci: </b> {saveData.kuzelniks.map(e => archivedKuzelniks.find(kuzel => kuzel.id === e)?.nickname).join(", ")} </div>
                        {saveData.description && (<div className="text-center" style={{ fontSize: '15px' }}><b>Popis: </b> {saveData.description} </div>)}
                        {
                            groupsLoading ? (
                                <Loading loadingType="spinner" marginTop="13px" marginBottom="0"></Loading>
                            ) : (
                                <Select
                                    onChange={(e) => {
                                        setSaveData({ ...saveData, groups: e.map((anga) => anga.id) });
                                    }}
                                    className="mt-3"
                                    menuPlacement="top"
                                    placeholder={'Vyber komunity'}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    //defaultValue={() => groups.find((group) => group.id == Store.getObject("selectedGroup").id)} 
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    options={groups}
                                    noOptionsMessage={() => 'Boli zvolené všetky dostupné komunity'}
                                />
                            )
                        }
                    </div>
                </>
            </Modal.Body>
            <Modal.Footer className="m-0 py-2">
                <div className="w-100">
                    {activeStep == 1 ? (
                        <>
                            <button className="btn-kuzel w-50 secondary" onClick={() => closeAddCone()}>Zrušiť</button>
                            <button className="btn-kuzel w-50" onClick={() => saveKuzel()}>Pokračovať</button>
                        </>
                    ) : (
                        <>
                            <button className="btn-kuzel w-50 secondary" onClick={() => goBackHandler()} >Zrušiť</button>
                            <button className="btn-kuzel w-50" onClick={() => postKuzel(saveData)}>Potvrdiť</button>
                        </>
                    )}
                </div>
                <div className="text-center w-100 mb-1" style={{ fontSize: '13px' }}>
                    <i>
                        <span>Potvrdením súhlasíš, že kužeľová udalosť je v súlade s </span>
                        <u role="button" onClick={() => openRulesModal(true)} className="opacityHover">kužeľovou chartou</u>
                    </i>
                </div>
            </Modal.Footer>

            {loading && (
                <Loading text={loading} marginTop="160px" loadingType="fullscreenCone"></Loading>
            )}

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </Modal>
    )
}