import React, { useEffect, useState, useRef } from "react";
import { Store, Geolocation, Loading, ModalContent, ConeImageDetail, ConeComponentsService } from "../../common";
import {
    MapContainer,
    TileLayer,
    Marker,
    useMap,
    useMapEvent,
} from "react-leaflet";
import { ZoomControl } from 'react-leaflet/ZoomControl'
import L from "leaflet";
import kuzelIcon from "../../resources/kuzel.svg";
import kuzelAddIcon from "../../resources/kuzelAdd.svg";
import kuzelAddDisabledIcon from "../../resources/kuzelAddDisabled.svg";
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import 'leaflet.markercluster';
import '../../resources/css/MarkerCluster.css';
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

import Main from "./mainElements/MainElements";
import ConeEventPopup from "./mainElements/popups/ConeEventPopup";
import AddCone from "./mainElements/popups/AddCone";

import { determineCity, normalizeCity } from "../../common/utils/CityHelper"

import { url } from "../../config";
import {defaultWidth} from "../../common/utils/ImageConstants";
const axios = require("axios").default;

const position = [48.3763, 17.587];

export default function Map() {
    let [actualMarker, setActualMarker] = useState(null);
    let [modalConfig, setModalConfig] = useState({ show: false });
    let [isPopupOrMenuOpened, setIsPopupOrMenuOpened] = useState(false);
    let [loading, setLoading] = useState(false);
    let [showAddCone, setShowAddCone] = useState(false);
    let [coneEventDetailData, setConeEventDetailData] = useState(null);

    const mapRef = useRef(null);

    useEffect(() => {
        Store.addListener("selectedGroup", loadKuzels);

        loadKuzels();

        window.addEventListener("map-set-view", (e) => { mapSetViewHandler(e) });
    }, []);

    const mapSetViewHandler = (e) => {
        const map = mapRef.current
        if (map) {
            if (e.detail) {
                var latlng = L.latLng(e.detail.lat, e.detail.lng);
                map.setView(latlng, 13);
            } else {
                var latlng = L.latLng(position);
                map.setView(latlng, 5);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("open-cone-event-popup", function (e) {
            let map = mapRef.current;

            map.eachLayer(function (layer) {
                if (!!layer.toGeoJSON) {
                    if (layer._latlng?.lat == e.detail.lat && layer._latlng?.lng == e.detail.lng) {
                        markerOpenHandler(layer);
                    }
                }
            });
        });
    }, [])


    const loadKuzels = (group) => {
        let groupId = group ? group.id : Store.getObject("selectedGroup").id;
        setActualMarker(null);
        setLoading(true);

        var map = mapRef.current;
        if (map) {
            map.eachLayer(function (layer) {
                if (!!layer.toGeoJSON) {
                    map.removeLayer(layer);
                }
            });
        }

        axios
            .get(url + `/private/rest/kuzel${groupId ? `/by-group/${groupId}` : ''}`)
            .then((res) => {
                let map = mapRef.current;
                let markers = L.markerClusterGroup({
                    disableClusteringAtZoom: 12,
                    maxClusterRadius: function (mapZoom) {
                        if (mapZoom > 10) {
                            return 20; //v pixeloch
                        } else {
                            return 43; //v pixeloch
                        }
                    }, 
                    //https://github.com/Leaflet/Leaflet.markercluster //dalšie možné properties 
                });
                for (let cone of res.data) {
                    let marker = L.marker(cone, { icon: KuzelIcon });
                    marker.options.config = cone;
                    markers.addLayer(marker);
                }
                map.addLayer(markers);

                markers.on('click', function (a) {
                    markerOpenHandler(a.layer);
                });

                markers.on('popupclose', function (a) {
                    setIsPopupOrMenuOpened(false);
                });

                markers.on('popupopen', function (a) {
                    setIsPopupOrMenuOpened(true);
                });
            })
            .catch((err) => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nepodarilo sa načítať</div>,
                    footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                });
            })
            .then(() => {
                setLoading(false);
            });
    }

    function createNodeElement(component) {
        const div = document.createElement("span");
        const root = createRoot(div);

        flushSync(() => {
            root.render(component);
        });

        return div.innerHTML;
    }

    function markerOpenHandler(layer) {
        window.openedMarkerLayer = layer;
        let config = layer.options.config;

        rewriteLayerPopup(config);

        axios.get(url + `/private/rest/kuzel/detail/${config.id}`)
            .then((res) => {
                config = { ...config, ...res.data };
                rewriteLayerPopup(config);
            })
            .catch((err) => { });

        axios.get(url + config.photo + (defaultWidth > 0 ? `?width=${defaultWidth}` : ""), { responseType: "blob" })
            .then(function (res) {
                var reader = new window.FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = function () {
                    config = { ...config, image: reader.result };
                    rewriteLayerPopup(config);
                }
            })
            .catch((err) => { });

    }

    function rewriteLayerPopup(config) {
        window.openedMarkerLayer.options.config = config;
        let nodeElement = createNodeElement(<ConeEventPopup data={config} image={config.image}></ConeEventPopup>);
        window.openedMarkerLayer.bindPopup(nodeElement).openPopup();

        //bindLikeCommentClickHandlers
        setTimeout(() => {
            let likeButton = document.getElementById('likeButton');
            let commentsButton = document.getElementById('commentsButton');

            if (likeButton) likeButton.addEventListener('click', likeClickHandler);
            if (commentsButton) commentsButton.addEventListener('click', commentsClickHandler);
        }, "300");
    }

    function likeClickHandler() {
        let config = window.openedMarkerLayer.options.config;
        ConeComponentsService.addUpdateOrRemoveLike(config.id, "LIKE", (likes) => {
            config.likes = likes;
            rewriteLayerPopup(config);
        });
    }

    function commentsClickHandler() {
        setConeEventDetailData(window.openedMarkerLayer.options.config);
    }

    function hideConeEventModalHandler(config) {
        setConeEventDetailData(null);
        rewriteLayerPopup(config);
    }

    const isKuzelFree = (city, countryCode, addressString, lat, lng) => {
        let groupId = Store.getObject("selectedGroup").id

        axios
            .get(url + `/private/rest/kuzel/is-free/${countryCode}/${city}${groupId ? `/${groupId}` : ''}`)
            .then((res) => {
                if (res.data.success) {
                    addConeMarker(lat, lng, city, countryCode);
                }
                else {
                    setActualMarker(null);
                    setModalConfig({
                        show: true,
                        headerContent: <span className="color-kuzel">{city + ', ' + countryCode.toUpperCase()}</span>,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{res.data.message}</div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const KuzelIcon = L.icon({
        iconUrl: kuzelIcon,
        iconSize: [40, 40],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
    });

    const KuzelAddIcon = L.icon({
        iconUrl: kuzelAddIcon,
        iconSize: [40, 40],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
    });

    const KuzelAddDisabledIcon = L.icon({
        iconUrl: kuzelAddDisabledIcon,
        iconSize: [40, 40],
        iconAnchor: [12, 12],
        popupAnchor: [0, 0],
    });

    function MapEvents() {
        var geocoder = L.Control.Geocoder.nominatim({ reverseQueryParams: { 'accept-language': 'en' } });

        const map = useMapEvent("contextmenu", (e) => {
            //addConeLoadingMarker
            setActualMarker(<Marker position={[e.latlng.lat, e.latlng.lng]} icon={KuzelAddDisabledIcon}></Marker>);

            geocoder.reverse(
                e.latlng,
                map.options.crs.scale(18),
                (results) => {
                    var r = results[0];
                    if (r) {
                        var address = r.properties.address;
                        var city = determineCity(address);
                        var countryCode = address.country_code;
                        var addressString = (address.county ?? '_').trim() + '-' + (address.region ?? '_').trim() + '-' + (address.state ?? '_').trim() + '-' + (address.province ?? '_').trim();

                        if (city !== undefined && countryCode !== undefined) {
                            isKuzelFree(normalizeCity(city), countryCode, addressString, e.latlng.lat, e.latlng.lng);
                        }
                    }
                }
            );
        });

        return false;
    }

    function Search() {
        const map = useMap();
        useEffect(() => {
            const provider = new OpenStreetMapProvider();

            const searchControl = new GeoSearchControl({
                provider,
                showMarker: false,
            });

            map.addControl(searchControl);

            return () => map.removeControl(searchControl);
        }, []);

        return null;
    }

    function addConeMarker(lat, lng, city, countryCode) {
        setActualMarker(
            <Marker position={[lat, lng]} icon={KuzelAddIcon} eventHandlers={{
                click: (e) => {
                    setShowAddCone({ lat, lng, city, countryCode });
                },
                popupclose: () => {
                    //setActualMarker(null)
                    //setIsPopupOrMenuOpened(false)
                },
                popupopen: () => {
                    //setIsPopupOrMenuOpened(true)
                },
            }}>
            </Marker>
        );
    }

    const closeAddConeHandler = () => {
        setActualMarker(null);
        setShowAddCone(false);
    }

    return (
        <>
            <MapContainer
                center={position}
                zoom={5}
                minZoom={2}
                maxZoom={17}
                maxBounds={[
                    [-90, -180],
                    [90, 180],
                ]}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ height: "100vh" }}
                ref={mapRef}
            >
                <TileLayer
                    noWrap={true}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {(isPopupOrMenuOpened === false) && (
                    <>
                        <ZoomControl></ZoomControl>
                        <Search />
                        <Geolocation />
                    </>
                )}

                <MapEvents />

                {actualMarker}
            </MapContainer>

            {/*toto tu nehaj aby bol hned na začiatku dotiahnuty kuželček*/}
            <img src="cone.png" alt="" hidden />

            <Main onChangeIsPopupOrMenuOpened={(e) => setIsPopupOrMenuOpened(e)} isPopupOrMenuOpened={isPopupOrMenuOpened} />

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>

            {coneEventDetailData && (
                <ConeImageDetail detailData={coneEventDetailData} onHideModal={(e) => hideConeEventModalHandler(e)} ></ConeImageDetail>
            )}

            {showAddCone && (
                <AddCone lat={showAddCone.lat} lng={showAddCone.lng} city={showAddCone.city} country={showAddCone.countryCode} setMarkersWithNewMarker={loadKuzels} closeAddCone={() => closeAddConeHandler()} />
            )}

            {loading && (
                <Loading text="Načítavam" marginTop="160px" loadingType="fullscreenCone"></Loading>
            )}
        </>
    );
}