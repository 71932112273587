import React, { useState, useEffect } from "react";
import { Toast, Modal } from 'react-bootstrap';
import { Store } from "../../../common";

import ConeEventsModal from "./modals/ConeEventsModal";
import RankingModal from "./modals/RankingModal";
import RulesModal from "./modals/RulesModal";
import AboutUsModal from "./modals/AboutUsModal";
import GroupsModal from "./modals/GroupsModal/GroupsModal";
import MerchModal from "./modals/MerchModal";
import CompetitionsModal from "./modals/CompetitionsModal/CompetitionsModal";

import GroupDropdown from "./dropdowns/GroupsDropdown";

import NotificationsCanvas from "./canvas/NotificationsCanvas";
import UserCanvas from "./canvas/UserCanvas/UserCanvas";
import FAQcanvas from "./canvas/FAQcanvas";
import WelcomeCanvas from "./canvas/WelcomeCanvas";

import Stories from "./stories/Stories";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'react-tooltip'

import { Menu, MenuItem, MenuHeader, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { url } from "../../../config";
const axios = require("axios").default;

export default function MainElements({ isPopupOrMenuOpened, onChangeIsPopupOrMenuOpened }) {
    let [modalConfig, setModalConfig] = useState({});

    let [banner, setBanner] = useState(null);

    useEffect(() => {
        initialShowBanner();

        window.addEventListener("close-modals-and-canvas", (e) => { setModalConfig({}) });
    }, [])

    const initialShowBanner = () => {
        axios.get(url + "/private/rest/custom-content/active")
            .then(res => {
                let banners = res.data;
                let visitedBanners = Store.getObject("visitedBanners");

                let currentDate = new Date();
                let currentDateString = currentDate.toISOString().substring(0, 10);

                //potrebujem včerajší den aby som zakázal aj ten
                currentDate.setDate(currentDate.getDate() - 1)
                let yesterdayDateString = currentDate.toISOString().substring(0, 10);

                for (let banner of banners) {
                    //banner sa zobrazí iba ak som ho dnes alebo včera ešte nezavrel (na danom zariadení)
                    if (!visitedBanners[banner.id] || (visitedBanners[banner.id] !== currentDateString && visitedBanners[banner.id] !== yesterdayDateString)) {
                        setBanner(banner);
                        continue;
                    }
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const hideBanner = () => {
        let visitedBanners = Store.getObject("visitedBanners");
        let currentDate = new Date();
        let currentDateString = currentDate.toISOString().substring(0, 10);

        Store.setObject("visitedBanners", { ...visitedBanners, [banner.id]: currentDateString });

        setBanner(null);
    }

    const createFullPostMarkup = () => {
        return { __html: banner.content }
    }

    return (
        <>
            <WelcomeCanvas setParentModalConfig={(e) => setModalConfig(e)} />

            {
                banner && (
                    <Toast onClose={() => hideBanner()} show={banner} style={{ position: 'absolute', bottom: '27px', right: '10px', zIndex: '1000', textAlign: 'left', backgroundColor: 'white' }}>
                        <Toast.Header>
                            <strong className="me-auto">{banner.title}</strong>
                        </Toast.Header>
                        <Toast.Body dangerouslySetInnerHTML={createFullPostMarkup()}>
                        </Toast.Body>
                    </Toast>
                )
            }

            <div className="navbarMenu left">
                <Menu onMenuChange={(e) => onChangeIsPopupOrMenuOpened(e.open)} menuButton={<FontAwesomeIcon className="mainIcon" icon="fa-solid fa-bars" />} transition>
                    <MenuHeader><FontAwesomeIcon icon="fa-regular fa-file-lines" className="me-2" />Základné informácie</MenuHeader>
                    <MenuItem onClick={() => setModalConfig({ show: 'O kužeľníkoch' })}>O kužeľníkoch</MenuItem>
                    <MenuItem onClick={() => setModalConfig({ show: 'Kužeľová charta' })}>Kužeľová charta</MenuItem>
                    <MenuDivider />
                    <MenuHeader><FontAwesomeIcon icon="fa-solid fa-arrow-down-wide-short" className="me-2" />Štatistiky</MenuHeader>
                    <MenuItem onClick={() => setModalConfig({ show: 'Kužeľové udalosti' })}>Prehľad udalostí</MenuItem>
                    <MenuItem onClick={() => setModalConfig({ show: 'Rebríček kužeľníkov' })}>Rebríček kužeľníkov</MenuItem>
                    <MenuDivider />
                    <MenuHeader><FontAwesomeIcon icon="fa-solid fa-people-group" className="me-2" />Komunity</MenuHeader>
                    <MenuItem onClick={() => setModalConfig({ show: 'Komunity' })}>Správa komunít</MenuItem>
                    <MenuItem className="hoverUnsetBackground">
                        <a href="https://www.facebook.com/kuzel.online" target="_blank" rel="noopener noreferrer" className="tooltip-cone-on-facebook">
                            <FontAwesomeIcon className="socialIcon" icon="fa-brands fa-facebook" />
                        </a>
                        <Tooltip anchorSelect=".tooltip-cone-on-facebook" place="top">
                            Kužeľ na Facebook-u!
                        </Tooltip>
                        {/*<FontAwesomeIcon onClick={() => setModalConfig({ show: 'Merch' })} className="socialIcon tooltip-merch" icon="fa-brands fa-shopify" />*/}
                        {/*<Tooltip anchorSelect=".tooltip-merch" place="top">*/}
                        {/*    Chceš náš merch?*/}
                        {/*</Tooltip>*/}
                    </MenuItem>
                </Menu>

                <GroupDropdown />
            </div>

            <div className="navbarMenu right">
                <NotificationsCanvas setParentModalConfig={(e) => setModalConfig(e)}></NotificationsCanvas>
                <UserCanvas setParentModalConfig={(e) => setModalConfig(e)} ></UserCanvas>
                <FAQcanvas setParentModalConfig={(e) => setModalConfig(e)} ></FAQcanvas>
            </div>

            {/*<div className={'navbarMenu right secondRow ' + (isPopupOrMenuOpened ? 'd-none' : '')}>*/}
            {/*<div className="competitions bg-kuzel opacityHover" onClick={() => setModalConfig({ show: 'Letné súťaže' })}>Letné súťaže</div>*/}
            {/*</div>*/}

            <Stories isPopupOrMenuOpened={isPopupOrMenuOpened} />

            <Modal
                show={modalConfig.show}
                size={modalConfig.size ?? 'xl'}
                fullscreen="md-down"
                scrollable="false"
                onHide={() => setModalConfig({ ...modalConfig, show: '' })}
                className='fullHeight'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100 color-kuzel">
                        {modalConfig.show}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalConfig.show === 'O kužeľníkoch' && (
                            <AboutUsModal />
                        )
                    }
                    {
                        modalConfig.show === 'Kužeľová charta' && (
                            <RulesModal />
                        )
                    }
                    {
                        modalConfig.show === 'Rebríček kužeľníkov' && (
                            <RankingModal />
                        )
                    }
                    {
                        modalConfig.show === 'Kužeľové udalosti' && (
                            <ConeEventsModal />
                        )
                    }
                    {
                        modalConfig.show === 'Merch' && (
                            <MerchModal />
                        )
                    }
                    {
                        modalConfig.show === 'Komunity' && (
                            <GroupsModal />
                        )
                    }
                    {
                        modalConfig.show === 'Letné súťaže' && (
                            <CompetitionsModal />
                        )
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}