import React, { useState } from "react";
import { Form, Image, Collapse } from 'react-bootstrap';
import { Loading, ModalContent, Crop, getCroppiedImage, GlobalFunctions } from "../../../../common";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../config";
import {defaultWidth} from "../../../../common/utils/ImageConstants";
const axios = require("axios").default;


export default function StoriesDetail({ initDetailData, loadStories, closeDetail }) {
    let [modalConfig, setModalConfig] = useState({ show: false });

    let [loading, setLoading] = useState(true);

    let [showPhoto, setShowPhoto] = useState(false);
    let [changePhoto, setChangePhoto] = useState(false);
    let [showAdditionalSettings, setShowAdditionalSettings] = useState(false);

    let [detailData, setDetailData] = useState(initDetailData);
    let [imageData, setImageData] = useState({ image: null, fileName: '', crop: null });

    let [image, setImage] = useState(null);

    const deleteStory = () => {
        let responseMessage = "";

        axios.delete(url + "/private/rest/admin/story/" + detailData.id)
           .then(res => {
               loadStories();
               responseMessage = "Vymazanie úspešné";
           })
           .catch(err => {
               responseMessage = "Vymazanie sa voláko nepodarilo";
               console.log(err)
           })
           .then(res => {
               setModalConfig({
                   show: true,
                   headerContent: null,
                   bodyContent: <>
                       <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>
                   </>,
                   footerContent: <>
                       <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                   </>
               });
           });
    }

    const deleteStoryModal = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vymazať toto dobrodružstvo ? </b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => deleteStory()}>Vymazať</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const refreshDetail = (data) => {
        axios
           .get(url + "/private/rest/admin/story/detail/" + data.id)
           .then((res) => {
               setDetailData(res.data);
               //setLoading(false);
           })
           .catch((err) => {
               setModalConfig({
                   show: true,
                   headerContent: null,
                   bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Nepodarilo sa načítať korektne</b></div>,
                   footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
               });
           });
    }

    const toggleAdditionalSettingsHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setShowAdditionalSettings(!showAdditionalSettings);
    }

    const togglePhotoHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!showPhoto) {
            if (!image) {
                axios.get(url + detailData.photo + (defaultWidth > 0 ? `?width=${defaultWidth}` : ""), { responseType: "blob" })
                    .then(function (res) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(res.data);
                        reader.onload = function () {
                            var imageDataUrl = reader.result;
                            setImage(imageDataUrl);

                            setShowPhoto(true);
                        }
                    })
            } else {
                setShowPhoto(true);
            }
        } else {
            setShowPhoto(false);
        }
    }

    const savePhotoModal = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš nahradiť fotku ? </b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => getCroppiedImage(imageData, onImageCroppiedHandler, setModalConfig)}>Nahradiť</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const onImageCroppiedHandler = (result) => {
        let data = { image: result, fileName: imageData.name, orientation: (imageData.crop.aspect === (4 / 3)) ? "LANDSCAPE" : "PORTRAIT" };

        let responseMessage = "";

        axios
            .put(url + `/private/rest/admin/story/update-photo/` + detailData.id, data)
            .then((res) => {
                //loadingDorobim...možno
                responseMessage = "Fotka úspešne nahradená";
                refreshDetail(detailData);
            })
            .catch((err) => {
                responseMessage = "Nepodarilo sa nahradiť fotku";
            })
            .then(res => {
                setShowPhoto(false);
                setChangePhoto(false);
                setImage(null);

                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            });
    };

    const saveDataHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());
        formDataObj = { ...detailData, ...formDataObj };
        setLoading(true);

        axios
           .put(url + `/private/rest/admin/story/` + detailData.id, formDataObj)
           .then((res) => {
               //loadingDorobim
               setLoading(false);
               loadStories();
               closeDetail();
           })
           .catch((err) => {
               console.log(err);
           });
    };

    return (
        <>
            <Form onSubmit={(val) => saveDataHandler(val)} className="border mb-3 mx-1 row adminDetail">
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Kužeľník</Form.Label>
                    <Form.Control size="md" type="text" disabled="disabled" defaultValue={detailData.creator.nickname} required="{true}" name="name" />
                </Form.Group>
                <Form.Group className="col-6 col-lg-3">
                    <Form.Label>Dátum vytvorenia</Form.Label>
                    <Form.Control size="md" type="date" defaultValue={detailData.created?.substring(0, 10)} required="{true}" disabled={true} name="createdDate" />
                </Form.Group>
                <Form.Group className="col-6 col-lg-3">
                    <Form.Label>Čas vytvorenia</Form.Label>
                    <Form.Control size="md" type="time" defaultValue={GlobalFunctions.getTime(detailData.created)} required="{true}" disabled={true} name="createdTime" />
                </Form.Group>
                <Form.Group className="col-12">
                    <Form.Label>Popis</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.description} name="description" />
                </Form.Group>
                <span className="mt-2">
                    <span className="text-white w-auto opacityHover" onClick={(e) => toggleAdditionalSettingsHandler(e)} role="button">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up" className={`me-2 ${showAdditionalSettings ? "rotate180deg" : "rotate0deg"}`} />
                        Rozšírené nastavenia
                    </span>
                </span>

                <Collapse in={showAdditionalSettings} className="w-100">
                    <div className="row mx-0 px-0">
                        <Form.Group className="col-12 col-lg-6">
                            <Form.Label>Adresa fotky</Form.Label>
                            <Form.Control size="md" type="text" defaultValue={detailData.photo} required="{true}" name="photo" />
                        </Form.Group>
                        <Form.Group className="col-12 col-lg-6">
                            <Form.Label>Orientácia fotky</Form.Label>
                            <Form.Control
                                as="select"
                                defaultValue={detailData.orientation}
                                name="orientation"
                            >
                                <option value="PORTRAIT">Na výšku</option>
                                <option value="LANDSCAPE">Na ležato</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Collapse>

                <div className="mt-3 mb-2">
                    <button type="submit" className="btn btn-success">Uložiť</button>
                    <button className="btn btn-primary ms-2" onClick={(e) => togglePhotoHandler(e)}>Fotka</button>
                    <button className="btn btn-light ms-2" onClick={() => closeDetail()}>Zrušiť</button>
                    <button className="btn btn-danger ms-2" onClick={(e) => deleteStoryModal(e)}>Vymazať</button>
                </div>

                <Collapse in={showPhoto} className="w-100">
                    <div className="justify-content-center mt-1 mb-3">
                        {
                            changePhoto ? (
                                <>
                                    <>
                                        <div className="row justify-content-center">
                                            <Form.Group className="my-2 px-0 bg-white rounded-2" style={{ maxWidth: '301px' }}>
                                                <Crop onImageChange={(data) => setImageData(data)}></Crop>
                                            </Form.Group>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-success me-2" onClick={(e) => savePhotoModal(e)}>Uložiť</button>
                                            <button className="btn btn-secondary" onClick={() => setChangePhoto(false)}>Zrušiť</button>
                                        </div>
                                    </>
                                </>
                            ) : (
                                <>
                                    <Image
                                        src={image}
                                        className="mb-3"
                                        style={{ height: detailData.orientation === 'PORTRAIT' ? '420px' : 'unset', width: detailData.orientation === 'PORTRAIT' ? 'unset' : '100%', aspectRatio: detailData.orientation === 'PORTRAIT' ? 3 / 4 : 4 / 3 }}
                                        alt={detailData.city + " kuzel"}
                                        thumbnail
                                    />
                                    <div>
                                        <button className="btn btn-secondary me-2" onClick={(e) => setChangePhoto(true)}>Nahradiť fotku</button>
                                            <button className="btn btn-light" onClick={(e) => setShowPhoto(false)}>Zrušiť</button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Collapse>
            </Form>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}