import React, { useState } from "react";
import { Form } from 'react-bootstrap';
import { Store } from "../../../../common";

import Select from "react-select";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function RoleDetail({ id, propsData, callback, closeDetail }) {
    const [data, setData] = useState(propsData ? propsData : {
        name: "",
        description: "",
        permissions: [],
        accesses: []
    });

    const permissions = Store.getObject("role-permissions").map(perm => {
        return { value: perm.name, label: `${perm.name} (${perm.description})` }
    })
    const accesses = Store.getObject("role-accesses").map(access => {
        return { value: access.name, label: `${access.name} (${access.description})` }
    })

    const saveNewRole = (saveData) => {
        axios.post(url + "/private/rest/admin/role", saveData)
            .then(() => {
                if (callback) callback();
            })
            .catch(err => {
                console.log(err)
            });
    }

    const updateRole = (saveData) => {
        axios.put(url + "/private/rest/admin/role/" + id, saveData)
            .then(() => {
                if (callback) callback();
            })
            .catch(err => {
                console.log(err)
            });
    }

    const onDelete = () => {
        axios.delete(url + "/private/rest/admin/role/" + id)
            .then(() => {
                if (callback) callback();
            })
            .catch(err => {
                console.log(err)
            });
    }
    const onSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation(); 

        let saveData = processData();

        if (id && id > 0)
            updateRole(saveData);
        else
            saveNewRole(saveData);
    }

    const processData = () => {
        return {
            name: data.name,
            description: data.description,
            accesses: data.accesses.map(e => e.value),
            permissions: data.permissions.map(e => e.value)
        }
    }

    const setValue = (key, value) => {
        setData({ ...data, [key]: value });
    }

    return (
        <div>
            <Form onSubmit={(val) => onSubmitHandler(val)} className="border mb-3 mx-1 row adminDetail">
                <Form.Group className="col-6 col-lg-6">
                    <Form.Label>Názov</Form.Label>
                    <Form.Control onChange={e => setValue("name", e.currentTarget.value)} value={data.name} size="md"
                        type="text" required="{true}" name="name" />
                </Form.Group>
                <Form.Group className="col-6 col-lg-6">
                    <Form.Label>Popis</Form.Label>
                    <Form.Control onChange={e => setValue("description", e.currentTarget.value)}
                        value={data.description} size="md" type="textarea" name="description" />
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Label>Práva</Form.Label>
                    <Select
                        onChange={(e) => setValue("permissions", e)}
                        closeMenuOnSelect={false}
                        isMulti
                        value={data.permissions}
                        placeholder={'Vyberte práva'}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        options={permissions}
                    />
                </Form.Group>

                <Form.Group className="mb-2">
                    <Form.Label>Prístupy</Form.Label>
                    <Select
                        onChange={(e) => setValue("accesses", e)}
                        closeMenuOnSelect={false}
                        isMulti
                        value={data.accesses}
                        placeholder={'Vyberte prístupy'}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        options={accesses}
                    />
                </Form.Group>
                <div className="mt-3 mb-2">
                    <button type="submit" className="btn btn-success">Uložiť</button>
                    <button className="btn btn-light ms-2" onClick={() => closeDetail()}>Zrušiť</button>
                    {id && id > 0 ?
                        <button className="btn btn-danger ms-2">Vymazať</button> : null}
                </div>
            </Form>
        </div>
    )
}

