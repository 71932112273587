import "./resources/css/App.css";
import { Routes, Route } from "react-router-dom";

import Admin from "../src/components/admin/Admin";
import AdminHome from '../src/components/admin/adminModules/home/Home';
import Users from '../src/components/admin/adminModules/users/Users';
import Groups from '../src/components/admin/adminModules/groups/Groups';
import Stories from '../src/components/admin/adminModules/stories/Stories';
import Banners from '../src/components/admin/adminModules/banners/Banners';
import ConeEvents from '../src/components/admin/adminModules/coneEvents/ConeEvents';
import GeneralFunctions from '../src/components/admin/adminModules/generalFunctions/GeneralFunctions';

import UserLogged from "./common/utils/UserLogged";

import Map from "../src/components/main/Map";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import {RouterChecker} from "./RouterChecker";
import Roles from "./components/admin/adminModules/roles/Roles";
import {localHostConf} from "./config";

const axios = require("axios").default;

if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !localHostConf.enabled) {
    axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
    axios.defaults.withCredentials = false;
} else {
    axios.defaults.withCredentials = true;
}

function App() {
    const basicRoutes = <>
        <Route index element={<Map />} />
    </>

    const adminRoutes = <>
        <Route path="admin" element={<Admin />}>
            <Route path="home" element={<AdminHome />} />
            <Route path="users" element={<Users />} />
            <Route path="cone-events" element={<ConeEvents />} />
            <Route path="stories" element={<Stories />} />
            <Route path="groups" element={<Groups />} />
            <Route path="banners" element={<Banners />} />
            <Route path="roles" element={<Roles />} />
            <Route path="generalFunctions" element={<GeneralFunctions />} />
        </Route>
    </>

    return (
        <div className="App">
            <UserLogged>
                <RouterChecker
                    basicRoutes={basicRoutes}
                    adminRoutes={adminRoutes}
                >
                </RouterChecker>
            </UserLogged>
        </div>
    );
}

export default App;
library.add(fab, fas, far)