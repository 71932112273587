import React, { useState } from "react";
import Avatar from 'react-avatar';
import Store from "./utils/Store";
import GlobalFunctions from "./utils/GlobalFunctions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ConeComment({ data, index, deleteComment }) {
    const [userId, setUserId] = useState(Store.getObject("loggedUser").id);

    return (
        <div className={"cone-comment " + (index > 0 ? "mt-2" : "")}>
            <div className="d-inline-flex w-100">
                <Avatar name={data.author.nickname} size="30" textSizeRatio={2} round />
                <div className="ms-1 w-100">
                    <div className="cone-comment-text">
                        <div className="w-100 fw-bold mb-1 d-flex justify-content-between">
                            {data.author.nickname}
                            {
                                userId == data.author.id && (
                                    <FontAwesomeIcon className="opacityHover text-muted" onClick={() => deleteComment(data)} icon="fa-solid fa-trash" />
                                )
                            }
                        </div>
                        <div className={"w-100"}>
                            {data.comment}
                        </div>
                    </div>
                    <div className="w-100 cone-comment-date">
                        {GlobalFunctions.getDateText(data.created)}
                    </div>

                </div>
            </div>
        </div>
    )
}