import React from "react";
import { Link } from "react-router-dom";

export default function ResetPasswordSuccess() {
    return (
        <>
            <h4 className="mb-3 fs-2">Heslo úspešne zmenené</h4>
            <div className="fs-5">Môžeš sa <Link to='/'>prihlásiť tu</Link></div>
        </>
    );
}