import React, { useEffect, useState } from "react";
import { Table } from 'react-bootstrap';
import { Loading, Pagination } from "../../../../../../common";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../../../config";
const axios = require("axios").default;

export default function PublicGroups({ onClickShowDetail }) {
    const [publicGroups, setPublicGroups] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadPublicGroups(0);
    }, [])

    const loadPublicGroups = (page) => {
        axios.get(url + "/private/rest/kuzel-group/public-groups/detailed?page=" + page + "&size=5")
            .then(res => {
                setPublicGroups(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            });
    }

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th className="col-5">Názov</th>
                        <th className="col-3">Počet členov</th>
                        <th className="col-3 d-none d-lg-table-cell">Počet udalostí</th>
                        <th className="col-2 col-lg-1"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='80px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            publicGroups.data.map((group, index) => {
                                return (
                                    <tr key={index} onClick={() => onClickShowDetail(group)} role="button">
                                        <td>{group.name}</td>
                                        <td>{group.membersCount}</td>
                                        <td className="d-none d-lg-table-cell">{group.kuzelCount}</td>
                                        <td><FontAwesomeIcon className="text-secondary" icon="fa-solid fa-people-group"/></td>
                                    </tr>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>

            <div className="d-flex justify-content-center">
                <Pagination page={publicGroups.page} totalPages={publicGroups.totalPage} onPageChange={page => loadPublicGroups(page)} />
            </div>
        </>
    )
}