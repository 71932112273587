import React, { useState } from "react";
import { Form, Collapse } from 'react-bootstrap';
import { Store } from "../../../../../../common";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../../../config";
const axios = require("axios").default;

export default function Useranvas() {
    let [showSettings, setShowSettings] = useState(false);

    const handleSubmitChangeName = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());

        axios
            .put(url + `/private/rest/kuzelnik/change-name`, formDataObj)
            .then((res) => {
                if (res.data.success) {
                    setShowSettings(false);
                    Store.setObject("loggedUser", { ...Store.getObject("loggedUser"), name: formDataObj.name  })
                }
            })
            .catch((err) => {
                console.log(err)
            });
    };

    return (
        <>
            <button
                onClick={() => setShowSettings(!showSettings)}
                aria-expanded={showSettings}
                className="btn-kuzel secondary w-100 my-1"
            >
                <FontAwesomeIcon icon="fa-solid fa-user-gear" className="me-2" />Nastavenia profilu
            </button>
            <Collapse in={showSettings}>
                <div>
                    <Form className="p-2 my-2 border" onSubmit={handleSubmitChangeName} >
                        {/*<div className="form-check form-switch mt-1 mb-2">*/}
                        {/*    <input className="form-check-input" type="checkbox" role="switch" id="darkMode" />*/}
                        {/*    <label className="form-check-label" htmlFor="darkMode">Tmavý režim</label>*/}
                        {/*</div>*/}
                        {/*<div className="form-check form-switch mb-2">*/}
                        {/*    <input className="form-check-input" type="checkbox" role="switch" id="pushNotification" defaultChecked />*/}
                        {/*    <label className="form-check-label" htmlFor="pushNotification">Push notifikácie</label>*/}
                        {/*</div>*/}
                        {/*<div className="form-check form-switch mb-2">*/}
                        {/*    <input className="form-check-input" type="checkbox" role="switch" id="newsletter" defaultChecked />*/}
                        {/*    <label className="form-check-label" htmlFor="newsletter">Odoberať newsletter</label>*/}
                        {/*</div>*/}
                        {/*<Form.Group className="mb-2">*/}
                        {/*    <Form.Label>Jazyk</Form.Label>*/}
                        {/*    <select className="form-control" id="language" defaultValue='1' onChange={(e) => console.log(e)}>*/}
                        {/*        <option value='1'>Slovenský</option>*/}
                        {/*        <option value='2'>Anglický</option>*/}
                        {/*        <option value='3'>Rumunský</option>*/}
                        {/*    </select>*/}
                        {/*</Form.Group>*/}
                        <Form.Group className="mb-2">
                            <Form.Label>Prezývka</Form.Label>
                            <Form.Control size="md" type="text"
                                defaultValue={Store.getObject("loggedUser").nickname}
                                name="name"
                                disabled="disabled"
                            />
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Meno a priezvisko</Form.Label>
                            <Form.Control size="md" type="text"
                                defaultValue={Store.getObject("loggedUser").name}
                                name="name"
                            />
                        </Form.Group>
                        <button type="submit" className="btn-kuzel mt-2 w-100">Uložiť</button>
                    </Form>
                </div>
            </Collapse>
        </>
    );
}