import React, { useState } from "react";
import { Store } from "../../../common";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { localHostConf, url } from "../../../config";
const axios = require("axios").default;

export default function Loggin({ onSignIn }) {
    const [loginData, setLoginData] = useState({
        username: "",
        password: "",
        rememberMe: true,
        errorMessage: ""
    });

    const [showRepeatVerificationEmailButton, setShowRepeatVerificationEmailButton] = useState(false);
    const [showRepeatVerificationEmailSuccess, setShowRepeatVerificationEmailSuccess] = useState(false);
    const [publicId, setPublicId] = useState("");

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            signIn();
        }
    };

    const signIn = () => {
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization']

        axios
            .post(url + "/authenticate", loginData)
            .then((res) => {
                Store.setObject("loggedUser", res.data);

                if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !localHostConf.enabled) {
                    localStorage.setItem("token", res.data.token);
                    axios.defaults.headers.common['Authorization'] = res.data.token;
                }

                onSignIn();
            })
            .catch(err => {
                if (err.response.status === 406) {
                    //verifikacia vypršala - tak požiadam o novy mail
                    setShowRepeatVerificationEmailButton(true);
                    setPublicId(err.response.data.body.publicId);
                } else {
                    setLoginData({...loginData, errorMessage: "Nesprávne meno alebo heslo"})
                }
            });
    };

    const requestNewVerificationEmail = () => {
        axios
            .put(url + "/public/rest/registration/repeat-verification", {id: publicId})
            .then((res) => {
                setShowRepeatVerificationEmailButton(false);
                setShowRepeatVerificationEmailSuccess(true);
            })
            .catch(() => {

            });
    };

    return (
        <>
            <span className="errorMessage">
                {loginData.errorMessage && (
                    <>
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" className="me-1" />
                        {loginData.errorMessage}
                    </>
                )}
                <span style={{ userSelect: 'none' }}>{'\u00A0'}</span>
            </span>

            <span>
                {showRepeatVerificationEmailButton && (
                    <div className="mb-2">
                        <span>Váš účet ešte nie je aktivovaný.</span><br/>
                        Ak Vám neprišiel email môžete <a onClick={requestNewVerificationEmail}><u>požiadať o nový verifikačný email</u></a>
                    </div>
                )}
            </span>

            <span>
                {showRepeatVerificationEmailSuccess && (
                    <div className="mb-2">Nová žiadosť o verifikáciu úspešne odoslaná na Váš email</div>
                )}
            </span>

            <input
                type="text"
                placeholder="email"
                name="username"
                onChange={(e) => setLoginData({ ...loginData, username: e.target.value, errorMessage: "" })}
            />
            <input
                type="password"
                placeholder="heslo"
                name="password"
                onChange={(e) => setLoginData({ ...loginData, password: e.target.value, errorMessage: "" })}
                onKeyDown={handleKeyDown}
            />
            <input type="button" value="Prihlásiť" onClick={signIn} />

            <div className="mt-2"><Link to='/reset-password-request'>Zabudol som heslo</Link> </div>
            <div className="color-kuzel mt-1">Nemáš ešte učet? <Link to='/registration'>Zaregistruj sa</Link></div>
        </>
    );
}