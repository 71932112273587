const rules = [
    {
        code: 1,
        text: "kužeľ - prezývaný aj kónus je špicatý predmet s kruhovou základňou, ktorej všetky body sa zbiehajú do jedného bodu",
        hiddenRule: true,
    },
    {
        code: 10,
        text: "pre každú <b>obec/mesto</b> je možné pridať iba <b>jeden kužeľ</b> - nedelíme na mestské časti - napr. kužeľ z Petržalky patrí pod Bratislavský kužeľ",
    },
    {
        code: 20,
        text: "kužeľ sa musí nachádzať v <b>intraviláne</b> - súvislo zastavané územie vrátane vnútorných cestných komunikácií, plôch zelene, technickej a občianskej vybavenosti). Do intravilánu nespadajú osamelé sídla (umelé roztrúsené osady, samoty, chalupy ap.) a priemyselné alebo hospodárske stavby",
    },
    {
        code: 30,
        text: "nieje povolené <b>prenášať</b> kužeľ za účelom získania fotky v novej lokalite",
    },
    {
        code: 40,
        text: "nieje povolené fotiť sa <b>za jazdy</b> z auta, lietadla či <b>spoza plota</b> - musí byť preukázateľné, že kužeľník bol schopný <b>dotknúť</b> sa kužeľa",
    },
    {
        code: 50,
        text: "nieje povolené fotiť sa s kužeľom, ktorý je umiestnený na <b>letisku</b>, na <b>čerpacej stanici</b> a s takým, ktorý je na predaj v <b>obchode</b> (napr. športové potreby, cestné potreby...)",
    },
    {
        code: 60,
        text: "na fotke musí byť viditeľná <b>nadpolovičná </b>časť<b> hlavy</b> kužeľníka",
    },
    {
        code: 70,
        text: "na fotke musí byť viditeľná <b>nadpolovičná </b>časť plochy<b> kužeľa</b>",
    },
    {
        code: 80,
        text: "minimálna výška kužeľa je <b>20 centimetrov</b>",
    },
    {
        code: 90,
        text: "je potrebné byť od kužeľu vzdialený maximálne <b>3 metre</b> (primerane ďaleko aby netrpela kompozícia fotky - ale zároveň nie príliš ďaleko)",
    },
    {
        code:100,
        text: "na každej kužeľovej udalosti sa môže zúčastniť <b>viacero kužeľníkov</b> - je potrebné ich označiť pri hlásení kužeľovej udalosti",
    },
    {
        code: 110,
        text: "vždy musí byť v záujme kužeľníka aby <b>prizval ostatných kužeľníkov</b> na vytvorenie fotky, ak sú v jeho blízkosti (spoločná dovolenka a pod.)",
    },
    {
        code: 120,
        text: "na fotke sa <b>vyhýbame</b> rasizmu, antisemitizmu, prejavom odsudzujúcich menšiny a rôznych iných vulgarizmov",
    },
    {
        code: 130,
        text: "ku kužeľu sa správame s <b>úctou</b>, ctíme si jeho poslanie a v žiadnom prípade neprispievame k jeho znehodnoteniu"
    },
];

export default rules;
