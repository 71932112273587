import React, { useEffect, useState } from "react";
import { Offcanvas, Button, Tab, Tabs } from 'react-bootstrap';
import { ConeImageDetail, Loading, ModalContent, GlobalFunctions } from "../../../../common";

import InfiniteScroll from "react-infinite-scroll-component";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as KuzelLikeIcon } from "../../../../resources/kuzelLike.svg";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function NotificationsCanvas({ setParentModalConfig }) {
    const [showCanvas, setShowCanvas] = useState(false);

    const [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);

    const [detailData, setDetailData] = useState(null);

    const [activities, setActivities] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });
    const [requests, setRequests] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    let [numberOfNotifications, setNumberOfNotifications] = useState(0);
    let [numberOfUnreadedActivities, setNumberOfUnreadedActivities] = useState(0);
    let [numberOfUnreadedRequests, setNumberOfUnreadedRequests] = useState(0);

    useEffect(() => {
        axios
            .get(url + `/private/rest/notification/unseen-count`)
            .then((res) => {
                setNumberOfNotifications(res.data.activities + res.data.requests)
                setNumberOfUnreadedActivities(res.data.activities)
                setNumberOfUnreadedRequests(res.data.requests)
            })
            .catch((err) => { });

        window.addEventListener("close-modals-and-canvas", () => { setShowCanvas(false) });
    }, [])

    const handleAction = (action, id, index) => {
        axios
            .put(url + "/private/rest/notification/resolve/" + id, action)
            .then((res) => {
                let requestsNew = { ...requests };
                requestsNew.data[index].resolved = true;
                requestsNew.data[index].resolvedMessage = res.data.result;
                setRequests(requestsNew);
                setNumberOfUnreadedRequests(res.data.unseenCount);
                setNumberOfNotifications(res.data.unseenCount + numberOfUnreadedActivities);

                //reload mojich komunit po reakcii na pozvanku do komunity
                if (action.type === 'INVITATION_TO_GROUP') {
                    window.dispatchEvent(new CustomEvent("reload-my-groups"));
                }
            })
    }

    const onChangeIsOpened = () => {
        setNumberOfNotifications(null);
        setShowCanvas(true);
    }

    const loadData = (type, page = 0, reload) => {
        if (reload) setLoading(true);

        axios
            .get(url + `/private/rest/notification/${type}?page=${page}&size=15`)
            .then((res) => {
                if (type === 'activities') {
                    if (reload) {
                        setActivities(res.data.content);
                    } else {
                        let newActivities = { ...res.data.content, data: [...activities.data, ...res.data.content.data] };
                        setActivities(newActivities);
                    }
                    setNumberOfUnreadedActivities(res.data.count)
                    setNumberOfNotifications(res.data.count + numberOfUnreadedRequests)
                } else if (type === 'requests') {
                    if (reload) {
                        setRequests(res.data.content);
                    } else {
                        let newRequests = { ...res.data.content, data: [...requests.data, ...res.data.content.data] };
                        setRequests(newRequests)
                    }
                    setNumberOfUnreadedRequests(res.data.count)
                    setNumberOfNotifications(res.data.count + numberOfUnreadedActivities)
                }
                setLoading(false)
            })
            .catch((err) => {
            });
    }

    const handleClickNotificationMessage = (e) => {
        switch (e.source) {
            case 'KUZEL_COMMENT':
            case 'KUZEL_LIKE':
            case 'KUZEL_CREATION':
                openConeDetail(e.sourceData);
                break;
            case 'KUZEL_DELETE':
                setParentModalConfig({ show: "Kužeľová charta" })
                break;
            case 'KUZEL_GROUP':
            default:
            //nic take konkretne
        }
    };

    const openConeDetail = (notificationData) => {
        //tu dojde photo namiesto kuzelPhoto 
        setDetailData({ photo: notificationData.kuzelPhoto });

        axios.get(url + `/private/rest/kuzel/detail/${notificationData.kuzelId}`)
            .then((res) => {
                setDetailData({ photo: notificationData.kuzelPhoto, groupId: notificationData.groupId, ...res.data });
            })
            .catch((err) => { });
    };

    return (
        <>
            <FontAwesomeIcon className="mainIcon" onClick={() => onChangeIsOpened()} icon="fa-regular fa-bell" />
            {
                numberOfNotifications > 0 && (
                    <span className="numberOfNotifications"> {numberOfNotifications}</span>
                )
            }
            <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} onShow={() => loadData('activities', 0, true)}
                placement="end"
                className="notificationsContent">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Upozornenia</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body id={"scrollableDiv"} style={{ overflow: "auto" }}>
                    <Tabs
                        defaultActiveKey="activities"
                        id="notification-tab"
                        onSelect={e => loadData(e, 0, true)}
                        fill
                    >
                        <Tab eventKey="activities" title={
                            <span>Aktivita
                                {numberOfUnreadedActivities > 0 ?
                                    <span className="numberOfNotifications">
                                        <span className="number">{numberOfUnreadedActivities}</span>
                                    </span> : ""}
                            </span>
                        }>
                            <InfiniteScroll
                                dataLength={activities.data.length} //This is important field to render the next data
                                next={() => loadData('activities', activities.page + 1)}
                                hasMore={activities.page < activities.totalPage - 1}
                                scrollableTarget="scrollableDiv"
                            >
                                {
                                    loading ? (
                                        <div className="text-center my-5">
                                            <Loading marginTop='100px'></Loading>
                                        </div>) : (
                                        activities.data.length > 0 ? (
                                            activities.data.map((e, index) => {
                                                return (
                                                    <div key={index} className="mt-3 mb-1 opacityHover" role="button" style={{ fontSize: "14px" }} onClick={() => handleClickNotificationMessage(e)}>
                                                        <div className="d-inline-flex">
                                                            <div style={{ minWidth: "75px", margin: "auto" }}
                                                                className="text-center">
                                                                <Icon e={e}></Icon>
                                                            </div>
                                                            <div>
                                                                <div className="notificationText" dangerouslySetInnerHTML={{ __html: e.body }}></div>
                                                                <div className='d-flex justify-content-end mt-1 me-1 mb-0'>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='d-flex w-100 justify-content-end mt-1 me-1 mb-0'>
                                                            <small><em>{GlobalFunctions.getDateText(e.created)}</em></small>
                                                        </div>
                                                        <div className="szh-menu__divider m-0"></div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="d-flex justify-content-center mt-4">
                                                <i>Nebola nahlásená žiadna aktivita</i>
                                            </div>
                                        )
                                    )
                                }
                            </InfiniteScroll>
                        </Tab>
                        <Tab eventKey="requests" title={
                            <span>Žiadosti
                                {numberOfUnreadedRequests > 0 ?
                                    <span className="numberOfNotifications">
                                        <span className="number">{numberOfUnreadedRequests}
                                        </span>
                                    </span> : ""}
                            </span>
                        }>
                            <InfiniteScroll
                                dataLength={requests.data.length} //This is important field to render the next data
                                next={() => loadData('requests', requests.page + 1)}
                                hasMore={requests.page < requests.totalPage - 1}
                                scrollableTarget="scrollableDiv"
                            >
                                {
                                    loading ? (
                                        <div className="text-center my-5">
                                            <Loading marginTop='100px'></Loading>
                                        </div>) : (
                                        requests.data.length > 0 ? (
                                            requests.data.map((e, index) => {
                                                return (
                                                    <div key={index} className="mt-3 mb-1" style={{ fontSize: "14px" }}>
                                                        <div className="d-inline-flex">
                                                            <div style={{ minWidth: "75px", margin: "auto" }} className="text-center">
                                                                <Icon e={e}></Icon>
                                                            </div>
                                                            <div>
                                                                {e.title && e.title !== '' ? <div className="w-100 fw-bold">{e.title}</div> : null}
                                                                <div className="notificationText" dangerouslySetInnerHTML={{ __html: e.body }}></div>
                                                                <div className='d-flex justify-content-end mt-1 me-1 mb-0'>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-end mt-1 me-1 mb-0">
                                                            {e.resolved ?
                                                                <span className="fw-bold">{e.resolvedMessage}</span>
                                                                :
                                                                e.actions.map((action, actionIndex) => {
                                                                    return (
                                                                        <Button key={actionIndex}
                                                                            className={`btn-${action.color} btn-sm ms-1`}
                                                                            onClick={() => handleAction(action, e.id, index)}
                                                                        >{action.text}</Button>
                                                                    )
                                                                })}
                                                        </div>

                                                        <div className='d-flex justify-content-end mt-1 me-1 mb-0'>
                                                            <small><em>{GlobalFunctions.getDateText(e.created)}</em></small>
                                                        </div>
                                                        <div className="szh-menu__divider m-0"></div>
                                                    </div>
                                                )
                                            })
                                        ) : (
                                            <div className="d-flex justify-content-center mt-4">
                                                <i>Nemáš žiadne žiadosti</i>
                                            </div>
                                        )
                                    )
                                }
                            </InfiniteScroll>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
            </Offcanvas>
            {
                detailData && (
                    <ConeImageDetail detailData={detailData} onHideModal={() => setDetailData(null)} ></ConeImageDetail>
                )
            }

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );

    function Icon({ e }) {
        const [iconType, setIconType] = useState();

        useEffect(() => {
            switch (e.source) {
                case 'KUZEL_LIKE':
                    setIconType('likeIcon');
                    break;
                case 'KUZEL_COMMENT':
                    setIconType('commentIcon');
                    break;
                case 'KUZEL_GROUP':
                    setIconType('groupIcon');
                    break;
                case 'KUZEL_DELETE':
                    setIconType('rejectIcon');
                    break;
                case 'KUZEL_CREATION':
                default:
                    setIconType('coneIcon');
            }
        }, [e])

        return (
            <>
                {
                    iconType == "coneIcon" && (
                        <img width="40px" src="cone.png" alt="kuzelIcon" />
                    )
                }
                {
                    iconType == "likeIcon" && (
                        <KuzelLikeIcon className="kuzelLikeActive" width="35px" height="35px" />
                    )
                }
                {
                    iconType == "groupIcon" && (
                        <img width="50px" src="groups.png" alt="groupIcon" />
                    )
                }
                {
                    iconType == "commentIcon" && (
                        <img width="35px" src="comment.png" alt="commentIcon" />
                    )
                }
                {
                    iconType == "requestIcon" && (
                        <img width="40px" src="request.png" alt="kuzelIcon" />
                    )
                }
                {
                    iconType == "rejectIcon" && (
                        <FontAwesomeIcon icon="fa-solid fa-xmark" className="color-kuzel" size="3x" />
                    )
                }
            </>
        );
    }
}