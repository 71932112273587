import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import ModalContent from "../../../../common/utils/ModalContent";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function UsersDetail({ initDetailData, loadUsers, closeDetail }) {
    let [modalConfig, setModalConfig] = useState({ show: false });

    let [loading, setLoading] = useState(true);

    let [detailData, setDetailData] = useState(initDetailData);

    let [disableLockedReason, setDisableLockedReason] = useState(!detailData.locked);


    const saveDataHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());
        formDataObj = { ...detailData, ...formDataObj };
        setLoading(true);

        axios
            .put(url + `/private/rest/admin/kuzelnik`, formDataObj)
            .then((res) => {
                //loadingDorobim
                setLoading(false);
                loadUsers();
                closeDetail();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Form onSubmit={(val) => saveDataHandler(val)} className="border mb-3 mx-1 row adminDetail">
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Prezývka</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.nickname} required="{true}" name="nickname" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Meno a priezvisko</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.name} name="name" />
                </Form.Group>
                <Form.Group className="col-12">
                    <Form.Label>Email</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.email} required="{true}" name="email" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6 mt-3 mt-lg-5">
                    <Form.Check
                        inline
                        label="Odomknutý"
                        type="radio"
                        id="locked-1"
                        checked={detailData.locked == false}
                        onChange={() => {
                            setDetailData({ ...detailData, locked: false, lockedReason: "" }); setDisableLockedReason(true)
                        }}
                    />
                    <Form.Check
                        inline
                        label="Zamknutý"
                        type="radio"
                        id="locked-2"
                        checked={detailData.locked == true}
                        onChange={() => {
                            setDetailData({ ...detailData, locked: true }); setDisableLockedReason(false)
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Dôvodová správa o vyhostení</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.lockedReason} name="lockedReason" disabled={disableLockedReason} />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6 mt-3 mt-lg-5">
                    <Form.Check
                        inline
                        label="Aktívny"
                        type="radio"
                        id="enabled-1"
                        checked={detailData.enabled == true}
                        disabled={true}
                    />
                    <Form.Check
                        inline
                        label="Neaktívny"
                        type="radio"
                        id="enabled-2"
                        checked={detailData.enabled == false}
                        disabled={true}
                    />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Dátum registrácie</Form.Label>
                    <Form.Control size="md" type="date" defaultValue={detailData.created?.substring(0, 10)} required="{true}" name="createdDate" disabled={true} />
                </Form.Group>
                <div className="mt-3 mb-2">
                    <button type="submit" className="btn btn-success">Uložiť</button>
                    <button className="btn btn-light ms-2" onClick={() => closeDetail()}>Zrušiť</button>
                </div>
            </Form>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}