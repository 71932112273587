const SLOVINSKE_MESTA = {
    "Kranj": [
        
    ],
    "Koper / Capodistria": [
        
    ],
    "Velenje": [
        "Cesta pod parkom",
        "Aškerčeva cesta",
        "Bevkova ulica",
        "Bračičeva cesta",
        "Cankarjeva cesta",
        "Celjska cesta",
        "Cesta Borisa Kraigherja",
        "Cesta bratov Mravljakov",
        "Cesta Františka Foita",
        "Cesta I",
        "Cesta II",
        "Cesta III",
        "Cesta IV",
        "Cesta IX",
        "Cesta Jožeta Ervina Prislana",
        "Cesta na Griču",
        "Cesta na Jezero",
        "Cesta na Vrtače",
        "Cesta pod Parkom",
        "Cesta Simona Blatnika",
        "Cesta talcev",
        "Cesta V",
        "Cesta v Bevče",
        "Cesta VI",
        "Cesta VII",
        "Cesta VIII",
        "Cesta X",
        "Cesta XIV divizije",
        "Čopova cesta",
        "Čufarjeva cesta",
        "Deberca",
        "Efenkova cesta",
        "Erjavčeva cesta",
        "Finžgarjeva cesta",
        "Gorenjska cesta",
        "Goriška cesta",
        "Graškogorska cesta",
        "Gregorčičeva cesta",
        "Gubčeva cesta",
        "Jenkova cesta",
        "Jerihova cesta",
        "Jurčičeva cesta",
        "Kajuhova cesta",
        "Kardeljev trg",
        "Kersnikova cesta",
        "Kidričeva cesta",
        "Konovska cesta",
        "Kopališka cesta",
        "Koroška cesta",
        "Kosovelova ulica",
        "Koželjskega ulica",
        "Levstikova cesta",
        "Lilijska ulica",
        "Linhartova ulica",
        "Lipa",
        "Ljubljanska cesta",
        "Madarjeva ulica",
        "Malgajeva ulica",
        "Meškova ulica",
        "Partizanska cesta",
        "Podgorska cesta",
        "Pokopališka cesta",
        "Preloška cesta",
        "Prešernova cesta",
        "Prisojna cesta",
        "Rudarska cesta",
        "Rudniška cesta",
        "Selo",
        "Sončna pot",
        "Sončni grič",
        "Splitska ulica",
        "Srebotnikova cesta",
        "Stanetova cesta",
        "Stantetova ulica",
        "Stari trg",
        "Stritarjeva cesta",
        "Subotiška cesta",
        "Šalek",
        "Šaleška cesta",
        "Šenbriška cesta",
        "Šercerjeva cesta",
        "Škalska cesta",
        "Šlandrova cesta",
        "Šmarška cesta",
        "Šolnova ulica",
        "Špeglova ulica",
        "Štrbenkova cesta",
        "Tavčarjeva cesta",
        "Titov trg",
        "Toledova ulica",
        "Tomšičeva cesta",
        "Trg mladosti",
        "Trubarjeva ulica",
        "Ulica 3. julija",
        "Ulica Dušana Kvedra",
        "Ulica Franca Sokliča",
        "Ulica Janka Ulriha",
        "Ulica Janka Vrabiča",
        "Ulica Lovra Kuharja",
        "Ulica Pohorskega bataljona",
        "Ulica svežih idej",
        "Uriskova ulica",
        "Vodnikova cesta",
        "Vodovodna ulica",
        "Vojkova cesta",
        "Zidanškova cesta",
        "Žarova cesta",
        "Župančičeva ulica"
    ],
    "Novo Mesto": [
        "Adamičeva ulica",
        "Andrijaničeva cesta",
        "Aškerčeva ulica",
        "Avšičeva ulica",
        "Bajčeva ulica",
        "Belokranjska cesta",
        "Breckerfeldova ulica",
        "Breg",
        "Brezje",
        "Brinje",
        "Brod",
        "Bršljin",
        "Cankarjeva ulica",
        "Cegelnica",
        "Cerovci",
        "Cesarjeva ulica",
        "Cesta brigad",
        "Cvelbarjeva ulica",
        "Čitalniška ulica",
        "Dalmatinova ulica",
        "Defranceschijeva ulica",
        "Detelova ulica",
        "Dilančeva ulica",
        "Dobovškova ulica",
        "Dolenje Kamence",
        "Drejčetova pot",
        "Drgančevje",
        "Drska",
        "Dularjeva ulica",
        "Finžgarjeva ulica",
        "Florjanov trg",
        "Foersterjeva ulica",
        "Frančiškanski trg",
        "Germova ulica",
        "Glavni trg",
        "Gosjakova ulica",
        "Gotna vas",
        "Gregoričeva ulica",
        "Grmsko sprehajališče",
        "Groblje",
        "Gubčeva ulica",
        "Hladnikova ulica",
        "Irča vas",
        "Jakčeva ulica",
        "Jedinščica",
        "Jenkova ulica",
        "Jerebova ulica",
        "Julijino sprehajališče",
        "Jurčičeva ulica",
        "K Roku",
        "K sodišču",
        "Kandijska cesta",
        "Kapiteljska ulica",
        "Karlovška cesta",
        "Kastelčeva ulica",
        "Kettejev drevored",
        "Klemenčičeva ulica",
        "Knafelčeva ulica",
        "Knezova ulica",
        "Kochova ulica",
        "Kočevarjeva ulica",
        "Kolodvorska ulica",
        "Komeljeva ulica",
        "Kosova ulica",
        "Košenice",
        "Koštialova ulica",
        "Krajčeva ulica",
        "Krallova ulica",
        "Kratka ulica",
        "Krka",
        "Lamutova ulica",
        "Lastovče",
        "Lebanova ulica",
        "Levičnikova cesta",
        "Levstikova ulica",
        "Linhartova ulica",
        "Livada",
        "Ljubljanska cesta",
        "Lobetova ulica",
        "Ločna",
        "Loški most",
        "Mačkovec",
        "Maistrova ulica",
        "Markljeva ulica",
        "Medičeva ulica",
        "Mej vrti",
        "Mestne njive",
        "Mirnopeška cesta",
        "Mlinarska pot",
        "Muhaber",
        "Mušičeva ulica",
        "Muzejska ulica",
        "Na Brezovici",
        "Na Dragah",
        "Na dulah",
        "Na Lazu",
        "Na Loko",
        "Na skali",
        "Na Tratah",
        "Na žago",
        "Nad mlini",
        "Nahtigalova ulica",
        "Novi trg",
        "Ob potoku",
        "Ob Težki vodi",
        "Ograja",
        "Okoljska ulica",
        "Osredek",
        "Otoška cesta",
        "Paderšičeva ulica",
        "Pavčkova ulica",
        "Pod Barončevim hribom",
        "Pod Šipčevim hribom",
        "Pod Trško goro",
        "Podbevškova ulica",
        "Podbreznik",
        "Poganška ulica",
        "Pot na Gorjance",
        "Pot v gaj",
        "Potočna vas",
        "Povhova ulica",
        "Prešernov trg",
        "Prisojna pot",
        "Pugljeva ulica",
        "Ragovo",
        "Ragovska ulica",
        "Ravnikarjeva ulica",
        "Regrča vas",
        "Resslova ulica",
        "Rozmanova ulica",
        "Rudolfovo sprehajališče",
        "Savinškova ulica",
        "Seidlova cesta",
        "Situlska pot",
        "Skalickega ulica",
        "Slančeva ulica",
        "Slatkonjeva ulica",
        "Smrečnikova ulica",
        "Sokolska ulica",
        "Straška cesta",
        "Stražna",
        "Streliška ulica",
        "Stritarjeva ulica",
        "Strma pot",
        "Strojarska pot",
        "Šalijeva ulica",
        "Šegova ulica",
        "Šentjernejska cesta",
        "Šentjoška cesta",
        "Školova ulica",
        "Škrabčeva ulica",
        "Šmarješka cesta",
        "Šmihel",
        "Šmihelska cesta",
        "Šolska ulica",
        "Štukljeva cesta",
        "Šukljetova ulica",
        "Tavčarjeva ulica",
        "Topliška cesta",
        "Trdinova ulica",
        "Trubarjeva ulica",
        "Tržiška ulica",
        "Turkova ulica",
        "Ulica Danila Bučarja",
        "Ulica Elizabete Soklič",
        "Ulica Ilke Vaštetove",
        "Ulica Ivana Roba",
        "Ulica Ivane Oblak",
        "Ulica Marije Tomšič",
        "Ulica Marjana Kozine",
        "Ulica Milana Majcna",
        "Ulica Mirana Jarca",
        "Ulica na Hribu",
        "Ulica Pie in Pina Mlakarja",
        "Ulica Slavka Gruma",
        "Ulica stare pravde",
        "Ulica talcev",
        "Ulica Vere Albreht",
        "V Brezov log",
        "V Ragov log",
        "Valantičevo",
        "Vandotova ulica",
        "Vavpotičeva ulica",
        "Velika Bučna vas",
        "Velika Cikava",
        "Vidmarjeva ulica",
        "Vodnikova ulica",
        "Volčičeva ulica",
        "Vorančeva ulica",
        "Vrhovčeva ulica",
        "Westrova ulica",
        "Zagrebška cesta",
        "Zaloška cesta",
        "Zobčeva ulica",
        "Zwittrova ulica",
        "Žabja vas",
        "Žabjak",
        "Žlebej",
        "Župančičevo sprehajališče",
        "Župnca"
    ],
    "Ptuj": [
        "Abramičeva ulica",
        "Anželova ulica",
        "Arbajterjeva ulica",
        "Arničeva ulica",
        "Arnuševa ulica",
        "Aškerčeva ulica",
        "Belšakova ulica",
        "Bevkova ulica",
        "Brstje",
        "Cafova ulica",
        "Cankarjeva ulica",
        "Cesta 8. avgusta",
        "Ciril-Metodov drevored",
        "Cvetkov trg",
        "Čarmanova ulica",
        "Čehova ulica",
        "Čopova ulica",
        "Črtkova ulica",
        "Čučkova ulica",
        "Čufarjeva ulica",
        "Dornavska cesta",
        "Dravska ulica",
        "Draženska cesta",
        "Erjavčeva pot",
        "Finžgarjeva ulica",
        "Flegeričeva ulica",
        "Frankovičeva ulica",
        "Frasova ulica",
        "Gajzerjeva ulica",
        "Golobova ulica",
        "Gomilšakova ulica",
        "Grajska ulica",
        "Gregorčičev drevored",
        "Groharjeva pot",
        "Gromova ulica",
        "Gubčeva ulica",
        "Hauptmaničeva ulica",
        "Hercegova ulica",
        "Hermanova ulica",
        "Hreničeva ulica",
        "Ilčeva ulica",
        "Jadranska ulica",
        "Jenkova ulica",
        "Jezova ulica",
        "Jurčičeva ulica",
        "K jezeru",
        "K Mitreju",
        "Kajuhova ulica",
        "Kariževa ulica",
        "Kerenčičeva ulica",
        "Kersnikova ulica",
        "Kettejeva ulica",
        "Klepova ulica",
        "Kogejeva pot",
        "Kokolova ulica",
        "Koresova ulica",
        "Kraigherjeva ulica",
        "Krambergerjeva pot",
        "Kratka ulica",
        "Krčevina pri Ptuju",
        "Krempljeva ulica",
        "Kvedrova ulica",
        "Langusova ulica",
        "Lazarjeva ulica",
        "Levstikova pot",
        "Linhartova ulica",
        "Ljudski vrt",
        "Macunova ulica",
        "Maistrova ulica",
        "Mariborska cesta",
        "Med vrti",
        "Meglova pot",
        "Mejna cesta",
        "Mestni log",
        "Mestni trg",
        "Meškova ulica",
        "Mežanova ulica",
        "Miklošičeva ulica",
        "Minoritski trg",
        "Mladinska ulica",
        "Mlinska cesta",
        "Murkova ulica",
        "Murščeva ulica",
        "Muzejski trg",
        "Na gradu",
        "Na hribu",
        "Na jasi",
        "Na obrežju",
        "Na postajo",
        "Na tratah",
        "Natašina pot",
        "Nova cesta",
        "Nova vas pri Ptuju",
        "Novi trg",
        "Ob Dravi",
        "Ob Grajeni",
        "Ob Rogoznici",
        "Ob Studenčnici",
        "Ob železnici",
        "Obrtniška ulica",
        "Orešje",
        "Ormoška cesta",
        "Osojnikova cesta",
        "Osterčeva ulica",
        "Panonska ulica",
        "Pergerjeva ulica",
        "Peršonova ulica",
        "Petrovičeva ulica",
        "Pivkova ulica",
        "Poljska cesta",
        "Pot v toplice",
        "Potrčeva cesta",
        "Povodnova ulica",
        "Praprotnikova ulica",
        "Prečna pot",
        "Prešernova ulica",
        "Prežihova ulica",
        "Puhova ulica",
        "Rabelčja vas",
        "Raičeva ulica",
        "Rajšpova ulica",
        "Remčeva ulica",
        "Reševa ulica",
        "Ribiška pot",
        "Rimska ploščad",
        "Rimska ulica",
        "Rogaška cesta",
        "Rogozniška cesta",
        "Sagadinova ulica",
        "Satlerjeva ulica",
        "Seliškarjeva ulica",
        "Selska cesta",
        "Severova ulica",
        "Slekovčeva ulica",
        "Slomškova ulica",
        "Slovenski trg",
        "Slovenskogoriška cesta",
        "Sodnice",
        "Sovretova pot",
        "Spolenakova ulica",
        "Stritarjeva pot",
        "Suha veja",
        "Svržnjakova ulica",
        "Šeronova ulica",
        "Špindlerjeva ulica",
        "Štrafelova ulica",
        "Štuki",
        "Švajgerjeva ulica",
        "Tavčarjeva ulica",
        "Tiha pot",
        "Toplakova ulica",
        "Trajanova ulica",
        "Trdinova ulica",
        "Trstenjakova ulica",
        "Trubarjeva ulica",
        "Turniška ulica",
        "Ulica 14. divizije",
        "Ulica 25. maja",
        "Ulica 5. prekomorske",
        "Ulica Anice Kaučevič",
        "Ulica Anice Kolarič",
        "Ulica Avgusta Hlupiča",
        "Ulica Franca Kosca",
        "Ulica heroja Lacka",
        "Ulica Jožefe Lackove",
        "Ulica kneza Koclja",
        "Ulica Lackove čete",
        "Ulica Pohorskega bataljona",
        "Ulica prvega maja",
        "Ulica Stanka Brenčiča",
        "Ulica Šercerjeve brigade",
        "Ulica Tomaža Šalamuna",
        "Ulica Vide Alič",
        "Ulica Viktorina Ptujskega",
        "Ulica Žetalskega Jožeta",
        "Vegova ulica",
        "Vespazianova ulica",
        "Vičava",
        "Vinarski trg",
        "Vodnikova ulica",
        "Vodova ulica",
        "Volkmerjeva cesta",
        "Vošnjakova ulica",
        "Vrazov trg",
        "Vrtnarska pot",
        "Wilhelmova ulica",
        "Zadružni trg",
        "Zagrebška cesta",
        "Zavčeva ulica",
        "Zechnerjeva ulica",
        "Zelenikova ulica",
        "Zidanškova ulica",
        "Zoisova pot",
        "Žabjak",
        "Žgečeva ulica",
        "Žnidaričevo nabrežje",
        "Žunkovičeva ulica",
        "Župančičeva ulica"
    ],
    "Kamnik": [
        "Aškerčeva ulica",
        "Bakovnik",
        "Bakovniška ulica",
        "Bazoviška ulica",
        "Bevkova ulica",
        "Čopova pot",
        "Črnivška ulica",
        "Dobajeva ulica",
        "Domžalska cesta",
        "Ekslerjeva ulica",
        "Frana Albrehta ulica",
        "Franca Pirca ulica",
        "Frančiškanski trg",
        "Fužine",
        "Glavni trg",
        "Gregorčičeva ulica",
        "Groharjeva ulica",
        "Hipolitov prehod",
        "Jakopičeva ulica",
        "Jamova ulica",
        "Janežičev drevored",
        "Japljeva ulica",
        "Jelovškova ulica",
        "Jenkova ulica",
        "Jurčičeva ulica",
        "Kajuhova pot",
        "Kebetova ulica",
        "Kersnikova ulica",
        "Kettejeva ulica",
        "Klavčičeva ulica",
        "Knafličev prehod",
        "Kolodvorska ulica",
        "Kovinarska cesta",
        "Koželjeva ulica",
        "Kranjska cesta",
        "Levstikova ulica",
        "Livarska ulica",
        "Ljubljanska cesta",
        "Lobodova ulica",
        "Maistrova ulica",
        "Medvedova ulica",
        "Mengeška pot",
        "Meninska ulica",
        "Miklavčičeva ulica",
        "Mlakarjeva ulica",
        "Murnova ulica",
        "Muzejska pot",
        "Na bregu",
        "Na jasi",
        "Na produ",
        "Novi trg",
        "Palovška cesta",
        "Parapatova ulica",
        "Parmova ulica",
        "Perkova ulica",
        "Perovo",
        "Petruškova pot",
        "Podgorska pot",
        "Podlimbarskega pot",
        "Pot 27. julija",
        "Pot Marije Vere",
        "Pot na Dobravo",
        "Pot na Poljane",
        "Pot v Rudnik",
        "Prečna ulica",
        "Prešernova ulica",
        "Prisojna pot",
        "Prvomajska ulica",
        "Raspov prehod",
        "Rozmanova ulica",
        "Rudniška ulica",
        "Sadnikarjeva ulica",
        "Samostanska ulica",
        "Slatnarjeva ulica",
        "Steletova cesta",
        "Streliška ulica",
        "Svetčeva pot",
        "Šipkova ulica",
        "Šlakarjev prehod",
        "Šlakarjeva pot",
        "Šolska ulica",
        "Šutna",
        "Tkalska pot",
        "Tomšičeva ulica",
        "Trdinova ulica",
        "Trg prijateljstva",
        "Trg svobode",
        "Trg talcev",
        "Tunjiška cesta",
        "Ulica Andreja Smolnikarja",
        "Ulica Edvarda Peperka",
        "Ulica Fortunata Berganta",
        "Ulica Frančka Miha Paglovca",
        "Ulica Jakoba Alešovca",
        "Ulica Jakoba Zupana",
        "Ulica Josipa Močnika",
        "Ulica Josipa Ogrinca",
        "Ulica Kamniško-zasav. odreda",
        "Ulica Ljudevita Stiasnyja",
        "Ulica Matije Blejca",
        "Ulica Toma Brejca",
        "Ulica Vilka Rožiča",
        "Usnjarska cesta",
        "Vavpotičeva ulica",
        "Vegova ulica",
        "Vremšakova ulica",
        "Zaprice",
        "Zikova ulica",
        "Žale",
        "Žebljarska pot",
        "Župančičeva ulica"
    ],
    "Jesenice": [
        "Cesta 1. maja",
        "Cesta železarjev",
        "Cesta Toneta Tomšiča",
        "Cesta bratov Stražišarjev",
        "Ulica Franca Benediča",
        "Fužinska cesta",
        "Ulica bratov Rupar",
        "Ulica Viktorja Kejžarja",
        "Ulica Cankarjevega bataljona",
        "Razgledna pot",
        "Gregorčičeva ulica",
        "Aljaževa ulica",
        "Ukova",
        "Delavska ulica",
        "Skladiščna ulica",
        "Mencigerjeva ulica",
        "Čopova ulica",
        "Medvedova ulica",
        "Ulica heroja Verdnika",
        "Janševa ulica",
        "Cesta maršala Tita",
        "Ledarska ulica",
        "Pod gozdom",
        "Pod Mirco",
        "Cesta Franceta Prešerna",
        "Ulica Mirkota Roglja",
        "Murova ulica",
        "Kosova ulica",
        "Cesta Cirila Tavčarja",
        "Cesta revolucije",
        "Ulica Staneta Bokala",
        "Cesta na Golico",
        "Zgornji Plavž",
        "Spodnji Plavž",
        "Log Ivana Krivca"
    ],
    "Trbovlje": [
        "Žabjek",
        "Cesta Osvobodilne fronte",
        "Cesta Tončke Čeč",
        "Dobrna",
        "Dom in vrt",
        "Gimnazijska cesta",
        "Globušak",
        "Golovec",
        "Hohkrautova kolonija",
        "Keršičev hrib",
        "Keršičeva cesta",
        "Kešetovo",
        "Kolodvorska cesta",
        "Kolonija 1. maja",
        "Kovinarsko naselje",
        "Loke",
        "Majcenova cesta",
        "Mestni trg",
        "Nasipi",
        "Neža",
        "Novi dom",
        "Ob železnici",
        "Obrtniška cesta",
        "Opekarna",
        "Partizanska cesta",
        "Pod gozdom",
        "Pod Ostrim vrhom",
        "Ribnik",
        "Rudarska cesta",
        "Savinjska cesta",
        "Šuštarjeva kolonija",
        "Trg Franca Fakina",
        "Trg revolucije",
        "Trg svobode",
        "Ulica 1. junija",
        "Ulica Sallaumines",
        "Ulica španskih borcev",
        "Vodenska cesta",
        "Vreskovo",
        "Zasavska cesta",
        "Žabjek"
    ],
    "Domžale": [
        "Adamičeva ulica",
        "Aškerčeva ulica",
        "Bernikova ulica",
        "Bistriška cesta",
        "Brejčeva ulica",
        "Breznikova ulica",
        "Brezova ulica",
        "Cankarjeva ulica",
        "Cesta talcev",
        "Češminova ulica",
        "Detelova ulica",
        "Fajfarjeva ulica",
        "Gostičeva ulica",
        "Gregorčičeva ulica",
        "Hrastova ulica",
        "Jarčeva ulica",
        "Javorjeva ulica",
        "Jesenova ulica",
        "Ješetova ulica",
        "Kajuhova cesta",
        "Kamniška cesta",
        "Karantanska cesta",
        "Karlovškova cesta",
        "Kasalova ulica",
        "Kersnikova ulica",
        "Kettejeva ulica",
        "Kolodvorska cesta",
        "Kopališka cesta",
        "Kosovelova ulica",
        "Krakovska cesta",
        "Krožna cesta",
        "Levstikova cesta",
        "Ljubljanska cesta",
        "Lobodova ulica",
        "Mačkovci",
        "Masarykova ulica",
        "Masljeva ulica",
        "Mestni trg",
        "Miklošičeva ulica",
        "Murnova ulica",
        "Na Zavrteh",
        "Nova ulica",
        "Obrtniška ulica",
        "Partizanska ulica",
        "Plečnikova ulica",
        "Poljska pot",
        "Pot na Pridavko",
        "Potočnikova ulica",
        "Prečna ulica",
        "Prešernova cesta",
        "Radio cesta",
        "Ravnikarjeva ulica",
        "Rodiška ulica",
        "Rojska cesta",
        "Roška ulica",
        "Savska cesta",
        "Sejmiška ulica",
        "Slamnikarska cesta",
        "Staretova ulica",
        "Stobovska cesta",
        "Stobovšak",
        "Stranska ulica",
        "Šolska ulica",
        "Študljanska cesta",
        "Tabor",
        "Taborska cesta",
        "Trdinova cesta",
        "Triglavska ulica",
        "Trubarjeva ulica",
        "Trzinska ulica",
        "Ulica Antona Skoka",
        "Ulica Ivana Pengova",
        "Ulica Matije Tomca",
        "Ulica Nikola Tesla",
        "Ulica Simona Jenka",
        "Ulica Urha Stenovca",
        "Usnjarska ulica",
        "Varškova ulica",
        "Vegova ulica",
        "Vodnikova ulica",
        "Vodopivčeva ulica",
        "Vodovodna cesta",
        "Zoranina ulica",
        "Železniška cesta",
        "Župančičeva ulica",
        "Domžale",
        "Pot za Bistrico",
        "Študa"
    ],
    "Nova Gorica": [
        "Barje",
        "Bazoviška ulica",
        "Bevkov trg",
        "Bidovčeva ulica",
        "Cankarjeva ulica",
        "Cesta 15. septembra",
        "Cesta 25. junija",
        "Delpinova ulica",
        "Erjavčeva ulica",
        "Gortanova ulica",
        "Grčna",
        "Gregorčičeva ulica",
        "Kajuhova ulica",
        "Kidričeva ulica",
        "Kolodvorska pot",
        "Kosovelova ulica",
        "Kromberška cesta",
        "Ledine",
        "Lemutova ulica",
        "Marušičeva ulica",
        "Miloševa ulica",
        "Na potoku",
        "Partizanska ulica",
        "Ploščad Silvana Furlana",
        "Pod Grčno",
        "Prešernova ulica",
        "Prvomajska ulica",
        "Rejčeva ulica",
        "Rutarjeva ulica",
        "Sedejeva ulica",
        "Streliška pot",
        "Ščedne",
        "Škrabčeva ulica",
        "Trg Edvarda Kardelja",
        "Trg Evrope",
        "Trubarjeva ulica",
        "Tumova ulica",
        "Ulica dr. Karla Lavriča",
        "Ulica Gradnikove brigade",
        "Ulica Ivana Regenta",
        "Ulica Marija Kogoja",
        "Ulica Rada Simonitija",
        "Ulica Šantlovih",
        "Ulica tolminskih puntarjev",
        "Ulica XXX. divizije",
        "Ulica Zorka Jelinčiča",
        "Valenčičeva ulica",
        "Vetrišče",
        "Vojkova cesta",
        "Vrtna ulica",
        "Železničarska pot",
        "Požarna pot",
        "Ulica Tolminskih puntarjev"
    ],
    "Skofja Loka": [
        "Blaževa ulica",
        "Cankarjev trg",
        "Cesta talcev",
        "Demšarjeva cesta",
        "Frankovo naselje",
        "Fužinska ulica",
        "Grajska pot",
        "Groharjevo naselje",
        "Hafnerjevo naselje",
        "Kamnitnik",
        "Kapucinski trg",
        "Kidričeva cesta",
        "Klobovsova ulica",
        "Kopališka ulica",
        "Koširjeva cesta",
        "Ljubljanska cesta",
        "Mestni trg",
        "Nad Plevno",
        "Novi svet",
        "Partizanska cesta",
        "Pod Plevno",
        "Podlubnik",
        "Poljanska cesta",
        "Potočnikova ulica",
        "Sorška cesta",
        "Spodnji trg",
        "Stara cesta",
        "Studenec",
        "Suška cesta",
        "Šolska ulica",
        "Tavčarjeva ulica"
    ],
    "Izola / Isola": [
        "Alietova ulica",
        "Aškerčeva ulica",
        "Bazoviška ulica",
        "Bevkova ulica",
        "Brkinska ulica",
        "Cankarjev drevored",
        "Cegnarjeva ulica",
        "Cesta v Pregavor",
        "Dantejeva ulica",
        "Drevored 1. maja",
        "Dvoriščna ulica",
        "Garibaldijeva ulica",
        "Gasilska ulica",
        "Goriška ulica",
        "Gorkijeva ulica",
        "Gortanova ulica",
        "Gramscijeva ulica",
        "Gregorčičeva ulica",
        "Gubčeva ulica",
        "Industrijska cesta",
        "Istrska vrata",
        "Južna cesta",
        "Kajuhova ulica",
        "Kažipotna ulica",
        "Kettejeva ulica",
        "Kocjančičeva ulica",
        "Kopališka ulica",
        "Koprska ulica",
        "Kosova ulica",
        "Kosovelova ulica",
        "Kraška ulica",
        "Krpanova ulica",
        "Kumarjeva ulica",
        "Leninova ulica",
        "Levstikova ulica",
        "Livade",
        "Ljubljanska ulica",
        "Manziolijev trg",
        "Morova ulica",
        "Muzčeva ulica",
        "Nazorjeva ulica",
        "Obrtna ulica",
        "Partizanska ulica",
        "Pekarniška ulica",
        "Piranska ulica",
        "Pittonijeva ulica",
        "Plenčičeva ulica",
        "Polje",
        "Postojnska ulica",
        "Prečna ulica",
        "Premrlova ulica",
        "Prešernova cesta",
        "Ribiška ulica",
        "Skladiščna ulica",
        "Smareglieva ulica",
        "Smrekarjeva ulica",
        "Sončno nabrežje",
        "Spinčičeva ulica",
        "Šolska ulica",
        "Tartinijeva ulica",
        "Tomažičeva ulica",
        "Tomšičeva ulica",
        "Tovarniška ulica",
        "Trg Etbina Kristana",
        "Trg padlih za svobodo",
        "Trg Republike",
        "Trg svetega Mavra",
        "Trgovska ulica",
        "Trinkova ulica",
        "Trubarjeva ulica",
        "Tržaška ulica",
        "Ulica Alme Vivoda",
        "Ulica Alojza Valenčiča",
        "Ulica Bogomira Magajne",
        "Ulica Elvire Vatovec",
        "Ulica Emilia Driolia",
        "Ulica Ferda Bidovca",
        "Ulica Franeta Marušiča",
        "Ulica Giordano Bruno",
        "Ulica Istrskega odreda",
        "Ulica Ivana Regenta",
        "Ulica IX. korpusa",
        "Ulica Mihaele Škapin",
        "Ulica Mirke Kleve",
        "Ulica ob pečini",
        "Ulica ob starem zidovju",
        "Ulica ob stari bolnici",
        "Ulica ob stolpu",
        "Ulica ob vratih",
        "Ulica oktobrske revolucije",
        "Ulica Osvobodilne fronte",
        "Ulica Prekomorskih brigad",
        "Ulica Sergeja Mašere",
        "Ulica svetega Petra",
        "Ulica svobode",
        "Ulica Zvonimira Miloša",
        "Veliki trg",
        "Veluščkova ulica",
        "Verdijeva ulica",
        "Volaričeva ulica",
        "Zaprta ulica",
        "Zelena ulica",
        "Zustovičeva ulica",
        "Župančičeva ulica",
        "Ulica svetega Petra - Via San Pietro",
        "Kraška ulica - Via del Carso"
    ],
    "Murska Sobota": [
        "Aškerčeva ulica",
        "Bakovska ulica",
        "Bevkova ulica",
        "Borovnjakova ulica",
        "Cankarjeva ulica",
        "Ciril-Metodova ulica",
        "Cvetkova ulica",
        "Cvetna ulica",
        "Čopova ulica",
        "Dijaška ulica",
        "Finžgarjeva ulica",
        "Grajska ulica",
        "Gregorčičeva ulica",
        "Grofovsko",
        "Industrijska ulica",
        "Ivanocijevo naselje",
        "Jakobovo naselje",
        "Južna ulica",
        "Kajuhova ulica",
        "Kardoševa ulica",
        "Klavniška ulica",
        "Kocljeva ulica",
        "Kološeva ulica",
        "Kopališka ulica",
        "Kopitarjeva ulica",
        "Kroška ulica",
        "Ledavsko naselje",
        "Lendavska ulica",
        "Mala nova ulica",
        "Markišavska ulica",
        "Miklošičeva ulica",
        "Mirna ulica",
        "Mladinska ulica",
        "Mojstrska ulica",
        "Muzge",
        "Naselje J. Kerenčiča",
        "Naselje ljudske pravice",
        "Noršinska ulica",
        "Obrtna ulica",
        "Ozka ulica",
        "Panonska ulica",
        "Partizanska ulica",
        "Plese",
        "Polje",
        "Poljska ulica",
        "Prešernova ulica",
        "Prežihova ulica",
        "Prvomajska ulica",
        "Razlagova ulica",
        "Rožno naselje",
        "Severjeva ulica",
        "Slomškova ulica",
        "Slovenska ulica",
        "Sodna ulica",
        "Sončna ulica",
        "Stara ulica",
        "Šercerjevo naselje",
        "Šolsko naselje",
        "Talanyijeva ulica",
        "Temlinova ulica",
        "Tišinska ulica",
        "Tomšičeva ulica",
        "Trg kulture",
        "Trg zmage",
        "Trstenjakova ulica",
        "Trubarjev drevored",
        "Ulica arhitekta Novaka",
        "Ulica Daneta Šumenjaka",
        "Ulica generala Maistra",
        "Ulica Ivana Regenta",
        "Ulica Jožefa Benka",
        "Ulica Jožefa Grleca",
        "Ulica Juša Kramarja",
        "Ulica Matije Gubca",
        "Ulica Mikloša Kuzmiča",
        "Ulica Miška Baranje",
        "Ulica Nikole Tesla",
        "Ulica ob kanalu",
        "Ulica ob progi",
        "Ulica Otona Župančiča",
        "Ulica Prekmurske čete",
        "Ulica Slave Klavora",
        "Ulica Staneta Rozmana",
        "Ulica Štefana Kovača",
        "Ulica Štefana Kuharja",
        "Ulica Štefana Kuzmiča",
        "Ulica Vinka Megle",
        "Ulica Zofke Kvedrove",
        "Ulica Zorana Velnarja",
        "Vegova ulica",
        "Vrazova ulica",
        "Vrbišče",
        "Vrtna ulica",
        "Vrtnarska ulica",
        "Zelena ulica",
        "Zvezna ulica",
        "Žitna ulica"
    ],
    "Postojna": [
        "Brezov drevored",
        "Cankarjeva ulica",
        "Cesta na Kremenco",
        "Cesta v Staro vas",
        "Čukovca",
        "Erazmova ulica",
        "Globočnikova ulica",
        "Gregorčičev drevored",
        "Industrijska cesta",
        "Jamska cesta",
        "Javorniška pot",
        "Jenkova ulica",
        "Jeršice",
        "Jurčičeva ulica",
        "Kajuhova ulica",
        "Kalistrova ulica",
        "Kazarje",
        "Kettejeva ulica",
        "Kidričevo naselje",
        "Kolodvorska cesta",
        "Kosovelova ulica",
        "Kot",
        "Kraigherjeva ulica",
        "Kraška ulica",
        "Ljubljanska cesta",
        "Log",
        "Na Lokvi",
        "Notranjska ulica",
        "Nova vas",
        "Novi trg",
        "Park pod Javorniki",
        "Pivška ulica",
        "Pod Jelovico",
        "Pod kolodvorom",
        "Pod Ostrim vrhom",
        "Pot k Pivki",
        "Pot na Poljane",
        "Prečna ulica",
        "Prešernova ulica",
        "Pretnerjeva ulica",
        "Raubarkomanda",
        "Reška cesta",
        "Rožna ulica",
        "Stjenkova ulica",
        "Streliška ulica",
        "Stritarjeva ulica",
        "Štihova ulica",
        "Titov trg",
        "Titova cesta",
        "Tomšičeva ulica",
        "Trg padlih borcev",
        "Tržaška cesta",
        "Ulica 1. maja",
        "Ulica Dolomitskega odreda",
        "Ulica Franca Ambrožiča",
        "Ulica Franca Smrduja",
        "Ulica IX. korpusa",
        "Ulica Luke Čeč",
        "Ulica prekomorskih brigad",
        "Ulica Vilka Kledeta",
        "Vegova ulica",
        "Vilharjeva ulica",
        "Vojkova ulica",
        "Volaričeva ulica",
        "Za Sovičem",
        "Zalog",
        "Zeleni biser",
        "Župančičeva ulica",
        "Ulica Prekomorskih brigad",
        "Sovič"
    ],
    "Vrhnika": [
        "Betajnova",
        "Cankarjev trg",
        "Cesta 6. maja",
        "Cesta gradenj",
        "Čuža",
        "Delavsko naselje",
        "Dobovičnikova ulica",
        "Dolge njive",
        "Gabrče",
        "Gradišče",
        "Grilcev grič",
        "Hrastje",
        "Hrib",
        "Idrijska cesta",
        "Jelovškova ulica",
        "Kolesarska pot",
        "Kolodvorska ulica",
        "Kopališka ulica",
        "Krožna pot",
        "Krpanova ulica",
        "Kuclerjeva ulica",
        "Kurirska pot",
        "Ljubljanska cesta",
        "Lošca",
        "Med vrtovi",
        "Močilnik",
        "Mokrice",
        "Možinova ulica",
        "Mrakova cesta",
        "Na klancu",
        "Na Klisu",
        "Na livadi",
        "Na zelenici",
        "Notranjska cesta",
        "Ob Beli",
        "Ob igrišču",
        "Ob izviru",
        "Ob Ljubljanici",
        "Ob potoku",
        "Ob progi",
        "Opekarska cesta",
        "Partizanski tabor",
        "Pavkarjeva pot",
        "Petkovškova ulica",
        "Pod Hruševco",
        "Poštna ulica",
        "Pot k studencu",
        "Pot k Trojici",
        "Pot na Košace",
        "Pot na Tojnice",
        "Pot v Močilnik",
        "Prečna pot",
        "Pri lipi",
        "Prisojna pot",
        "Raskovec",
        "Ribiška pot",
        "Robova cesta",
        "Rožna ulica",
        "Sivkina ulica",
        "Stara cesta",
        "Storžev grič",
        "Švabičeva ulica",
        "Sušnikova cesta",
        "Tičnica",
        "Travniška ulica",
        "Trg Karla Grabeljška",
        "Tržaška cesta",
        "Turnovše",
        "Usnjarska cesta",
        "V zatišju",
        "Vas",
        "Voljčeva cesta",
        "Vrtnarija",
    ],
    "Slovenska Bistrica": [
        "Aljaževa ulica",
        "Aškerčeva ulica",
        "Bazoviška ulica",
        "Cankarjeva ulica",
        "Cesta XIV. divizije",
        "Cesta zmage",
        "Cvetlična ulica",
        "Čopova ulica",
        "Devinska ulica",
        "Finžgarjeva ulica",
        "Gombačeva ulica",
        "Gradišče",
        "Grajska ulica",
        "Gregorčičeva ulica",
        "Grogova ulica",
        "Industrijska ulica",
        "Ingoličeva ulica",
        "Izseljenska ulica",
        "Jamova ulica",
        "Jenkova ulica",
        "Jurčičeva ulica",
        "Kajuhova ulica",
        "Kettejeva ulica",
        "Kolodvorska ulica",
        "Kopališka ulica",
        "Kraigherjeva ulica",
        "Kratka ulica",
        "Krekova ulica",
        "Lackova ulica",
        "Leonova ulica",
        "Leskovarjeva ulica",
        "Levstikova ulica",
        "Livadna",
        "Ljubljanska cesta",
        "Maistrova ulica",
        "Mariborska cesta",
        "Miklošičeva ulica",
        "Mladinska ulica",
        "Mroževa ulica",
        "Murnova ulica",
        "Na Ajdov hrib",
        "Na Jožefu",
        "Na poljanah",
        "Nazorjeva ulica",
        "Ob gozdu",
        "Ob parku",
        "Ob potoku",
        "Ob stadionu",
        "Obrtniška ulica",
        "Osojna ulica",
        "Ozka ulica",
        "Partizanska ulica",
        "Pirnikova ulica",
        "Plečnikova ulica",
        "Potrčeva ulica",
        "Prečna ulica",
        "Prešernova ulica",
        "Prežihova ulica",
        "Proti jezam",
        "Prvomajska ulica",
        "Ratejeva ulica",
        "Ribiška ulica",
        "Rimska ulica",
        "Romihova ulica",
        "Samova ulica",
        "Sernčeva ulica",
        "Slomškova ulica",
        "Slovenska ulica",
        "Stepišnikova ulica",
        "Šolska ulica",
        "Špindlerjeva ulica",
        "Štancerjeva ulica",
        "Štuhčeva ulica",
        "Taborniška ulica",
        "Tavčarjeva ulica",
        "Tomažičeva ulica",
        "Tomšičeva ulica",
        "Tovarniška ulica",
        "Travniška ulica",
        "Trg Alfonza Šarha",
        "Trg svobode",
        "Trgovska ulica",
        "Trubarjeva ulica",
        "Ulica Anice Černejeve",
        "Ulica Borisa Winterja",
        "Ulica Bračičeve brigade",
        "Ulica dr. Jagodiča",
        "Ulica dr. Jožeta Pučnika",
        "Ulica dr. Štefke Hribarjeve",
        "Ulica Pohorskega bataljona",
        "Ulica Pohorskega odreda",
        "Ulica Sagadinovih",
        "Ulica Slave Klavore",
        "V Črnec",
        "V Zafošt",
        "Valvasorjeva ulica",
        "Vinarska ulica",
        "Vodovnikova ulica",
        "Vošnjakova ulica",
        "Vrtnarska ulica",
        "Zadružna ulica",
        "Zelena ulica",
        "Zidanškova ulica",
        "Žolgarjeva ulica",
        "Župančičeva ulica"
    ],
    "Kočevje": [
        "Bezgova pot",
        "Borova pot",
        "Bračičeva ulica",
        "Brestova pot",
        "Brezova pot",
        "Brinova pot",
        "Bršljanova pot",
        "Cankarjeva ulica",
        "Cesta na stadion",
        "Cesta na Trato",
        "Cesta v Mestni log",
        "Črnomeljska cesta",
        "Dimnikarska pot",
        "Frankopanska ulica",
        "Grajska pot",
        "Kajuhovo naselje",
        "Kekčeva ulica",
        "Kidričeva ulica",
        "Kocljeva ulica",
        "Kolodvorska cesta",
        "Ljubljanska cesta",
        "Mestni log I",
        "Mestni log II",
        "Mestni log III",
        "Mestni log IV",
        "Mestni log V",
        "Nabrežje",
        "Novomeška cesta",
        "Ob mahovniški cesti",
        "Opekarska ulica",
        "Podgorska ulica",
        "Prešernova ulica",
        "Pri Unionu",
        "Reška cesta",
        "Roška cesta",
        "Rozmanova ulica",
        "Rožna ulica",
        "Rudarsko naselje",
        "Rudnik",
        "Samova ulica",
        "Streliška ulica",
        "Šeškova ulica",
        "Tesarska ulica",
        "Tomšičeva cesta",
        "Trata I",
        "Trata II",
        "Trata III",
        "Trata IV",
        "Trata IX",
        "Trata V",
        "Trata VI",
        "Trata VII",
        "Trata VIII",
        "Trata XI",
        "Trata XII",
        "Trata XIII",
        "Trata XIV",
        "Trata XV",
        "Travniška ulica",
        "Trdnjava",
        "Trg Svetega Jerneja",
        "Trg zbora odposlancev",
        "Turjaško naselje",
        "Ulica heroja Marinclja",
        "Ulica slovenskih teritorialcev",
        "Ulica Veronike Deseniške",
        "Uskoška ulica"
    ],
    "Grosuplje": [
        "Adamičeva cesta",
        "Bevkova cesta",
        "Brinje, cesta I",
        "Brinje, cesta II",
        "Cesta Cankarjeve brigade",
        "Cesta dr. Jožeta Pučnika",
        "Cesta na Krko",
        "Cesta Toneta Kralja",
        "Erjavčeva cesta",
        "Gasilska cesta",
        "Hribarjeva cesta",
        "Hribska pot",
        "Industrijska cesta",
        "Jakhlova cesta",
        "Jerova vas",
        "Jurčičeva cesta I",
        "Jurčičeva cesta II",
        "Jurčičeva cesta III",
        "Jurčičeva pot",
        "Kadunčeva cesta",
        "Kajuhov dvor",
        "Kersnikova cesta",
        "Kettejeva cesta",
        "Kolodvorska cesta",
        "Kosovelova cesta",
        "Kovačičeva cesta",
        "Kozakova cesta",
        "Kozinova cesta",
        "Levstikova cesta",
        "Ljubljanska cesta",
        "Maistrova ulica",
        "Metelkov dvor",
        "Mlinska pot",
        "Murnova cesta",
        "Ob Grosupeljščici",
        "Obrtniška cesta",
        "Partizanska cesta",
        "Perovo",
        "Pod gozdom, cesta I",
        "Pod gozdom, cesta II",
        "Pod gozdom, cesta III",
        "Pod gozdom, cesta IV",
        "Pod gozdom, cesta V",
        "Pod gozdom, cesta VI",
        "Pod gozdom, cesta VII",
        "Pod gozdom, cesta VIII",
        "Pod hribom, cesta I",
        "Pod hribom, cesta II",
        "Pod hribom, cesta III",
        "Pod jelšami",
        "Prečna pot",
        "Preska",
        "Prešernova cesta",
        "Pri mostu",
        "Pri nadvozu",
        "Rodetova cesta",
        "Rožna dolina",
        "Seliškarjeva cesta",
        "Slomškova ulica",
        "Stranska pot I",
        "Stranska pot II",
        "Stranska pot III",
        "Stritarjeva cesta",
        "Šuligojeva cesta",
        "Taborska cesta",
        "Tovarniška cesta",
        "Trubarjeva cesta",
        "Ulica Ane Galetove",
        "Valvazorjev dvor",
        "Veselova cesta I",
        "Veselova cesta II",
        "Veselova cesta III",
        "Vodnikova cesta",
        "Za gasilskim domom",
        "Župančičeva cesta",
        "Pod gozdom cesta II",
        "Brinje cesta I",
        "Pod gozdom cesta VIII",
        "Pod gozdom cesta IV",
        "Pod gozdom cesta V",
        "Pod gozdom cesta VI",
        "Pod gozdom cesta III",
        "Pod gozdom cesta I",
        "Brinje cesta II"
    ],
    "Slovenj Gradec": [
        "Žančani"
    ],
    "Mengeš": [
        "Aljaževa ulica",
        "Balantičeva ulica",
        "Baragova ulica",
        "Bognarjeva ulica",
        "Cankarjeva ulica",
        "Čopova ulica",
        "Dalmatinova ulica",
        "Detelova ulica",
        "Dragarjeva ulica",
        "Drnovo",
        "Erjavčeva ulica",
        "Finžgarjeva ulica",
        "Glasbilarska ulica",
        "Glavarjeva ulica",
        "Glavičeva ulica",
        "Glavni trg",
        "Gorenjska cesta",
        "Gostičeva ulica",
        "Gregčeva ulica",
        "Grobeljska cesta",
        "Hribarjeva ulica",
        "Jalnova ulica",
        "Jama",
        "Janševa ulica",
        "Japljeva ulica",
        "Jarška cesta",
        "Jelovškova ulica",
        "Jurčičeva ulica",
        "Kamniška cesta",
        "Kersnikova ulica",
        "Koblarjeva ulica",
        "Kolodvorska cesta",
        "Kopitarjeva ulica",
        "Krekova ulica",
        "Lavričeva ulica",
        "Levčeva ulica",
        "Levstikova ulica",
        "Linhartova cesta",
        "Liparjeva cesta",
        "Maistrova ulica",
        "Medvedova ulica",
        "Mehletova ulica",
        "Muljava",
        "Murnova ulica",
        "Novakova ulica",
        "Ogrinovo",
        "Omejčeva ulica",
        "Prelovškova ulica",
        "Prešernova cesta",
        "Pri bajerju",
        "Pristava",
        "Rašiška cesta",
        "Ropretova cesta",
        "Sadnikarjeva ulica",
        "Slamnikarska ulica",
        "Slomškova ulica",
        "Slovenska cesta",
        "Stara pot",
        "Staretova ulica",
        "Steletova ulica",
        "Svetčeva ulica",
        "Šolska ulica",
        "Šubičeva ulica",
        "Šubljeva ulica",
        "Trdinov trg",
        "Trubarjeva ulica",
        "Ulica dr. Zajca",
        "Valvazorjeva ulica",
        "Veselovo nabrežje",
        "Vodnikova ulica",
        "Zadružniška ulica",
        "Zavrti",
        "Zoranina ulica",
        "Zupanova ulica"
    ],
    "Ravne na Koroškem": [
        "Čečovje",
        "Ditingerjeva ulica",
        "Dobja vas",
        "Gačnikova pot",
        "Gledališka pot",
        "Gozdarska pot",
        "Janeče",
        "Javornik",
        "Koroška cesta",
        "Malgajeva cesta",
        "Na gradu",
        "Na Šancah",
        "Ob Meži",
        "Ob Suhi",
        "Partizanska cesta",
        "Prežihova ulica",
        "Stara ulica",
        "Trg svobode"
    ],
    "Ajdovščina": [
        "Bevkova ulica",
        "Cankarjev trg",
        "Cebejeva ulica",
        "Cesta 5. maja",
        "Cesta IV. Prekomorske",
        "Cesta IX. Korpusa",
        "Goriška cesta",
        "Gradišče",
        "Gregorčičeva ulica",
        "Grivška pot",
        "Idrijska cesta",
        "Kidričeva ulica",
        "Lavričev trg",
        "Lavričeva cesta",
        "Levstikova ulica",
        "Lokarjev drevored",
        "Lokavška cesta",
        "Mirce",
        "Na Brajdi",
        "Na Livadi",
        "Na trati",
        "Ob Hublju",
        "Pod letališčem",
        "Polževa ulica",
        "Pot v Žapuže",
        "Prešernova ulica",
        "Slejkoti",
        "Slomškova ulica",
        "Stritarjeva ulica",
        "Šibeniška ulica",
        "Štrancarjeva ulica",
        "Tovarniška cesta",
        "Trg prve Slovenske vlade",
        "Ulica 24. septembra",
        "Ulica Bazoviške brigade",
        "Ulica Ivana Kosovela",
        "Ulica Milana Klemenčiča",
        "Ulica na Borštu",
        "Ulica Quiliano",
        "Ulica Vena Pilona",
        "Vilharjeva ulica",
        "Vojkova ulica",
        "Župančičeva ulica"
    ],
    "Brežice": [
        "Aškerčeva ulica",
        "Betettova ulica",
        "Bevkova ulica",
        "Bizeljska cesta",
        "Bohoričeva ulica",
        "Borštnikova ulica",
        "Brezina",
        "Cankarjeva ulica",
        "Cesta bratov Cerjakov",
        "Cesta bratov Milavcev",
        "Cesta prvih borcev",
        "Cesta svobode",
        "Cvetlična ulica",
        "Černelčeva cesta",
        "Čolnarska pot",
        "Čopova ulica",
        "Črnc",
        "Dalmatinova ulica",
        "Dobovska cesta",
        "Erjavčeva ulica",
        "Finžgarjeva ulica",
        "Gradnikova ulica",
        "Gregorčičeva ulica",
        "Gubčeva ulica",
        "Holyjeva steza",
        "Hrastinska pot",
        "Ipavčeva ulica",
        "Jurčičeva ulica",
        "Kajuhova ulica",
        "Kettejeva ulica",
        "Kocbekova ulica",
        "Kogojeva ulica",
        "Kopitarjeva ulica",
        "Kosovelova ulica",
        "Kratka ulica",
        "Kregarjeva ulica",
        "Krekova ulica",
        "Kržičnikova ulica",
        "Lamutova ulica",
        "Lenartova pot",
        "Levstikova ulica",
        "Linhartova ulica",
        "Maistrova ulica",
        "Marof",
        "Mencingerjeva ulica",
        "Meškova ulica",
        "Mladinska ulica",
        "Murnova ulica",
        "Na bregu",
        "Nad Vrbino",
        "Ob potoku",
        "Ob stadionu",
        "Obrtna ulica",
        "Opekarska ulica",
        "Orehova aleja",
        "Orliška ulica",
        "Pešpot",
        "Pleteršnikova ulica",
        "Pod obzidjem",
        "Prečna pot",
        "Prešernova cesta",
        "Prežihova ulica",
        "Razlagova ulica",
        "Rožna ulica",
        "Samova ulica",
        "Slomškova ulica",
        "Sodarska pot",
        "Stiplovškova ulica",
        "Stritarjeva ulica",
        "Šentlenart",
        "Šolska ulica",
        "Šubičeva ulica",
        "Tavčarjeva ulica",
        "Tovarniška cesta",
        "Travniška pot",
        "Trdinova ulica",
        "Trg izgnancev",
        "Trg Jožeta Toporišiča",
        "Trg vstaje",
        "Trnje",
        "Trnska pot",
        "Trubarjeva ulica",
        "Ulica Alenke Gerlovič",
        "Ulica bratov Gerjevič",
        "Ulica Elizabete Zorko",
        "Ulica Ilije Gregoriča",
        "Ulica kozjanskih borcev",
        "Ulica Stanka Škalerja",
        "Ulica stare pravde",
        "Usnjarska pot",
        "Valvasorjeva ulica",
        "Vegova ulica",
        "Vodnikova ulica",
        "Zakot",
        "Zidarska pot",
        "Žagarska ulica",
        "Župančičeva ulica"
    ],
    "Krško": [
        "Valvasorjevo nabrežje",
        "Vrtna ulica",
        "Bohoričeva ulica",
        "Cankarjeva ulica",
        "Cesta 4. julija",
        "Cesta krških žrtev",
        "Dalmatinova ulica",
        "Delavska ulica",
        "Erjavčeva ulica",
        "Gasilska ulica",
        "Gruenova ulica",
        "Gubčeva ulica",
        "Hočevarjev trg",
        "Humekova ulica",
        "Ilirska ulica",
        "Jurčičeva pot",
        "Kajuhova ulica",
        "Kidričeva ulica",
        "Kolodvorska ulica",
        "Kovinarska ulica",
        "Kratka pot",
        "Kurirska pot",
        "Kvedrova ulica",
        "Lapajnetova ulica",
        "Leskovška cesta",
        "Levstikova pot",
        "Majcnova ulica",
        "Mencingerjeva ulica",
        "Na bregu",
        "Na Resi",
        "Narpel",
        "Naselje nuklearne elektrarne",
        "Ob potoku",
        "Papirniška ulica",
        "Partizanska pot",
        "Pavlinova ulica",
        "Pod goro",
        "Pod pristavo",
        "Poljska pot",
        "Pot na Libno",
        "Pot na Polšco",
        "Prešernova cesta",
        "Ribiška ulica",
        "Rostoharjeva ulica",
        "Rozmanova ulica",
        "Sadjarska ulica",
        "Savska pot",
        "Sotelsko",
        "Sovretova ulica",
        "Sremiška cesta",
        "Stermeckijeva ulica",
        "Stritarjeva ulica",
        "Strma pot",
        "Šoferska ulica",
        "Šolska ulica",
        "Tomšičeva ulica",
        "Tovarniška ulica",
        "Trdinova ulica",
        "Trg Matije Gubca",
        "Trubarjeva ulica",
        "Ulica Frana Bonača",
        "Ulica Ilije Gregoriča",
        "Ulica Milke Kerinove",
        "Ulica Nikole Tesle",
        "Ulica Slavka Rožanca",
        "Ulica Tončke Čečeve",
        "Valvasorjevo nabrežje",
        "Vrtna ulica",
        "Zdolska cesta",
        "Župančičeva ulica",
        "Aškerčeva ulica"
    ],
    "Litija": [
        "Badjurova ulica",
        "Bevkova ulica",
        "Bobek",
        "Brodarska ulica",
        "Cankarjeva cesta",
        "Cesta Dolenjskega odreda",
        "Cesta Dušana Kvedra",
        "Cesta komandanta Staneta",
        "Cesta Zasavskega bataljona",
        "Frtica",
        "Graška cesta",
        "Grbinska cesta",
        "Grumova ulica",
        "Gubčeva ulica",
        "Jerebova ulica",
        "Ježa",
        "Kidričeva cesta",
        "Levstikova ulica",
        "Litijska Dobrava I",
        "Litijska Dobrava II",
        "Litijska Dobrava III",
        "Ljubljanska cesta",
        "Loška ulica",
        "Maistrova ulica",
        "Marokova pot",
        "Na Dobravi",
        "Na Gričku",
        "Parmova ulica",
        "Partizanska pot",
        "Podkraj",
        "Pokopališka pot",
        "Ponoviška cesta",
        "Praprošče",
        "Prečna ulica",
        "Predilniška ulica",
        "Prisojna ulica",
        "Prvomajska ulica",
        "Rudarska ulica",
        "Savska cesta",
        "Sitarjevška cesta",
        "Šmarska cesta",
        "Topilniška ulica",
        "Trg na Stavbah",
        "Trg svobode",
        "Ulica 25. maja",
        "Ulica bratov Poglajen",
        "Ulica Luke Svetca",
        "Ulica Milana Boriška",
        "Ulica Mire Pregljeve",
        "Ulica solidarnosti",
        "Valvazorjev trg",
        "Vegova ulica",
        "Vrvarska ulica",
        "Zasavska cesta"
    ],
    "Sežana": [
        "Bazoviška cesta",
        "Benčičeva ulica",
        "Brkinčeva ulica",
        "Cankarjeva ulica",
        "Cesta na Bršljanovec",
        "Cesta na Lenivec",
        "Cesta na Polane",
        "Cesta v Ograde",
        "Devinska ulica",
        "Gradišče",
        "Gradnikova ulica",
        "Gregorčičeva ulica",
        "Igriška ulica",
        "Jamarska ulica",
        "Kajuhova ulica",
        "Kamnarska ulica",
        "Kettejeva ulica",
        "Kidričeva ulica",
        "Kolodvorska ulica",
        "Kosovelova ulica",
        "Kraška ulica",
        "Kratka pot",
        "Krpanova pot",
        "Levstikova ulica",
        "Lipiška cesta",
        "Lokavska cesta",
        "Mirna pot",
        "Na bregu",
        "Orleška cesta",
        "Osojna pot",
        "Partizanska cesta",
        "Pod gozdom",
        "Pod Planino",
        "Pod Sablanico",
        "Pod Sedovnikom",
        "Pod Taborom",
        "Prečna ulica",
        "Pregljeva ulica",
        "Regentova ulica",
        "Repentaborska cesta",
        "Rožna pot",
        "Sejmiška pot",
        "Sežanska ulica",
        "Skalna pot",
        "Skladiščna ulica",
        "Sončna pot",
        "Srebrničeva ulica",
        "Stara pot",
        "Starovaška pot",
        "Stjenkova ulica",
        "Stranska pot",
        "Strma pot",
        "Tomšičeva ulica",
        "Trg 28. avgusta",
        "Trg osvoboditve",
        "Ulica 1. maja",
        "Ulica Antona Bonete",
        "Ulica Avgusta Černigoja",
        "Ulica Cirila Zlobca",
        "Ulica Darka Marušiča",
        "Ulica Eme Starc",
        "Ulica Franca Segulina",
        "Ulica I. tankovske brigade",
        "Ulica Iga Grudna",
        "Ulica Ivana Rozmana",
        "Ulica Ivana Turšiča",
        "Ulica Josefa Ressla",
        "Ulica Jožeta Pahorja",
        "Ulica Mare Husove",
        "Ulica Mihaele Škapinove",
        "Ulica Mirka Pirca",
        "Ulica Nika Šturma",
        "Ulica Petra Kozlerja",
        "Ulica Pinka Tomažiča",
        "Ulica Pod borovci",
        "Ulica Ruže Petelinove",
        "Ulica talcev",
        "Vidmašče",
        "Vileniška ulica",
        "Vodovodna ulica",
        "Vojkova ulica",
        "Volaričeva ulica"
    ],
    "Radovljica": [
        "Bazoviška ulica",
        "Bevkova ulica",
        "Cankarjeva ulica",
        "Cesta na Jezerca",
        "Cesta svobode",
        "Gorenjska cesta",
        "Gradiška pot",
        "Gradnikova cesta",
        "Gubčeva ulica",
        "Jalnova cesta",
        "Janševa ulica",
        "Jelovška ulica",
        "Jurčičeva ulica",
        "Kajuhova ulica",
        "Kolodvorska ulica",
        "Kopališka cesta",
        "Kosovelova cesta",
        "Kranjska cesta",
        "Langusova ulica",
        "Linhartov trg",
        "Ljubljanska cesta",
        "Maistrova ulica",
        "Na Mlaki",
        "Partizanska pot",
        "Poljska pot",
        "Prešernova ulica",
        "Roblekovo naselje",
        "Savska cesta",
        "Šercerjeva ulica",
        "Špicarjeva ulica",
        "Štrukljeva ulica",
        "Tavčarjeva ulica",
        "Triglavska cesta",
        "Trubarjeva ulica",
        "Ulica Simona Gregorčiča",
        "Ulica Staneta Žagarja",
        "Ulica Stanka Lapuha",
        "Vurnikov trg",
        "Žale"
    ],
    "Zagorje ob Savi": [
        "Cankarjev trg",
        "Cesta 20. julija",
        "Cesta 9. avgusta",
        "Cesta Borisa Kidriča",
        "Cesta Otona Župančiča",
        "Cesta zmage",
        "Eberlova cesta",
        "Fakinova ulica",
        "Farčnikova kolonija",
        "Gasilska cesta",
        "Grajska ulica",
        "Kolodvorska cesta",
        "Kopališka ulica",
        "Levstikova ulica",
        "Marnova ulica",
        "Na bregu",
        "Naselje Srečka Kosovela",
        "Okrogarjeva kolonija",
        "Partizanska ulica",
        "Pintarjeva cesta",
        "Podvine",
        "Polje",
        "Prečna pot",
        "Prešernova cesta",
        "Šolska ulica",
        "Trboveljska cesta",
        "Ulica prvoborcev",
        "Ulica talcev",
        "Vinska cesta",
        "Vrtna ulica",
        "Selo pri Zagorju"
    ],
    "Črnomelj": [
        "Belokranjska cesta",
        "Bevkova ulica",
        "Butorajska cesta",
        "Cankarjeva ulica",
        "Čardak",
        "Čopova ulica",
        "Delavska pot",
        "Grajska cesta",
        "Gubčeva ulica",
        "Kajuhova ulica",
        "Kidričeva ulica",
        "Kočevje",
        "Kolodvorska cesta",
        "Krpanova ulica",
        "Kurirska steza",
        "Linhartova ulica",
        "Ločka cesta",
        "Majer",
        "Mala ulica",
        "Marentičeva ulica",
        "Metliška cesta",
        "Na bregu",
        "Nad progo",
        "Nazorjeva ulica",
        "Nova loka",
        "Partizanska pot",
        "Prečna ulica",
        "Prešernova ulica",
        "Pri mostu",
        "Pri stadionu",
        "Semiška cesta",
        "Sončna pot",
        "Šolska ulica",
        "Tomšičeva ulica",
        "Trdinova ulica",
        "Trg svobode",
        "Trubarjeva ulica",
        "Ulica 11. avgusta",
        "Ulica 21. oktobra",
        "Ulica Belokranjskega odreda",
        "Ulica bratov Klemenc",
        "Ulica Danila Bučarja",
        "Ulica Drage",
        "Ulica heroja Jožeta Mihelčiča",
        "Ulica heroja Stariha",
        "Ulica Lojzeta Fabjana",
        "Ulica Marjana Kozine",
        "Ulica Mirana Jarca",
        "Ulica Moša Pijade",
        "Ulica na Pristavah",
        "Ulica na Utrdbah",
        "Ulica ob Lozi",
        "Ulica Otona Župančiča",
        "Ulica padlih borcev",
        "Ulica pod gozdom",
        "Ulica pod lipo",
        "Ulica pod smreko",
        "Ulica Sadež",
        "Ulica Staneta Rozmana",
        "Viniška cesta",
        "Vodnikova ulica",
        "Vojinska cesta",
        "Vrtna ulica",
        "Zadružna cesta",
        "Zelena pot",
        "Železničarska cesta"
    ],
    "Medvode": [
        "Arharjeva ulica",
        "Barletova cesta",
        "Bečanova ulica",
        "Bergantova cesta",
        "Bernikova ulica",
        "Bizantova cesta",
        "Bogatajeva ulica",
        "Cesta komandanta Staneta",
        "Cesta na Senico",
        "Cesta na Svetje",
        "Cesta ob Sori",
        "Cesta ob železnici",
        "Cesta talcev",
        "Cesta v Bonovec",
        "Cesta v Žlebe",
        "Čarmanova ulica",
        "Čelesnikova ulica",
        "Dimčeva ulica",
        "Dobnikarjeva ulica",
        "Donova cesta",
        "Finžgarjeva ulica",
        "Gorenjska cesta",
        "Grajzerjeva ulica",
        "Hrastarjeva ulica",
        "Iztokova ulica",
        "Jamnikova ulica",
        "Kalanova ulica",
        "Kebetova ulica",
        "Klanska ulica",
        "Kržišnikova ulica",
        "Kuraltova ulica",
        "Kurirska cesta",
        "Medvoška cesta",
        "Na Čerenu",
        "Na Labornici",
        "Ostrovrharjeva ulica",
        "Podvizova ulica",
        "Preška cesta",
        "Seškova cesta",
        "Šetinova ulica",
        "Škofjeloška cesta",
        "Šlosarjeva ulica",
        "Šmalčeva ulica",
        "Štalčeva ulica",
        "Tehovnikova ulica",
        "Trampuževa ulica",
        "Trilerjeva ulica",
        "Turkova ulica",
        "Ulica Ivanke Ovijač",
        "Ulica k studencu",
        "Ulica ob gozdu",
        "Ulica Simona Jenka",
        "Višnarjeva ulica",
        "Zbiljska cesta",
        "Žontarjeva ulica"
    ],
    "Bled": [
        "Aljaževa cesta",
        "Alpska cesta",
        "Cankarjeva cesta",
        "Cesta Gorenjskega odreda",
        "Cesta svobode",
        "Cesta v Megre",
        "Cesta v Vintgar",
        "Črtomirova ulica",
        "Finžgarjeva cesta",
        "Gozdarska ulica",
        "Gradnikova cesta",
        "Grajska cesta",
        "Gregorčičeva ulica",
        "Grič",
        "Hrastova ulica",
        "Jamova ulica",
        "Jarška cesta",
        "Jelovška cesta",
        "Jermanka",
        "Kajuhova cesta",
        "Kidričeva cesta",
        "Kolodvorska cesta",
        "Koritenska cesta",
        "Koroška cesta",
        "Kumerdejeva ulica",
        "Levstikova ulica",
        "Ljubljanska cesta",
        "Mladinska cesta",
        "Mlinska cesta",
        "Na Jasi",
        "Na Plani",
        "Na Rebri",
        "Naselje Jaka Bernarda",
        "Otok",
        "Partizanska cesta",
        "Planinska cesta",
        "Pod Kozarco",
        "Pod Skalo",
        "Pod Stražo",
        "Poljska pot",
        "Poljšiška cesta",
        "Pot Franca Gornika",
        "Pot na Lisice",
        "Prečna ulica",
        "Prešernova cesta",
        "Prežihova cesta",
        "Prisojna ulica",
        "Razgledna cesta",
        "Rečiška cesta",
        "Ribenska cesta",
        "Riklijeva cesta",
        "Rožna ulica",
        "Savska cesta",
        "Sebenjska cesta",
        "Seliška cesta",
        "Selska cesta",
        "Slovenski trg",
        "Taleška ulica",
        "Triglavska cesta",
        "Trubarjeva cesta",
        "Ulica Jule Vovk Molnar",
        "Ulica Matije Čopa",
        "Ulica narodnih herojev",
        "Valvasorjeva ulica",
        "Veslaška promenada",
        "Za Gradom",
        "Za Pecovco",
        "Za Potokom",
        "Za Žago",
        "Zagoriška cesta",
        "Želeška cesta",
        "Župančičeva cesta"
    ],
    "Rogaška Slatina": [
        "Aškerčeva ulica",
        "Brestovška cesta",
        "Cankarjeva ulica",
        "Celjska cesta",
        "Cesta na Bellevue",
        "Cesta na Boč",
        "Cesta padlih aktivnistov NOV",
        "Cvetlični hrib",
        "Gozdna ulica",
        "Gubčeva ulica",
        "Ivanov hrib",
        "Izletniška ulica",
        "Janina",
        "Kajuhova ulica",
        "Kidričeva ulica",
        "Kneţec",
        "Kot",
        "Krpanova ulica",
        "Kvedrova ulica",
        "Lastine",
        "Levstikova ulica",
        "Linhartova ulica",
        "Ločen dol",
        "Lovska ulica",
        "Mladinska ulica",
        "Na livadi",
        "Na trati",
        "Ob progi",
        "Partizanska cesta",
        "Plečnikova ulica",
        "Pod Bellevuejem",
        "Prešernova ulica",
        "Prvomajska ulica",
        "Slomškova ulica",
        "Sončna ulica",
        "Sotelska cesta",
        "Spodnja cesta",
        "Steklarska ulica",
        "Stritarjeva ulica",
        "Strma cesta",
        "Šlandrova ulica",
        "Tavčarjeva ulica",
        "Trţaški hrib",
        "Ulica Ele Peroci",
        "Ulica Kozara",
        "Ulica Kozjanskega odreda",
        "Ulica talcev",
        "Ulica XIV. divizije",
        "Ulica Zrinjskega",
        "Vegova ulica",
        "Vid Ivanuševa ulica",
        "Zdraviliški trg",
        "Zlatorogova ulica",
        "Ţibernik",
        "Ţupančičeva ulica",
        "685"
    ],
    "Slovenske Konjice": [
        "Aškerčeva ulica",
        "Celjska cesta",
        "Cesta pod goro",
        "Cvetlična ulica",
        "Delavska cesta",
        "Grajska ulica",
        "Gubčeva ulica",
        "Industrijska cesta",
        "Kajuhova ulica",
        "Kletna ulica",
        "Lambrechtova ulica",
        "Liptovska ulica",
        "Mariborska ulica",
        "Mestni trg",
        "Mizarska cesta",
        "Mlinska cesta",
        "Ob potoku",
        "Obrtniška ulica",
        "Oplotniška cesta",
        "Pod gradom",
        "Podjetniška ulica",
        "Poljska cesta",
        "Prečna ulica",
        "Prešernova ulica",
        "Prevrat",
        "Prežihova ulica",
        "Rimska cesta",
        "Slomškova ulica",
        "Stari trg",
        "Šarhova ulica",
        "Škalska cesta",
        "Šolska ulica",
        "Štajerska ulica",
        "Taborniška ulica",
        "Tattenbachova ulica",
        "Tovarniška cesta",
        "Ulica Adolfa Tavčarja",
        "Ulica Anice Černejeve",
        "Ulica Antona Janše",
        "Ulica Borisa Vinterja",
        "Ulica dr. Rudolfa",
        "Ulica Dušana Jereba",
        "Ulica Ivana Minattija",
        "Ulica pri izviru",
        "Ulica Toneta Melive",
        "Usnjarska cesta",
        "Vešeniška cesta",
        "Vinogradna ulica",
        "Vrtna ulica",
        "Žička cesta"
    ],
    "Šentjur": [
        "Aškerčeva ulica",
        "Benjaminova ulica",
        "Cankarjeva ulica",
        "Cesta Kozjanskega odreda",
        "Cesta Leona Dobrotinška",
        "Cesta Miloša Zidanška",
        "Cesta na Brdo",
        "Cesta na Goro",
        "Cesta na grad",
        "Cesta na kmetijsko šolo",
        "Cesta na Rifnik",
        "Cesta pod Rifnikom",
        "Cesta Valentina Orožna",
        "Cvetna ulica",
        "Črnoliška ulica",
        "Delavska ulica",
        "Drofenikova ulica",
        "Gajstova pot",
        "Goriška ulica",
        "Gregorčičeva ulica",
        "Ilirska ulica",
        "Industrijska ulica",
        "Jerinova ulica",
        "Kolodvorska ulica",
        "Lahova ulica",
        "Lepa pot",
        "Leskovškova ulica",
        "Lesna ulica",
        "Liškova ulica",
        "Ljubljanska cesta",
        "Maistrova ulica",
        "Mestni trg",
        "Na Lipico",
        "Na Livadi",
        "Na Razgled",
        "Na Tičnico",
        "Obrtna ulica",
        "Pešnica",
        "Pod gozdom",
        "Pod Rebrami",
        "Pod Resevno",
        "Pod vrbco",
        "Poljska ulica",
        "Prešernova ulica",
        "Prijateljeva ulica",
        "Ripšlova ulica",
        "Romihova ulica",
        "Sončna ulica",
        "Svetinova ulica",
        "Tartinijeva ulica",
        "Trubarjeva ulica",
        "Ulica A.M. Slomška",
        "Ulica Blaža Kocena",
        "Ulica Dušana Kvedra",
        "Ulica Franja Malgaja",
        "Ulica Franja Vrunča",
        "Ulica Franja Žagarja",
        "Ulica I. celjske čete",
        "Ulica II. bataljona",
        "Ulica Matije Gubca",
        "Ulica skladateljev Ipavcev",
        "Ulica talcev",
        "Ulica Tončke Čečeve",
        "Ulica Toneta Seliškarja",
        "Vrtna ulica",
        "Zvezdna ulica"
    ],
    "Žalec": [
        "Aljaževa ulica",
        "Arničeva ulica",
        "Aškerčeva ulica",
        "Bevkova ulica",
        "Bilgerjeva ulica",
        "Cankarjeva ulica",
        "Celjska cesta",
        "Cesta Josipa Širca",
        "Cesta na Lavo",
        "Cesta na Vrbje",
        "Cesta ob železnici",
        "Cesta Žalskega tabora",
        "Čopova ulica",
        "Efenkova ulica",
        "Gotoveljska cesta",
        "Gregorčičeva ulica",
        "Gubčeva ulica",
        "Hausenbichlerjeva ulica",
        "Hmeljarska ulica",
        "Ipavčeva ulica",
        "Jurčičeva ulica",
        "Kajuhova ulica",
        "Kidričeva ulica",
        "Kvedrova ulica",
        "Levstikova ulica",
        "Maistrova ulica",
        "Mestni trg",
        "Oničeva ulica",
        "Partizanska ulica",
        "Pečnikova ulica",
        "Pohorska ulica",
        "Pokopališka cesta",
        "Prešernova ulica",
        "Prežihova ulica",
        "Roševa ulica",
        "Savinjska cesta",
        "Stritarjeva ulica",
        "Šarhova ulica",
        "Šilihova ulica",
        "Šlandrov trg",
        "Šprajčeva ulica",
        "Tomšičeva ulica",
        "Triglavska ulica",
        "Trubarjeva ulica",
        "Ulica bratov Letonja",
        "Ulica Florjana Pohlina",
        "Ulica heroja Staneta",
        "Ulica Ivanke Uranjek",
        "Ulica Janka Kača",
        "Ulica Nade Cilenšek",
        "Ulica Nikole Tesla",
        "Ulica Rista Savina",
        "Ulica Savinjske čete",
        "Ulica talcev",
        "Velenjska cesta",
        "Vodnikova ulica",
        "Vrečerjeva ulica",
        "Župančičeva ulica"
    ],
    "Hrastnik": [
        "Pot Josipa Brinarja",
        "Pot na Kal",
        "Cesta Adama Dušaka",
        "Ulica prvoborcev",
        "Trg Franca Kozarja",
        "Cesta padlih borcev",
        "Naselje Aleša Kaple",
        "Pot Vitka Pavliča",
        "Novi log",
        "Veličkova cesta",
        "Novi dom",
        "Cesta 1. maja",
        "Log"
    ],
    "Prevalje": [
        "Perzonali",
        "Zgornji kraj",
        "Stare sledi",
        "Prisoje",
        "Trg",
        "Stare sledi",
        "Glavarstvo",
        "Nicina",
        "Ob Meži",
        "Prisoje",
        "Na Produ",
        "Polje",
        "Pri postaji",
        "Ugasle peči",
        "Na Fari",
        "Pod gonjami",
        "Spodnji kraj",
        "Pri Brančurniku"
    ],
    "Sevnica": [
        "Bohorska ulica",
        "Cankarjeva ulica",
        "Cesta na Dobravo",
        "Cesta na grad",
        "Dalmatinova ulica",
        "Drožanjska cesta",
        "Florjanska ulica",
        "Glavni trg",
        "Grič",
        "Gubčeva ulica",
        "Hermanova cesta",
        "Kajuhova ulica",
        "Kidričeva ulica",
        "Kladnikova ulica",
        "Kozjanska ulica",
        "Krulejeva ulica",
        "Kvedrova cesta",
        "Naselje heroja Maroka",
        "Ob gozdu",
        "Planinska cesta",
        "Pod Vrtačo",
        "Pot na Zajčjo goro",
        "Prešernova ulica",
        "Prvomajska ulica",
        "Ribniki",
        "Savska cesta",
        "Stanetova ulica",
        "Šmarska cesta",
        "Taborniška ulica",
        "Trg svobode",
        "Trubarjeva ulica",
    ],
    "Ilirska Bistrica": [
        "Adamičeva ulica",
        "Aljažev breg",
        "Bazoviška cesta",
        "Bilčeva pot",
        "Bobkova ulica",
        "Brinškova ulica",
        "Cankarjeva ulica",
        "Gabrije",
        "Gradnikova ulica",
        "Gregorčičeva cesta",
        "Gubčeva ulica",
        "Hrib svobode",
        "Jurčičeva ulica",
        "Kettejeva ulica",
        "Kidričeva ulica",
        "Kosovelova ulica",
        "Levstikova ulica",
        "Maistrova ulica",
        "Partizanski hrib",
        "Pod Stražico",
        "Podgrajska ulica",
        "Prešernova ulica",
        "Rozmanova ulica",
        "Snežniška ulica",
        "Stritarjeva ulica",
        "Strma pot",
        "Šercerjeva cesta",
        "Tavčarjeva ulica",
        "Trg maršala Tita",
        "Trnovska ulica",
        "Trubarjeva ulica",
        "Ulica 7. maja",
        "Ulica IV. armije",
        "Ulica Nikola Tesla",
        "Ulica Toneta Tomšiča",
        "Vilharjeva cesta",
        "Vodnikova ulica",
        "Vojkov drevored",
        "Župančičeva ulica"
    ],
    "Ruše": [
        "Areška cesta",
        "Aškerčeva ulica",
        "Bezenska pot",
        "Binderjeva ulica",
        "Buhmajstrova ulica",
        "Cvetlična ulica",
        "Dobrova",
        "Dolinškova ulica",
        "Drevoredna ulica",
        "Falska cesta",
        "Gasilska ulica",
        "Gimnazijska ulica",
        "Glazerjeva ulica",
        "Golobova ulica",
        "Gozdarska ulica",
        "Grizoldova ulica",
        "Hmeljarska ulica",
        "Ilirska ulica",
        "Industrijska ulica",
        "Jamnikova ulica",
        "Jezernikova ulica",
        "Jugova ulica",
        "Kidričeva ulica",
        "Knifičeva ulica",
        "Knupleževa ulica",
        "Kolodvorska ulica",
        "Kopališka ulica",
        "Koroška ulica",
        "Košeninova ulica",
        "Kovaška ulica",
        "Kratka ulica",
        "Kurirska pot",
        "Lesjakova ulica",
        "Lobniška ulica",
        "Mali Beograd",
        "Mariborska cesta",
        "Matavškova ulica",
        "Minarikova ulica",
        "Na livadi",
        "Ob Dravi",
        "Ob gozdu",
        "Ob nasipu",
        "Ob železnici",
        "Pod goroj",
        "Pogačnikova ulica",
        "Prečna ulica",
        "Pri kolodvoru",
        "Pri viaduktu",
        "Segulinova ulica",
        "Selniška cesta",
        "Sernčeva ulica",
        "Sornikova ulica",
        "Stadionska ulica",
        "Stubiška ulica",
        "Šarhova pot",
        "Škerbotova ulica",
        "Šolska ulica",
        "Špengova ulica",
        "Šušmeljeva ulica",
        "Toledova ulica",
        "Tovarniška cesta",
        "Trg vstaje",
        "Trpinova ulica",
        "Ulica Črnkovih",
        "Ulica Minke Namestnik",
        "Ulica Norberta Vebra",
        "Ulica Osvobodilne fronte",
        "Ulica Pohorskega bataljona",
        "Ulica Ruške čete",
        "Ulica Silve Breznik",
        "Urbasova ulica",
        "Vargova ulica",
        "Vertnikova ulica",
        "Vinska pot",
        "Vivatova ulica",
        "Vodovnikova ulica",
        "Vranjekova ulica",
        "Vrtna ulica",
        "Zavita ulica"
    ],
    "Cerknica": [
        "Bračičeva ulica",
        "Brestova ulica",
        "Casermanova ulica",
        "Cesta 4. maja",
        "Cesta na Jezero",
        "Cesta pod Slivnico",
        "Cesta v Dolenjo vas",
        "Čabranska ulica",
        "Gasparijeva ulica",
        "Gerbičeva ulica",
        "Hacetova ulica",
        "Hribarjeva ulica",
        "Kamna gorica",
        "Kebetova ulica",
        "Kovačeva ulica",
        "Kraška ulica",
        "Loško",
        "Lovkova ulica",
        "Notranjska cesta",
        "Partizanska cesta",
        "Peščenk",
        "Petrovčičeva ulica",
        "Popkova ulica",
        "Primšarjeva ulica",
        "Rimska ulica",
        "Sinja gorica",
        "Šercerjeva ulica",
        "Tabor",
        "Turšičeva ulica",
        "Ulica za mlinom",
        "Ulica za vrtovi",
        "Videm",
        "Zidanškova ulica"
    ],
    "Trebnje": [
        "Baragov trg",
        "Cankarjeva ulica",
        "Cesta Vilme Bukovec",
        "Cviblje",
        "Dolenjska ulica",
        "Fiktivna ulica",
        "Glavarjeva ulica",
        "Goliev trg",
        "Gubčeva cesta",
        "Industrijska ulica",
        "Jurčičeva ulica",
        "Kidričeva ulica",
        "Klemenčičeva ulica",
        "Kolodvorska ulica",
        "Kresetova ulica",
        "Kresna ulica",
        "Levstikova ulica",
        "Maistrova ulica",
        "Majcnova ulica",
        "Marof",
        "Metelkova ulica",
        "Mirnska cesta",
        "Ob gozdu",
        "Obrtniška ulica",
        "Paradiž",
        "Pehanijeva ulica",
        "Pod Gradom",
        "Podjetniška ulica",
        "Pot na Hrib",
        "Praproče",
        "Prešernova ulica",
        "Prijateljeva cesta",
        "Pristava",
        "Reber",
        "Režunova ulica",
        "Rimska cesta",
        "Rožna ulica",
        "Simončičeva ulica",
        "Slakova ulica",
        "Stari trg",
        "Temeniška pot",
        "Tomšičeva ulica",
        "Trdinova ulica",
        "Trubarjeva ulica",
        "Ulica herojev",
        "Ulica OF",
        "Ulica Talcev",
        "Ulica Velike Gorice",
        "Valvazorjeva ulica",
        "Vegova ulica",
        "Vina Gorica"
    ],
    "Tržič": [
        "Balos",
        "Blejska cesta",
        "Cankarjeva cesta",
        "Cerkvena ulica",
        "Cesta Ste Marie aux mines",
        "Čevljarska ulica",
        "Fužinska ulica",
        "Koroška cesta",
        "Kosarska ulica",
        "Kovaška ulica",
        "Kranjska cesta",
        "Kukovniška pot",
        "Kurnikova pot",
        "Muzejska ulica",
        "Paradiž",
        "Partizanska ulica",
        "Pot na pilarno",
        "Pot na Zali Rovt",
        "Predilniška cesta",
        "Prehod",
        "Preska",
        "Ravne",
        "Šolska ulica",
        "Trg svobode",
        "Usnjarska ulica",
        "Virje",
        "Za jezom",
        "Za Mošenikom",
        "MTB Trail K41",
        "Preska"
    ],
    "Ribnica": [
        "Bukovška ulica",
        "Cesta na Ugar",
        "Čolnarska ulica",
        "Gallusovo nabrežje",
        "Gorenjska cesta",
        "Gornje Lepovče",
        "Grajska pot",
        "Kettejeva ulica",
        "Knafljev trg",
        "Kolodvorska ulica",
        "Kosovelova ulica",
        "Krošnjarska pot",
        "Kurirska pot",
        "Lepovče",
        "Levstikova ulica",
        "Ljubljanska cesta",
        "Majnikova ulica",
        "Merharjeva ulica",
        "Ob Bistrici",
        "Ob železnici",
        "Obrtna cona Ugar",
        "Opekarska cesta",
        "Podgorska cesta",
        "Prečna ulica",
        "Prešernova ulica",
        "Prijateljev trg",
        "Struška ulica",
        "Šeškova ulica",
        "Škrabčev trg",
        "Šolska ulica",
        "Topniška ulica",
        "Trubarjeva ulica",
        "Ulica Jožeta Petka",
        "Ulica Louisa Adamiča",
        "Ulica talcev",
        "Urbanova ulica",
        "Vodnikova cesta",
        "Vrtnarska cesta",
        "Vrvarska pot"
    ],
    "Žiri": [
        "Bedrih",
        "Cesta na Ledinico",
        "Čevljarska ulica",
        "Delavska ulica",
        "Dobračevska ulica",
        "Dražgoška ulica",
        "Gasilska ulica",
        "Gregorčičeva ulica",
        "Idrijska cesta",
        "Igriška ulica",
        "Industrijska ulica",
        "Jezerska ulica",
        "Jobstova cesta",
        "Kajuhova ulica",
        "Kosovelova ulica",
        "Levstikova ulica",
        "Logaška cesta",
        "Loška cesta",
        "Mladinska ulica",
        "Na Studencu",
        "Novovaška cesta",
        "Ob potoku",
        "Opekarska ulica",
        "Partizanska cesta",
        "Pionirska pot",
        "Pod griči",
        "Pod Žirkom",
        "Polje",
        "Pot na Koče",
        "Pot na Rovt",
        "Pot v Goropeke",
        "Pot v skale",
        "Prvomajska ulica",
        "Rakovnik",
        "Rakulk",
        "Rudniška ulica",
        "Sejmiška ulica",
        "Slovenska pot",
        "Starovaška cesta",
        "Starožirovska cesta",
        "Strojarska ulica",
        "Tabor",
        "Tavčarjeva ulica",
        "Trg svobode",
        "Triglavska ulica",
        "Ulica Maksima Sedeja",
        "Župančičeva ulica"
    ],
    "Lenart": [
        "Vrtna ulica",
        "Radehova",
        "Sadjarska ulica",
        "Ribiška pot",
        "Pod bregom",
        "Pot na Kamenšak",
        "Ulica heroja Lacka",
        "Gregorčičeva ulica",
        "Prežihova ulica",
        "Ulica Ele Kristl",
        "Strma pot",
        "Cvetlična ulica",
        "Ob Poleni",
        "Maistrova ulica",
        "Ptujska cesta",
        "Jurčičeva ulica",
        "Ilaunigova ulica",
        "Cafova ulica",
        "Gubčeva ulica",
        "Cankarjeva ulica",
        "Nikova ulica",
        "Partizanska cesta",
        "Mariborska cesta",
        "Kraigherjeva ulica",
        "Obrtna ulica",
        "Kidričeva ulica",
        "Jurovska cesta",
        "Industrijska ulica",
        "Cmureška cesta"
    ],
    "Ljutomer": [
        "Aškerčeva ulica",
        "Babinska cesta",
        "Bratov Pihlar ulica",
        "Cankarjeva cesta",
        "Cesta I. slovenskega tabora",
        "Cesta na Vilo",
        "Cvetka Golarja ulica",
        "Cvetlična ulica",
        "Fulneška ulica",
        "Glavni trg",
        "Grossmanova ulica",
        "Industrijska ulica",
        "Ivana Kaučiča cesta",
        "Janka Ribiča ulica",
        "Jeruzalemska cesta",
        "Joška Berdena ulica",
        "Jurčičeva ulica",
        "Jureša Cirila ulica",
        "Kajuhova ulica",
        "Kerenčičeva ulica",
        "Kidričeva ulica",
        "Kolodvorska ulica",
        "Lendavska cesta",
        "Maistrova ulica",
        "Mestni breg",
        "Miklošičev trg",
        "Ormoška cesta",
        "Poljska pot",
        "Postružnikova ulica",
        "Prešernova ulica",
        "Ptujska cesta",
        "Rajh Nade ulica",
        "Razlagova ulica",
        "Slavka Osterca ulica",
        "Soboška cesta",
        "Staneta Rozmana ulica",
        "Stari trg",
        "Trg Jakoba Babiča",
        "Ulica 9. maja",
        "Ulica Ante Trstenjaka",
        "Ulica dr. Franca Kovačiča",
        "Ulica Jana Baukarta",
        "Ulica Ludvika Bratuša",
        "Ulica ob hipodromu",
        "Ulica ob progi",
        "Ulica Rada Pušenjaka",
        "Ulica V. prekomorske brigade",
        "Užiška ulica",
        "Viktorja Kukovca ulica",
        "Vinka Megle ulica",
        "Volkmerjeva ulica",
        "Vrazova ulica",
        "Vrtna ulica",
        "Zacherlova ulica"
    ],
    "Laško": [
        "Aškerčev trg",
        "Badovinčeva ulica",
        "Celjska cesta",
        "Cesta na Gaberno",
        "Cesta na Lahomšek",
        "Cesta na Svetino",
        "Cesta v Debro",
        "Cesta v Rečico",
        "Čedejeva ulica",
        "Čopova ulica",
        "Kajuhova ulica",
        "Keršetova ulica",
        "Kidričeva ulica",
        "Kopitarjeva ulica",
        "Mestna ulica",
        "Na Pristavi",
        "Na Rebri",
        "Orožnov trg",
        "Pivovarniška ulica",
        "Ploščad prijateljstva",
        "Pod gradom",
        "Podhumska ulica",
        "Podšmihel",
        "Pot na grad",
        "Pot na Kobivjek",
        "Pot na Šmohor",
        "Poženelova ulica",
        "Rimska cesta",
        "Rožnik",
        "Savinjsko nabrežje",
        "Sončna pot",
        "Stegenškova ulica",
        "Šercerjeva ulica",
        "Taborje",
        "Trg svobode",
        "Trubarjeva ulica",
        "Trubarjevo nabrežje",
        "Valentiničeva cesta",
        "Valvasorjev trg",
        "Zdraviliška cesta"
    ],
    "Metlika": [
        "Badovinčeva ulica",
        "Berčice",
        "Bočka",
        "Breg revolucije",
        "Cankarjeva cesta",
        "Cesta 9. maja",
        "Cesta bratstva in enotnosti",
        "Cesta Josipa Savinška",
        "Cesta XV. brigade",
        "Črnomaljska cesta",
        "Frelihova ulica",
        "Ganglova ulica",
        "Gubčeva ulica",
        "Hočevarjeva ulica",
        "Jarčeva ulica",
        "Jerebova ulica",
        "Kolodvorska ulica",
        "Kratka pot",
        "Levstikova ulica",
        "Marentičeva ulica",
        "Mestni log",
        "Mestni trg",
        "Na Lazah",
        "Na Obrh",
        "Na Požeg",
        "Nad Sušico",
        "Naselje Borisa Kidriča",
        "Naselje Staneta Rozmana",
        "Navratilova pot",
        "Partizanski trg",
        "Pot na Veselico",
        "Pot v Strmec",
        "Prečna pot",
        "Stara cesta",
        "Strma pot",
        "Šestova ulica",
        "Šolska ulica",
        "Tomšičeva ulica",
        "Tovarniška cesta",
        "Trdinova pot",
        "Trg svobode",
        "Ulica 1. maja",
        "Ulica Belokranjskega odreda",
        "Ulica Janka Brodariča",
        "Ulica Marjana Kozine",
        "Ulica Milke Šobar",
        "Ulica Na trg",
        "Ulica ob parku",
        "Ulica pod brezami",
        "Ulica pod gabri",
        "Ulica pod hrasti",
        "Ulica pod kostanji",
        "Ulica Wagna",
        "Ulica XIV. divizije",
        "Vejar",
        "Vinogradniška cesta",
        "Župančičeva cesta",
    ],
    "Tolmin": [
        "Gregorčičeva ulica",
        "Pod gradom",
        "Ulica Cirila Kosmača",
        "Soška ulica",
        "Bevkova ulica",
        "Ilirska ulica",
        "Grajska ulica",
        "Žagarjeva ulica",
        "Triglavska ulica",
        "Ulica Tolminskega punta",
        "Brunov drevored",
        "Na Petelincu",
        "Pregljev trg",
        "Na hribih",
        "Kosovelova ulica",
        "Prešernova ulica",
        "Bazoviška ulica",
        "Tumov drevored",
        "Padlih Borcev",
        "Dijaška ulica",
        "Mestni trg",
        "Rutarjeva ulica",
        "Breg",
        "Klanec",
        "Na Rojah",
        "Podbreg",
        "Lavričeva ulica",
        "Vojkova ulica",
        "Pod klancem",
        "Na logu",
        "Trg Tigrovcev",
        "Ulica padlih borcev",
        "Mestni log",
        "Pod brajdo",
    ],
    "Gornja Radgona": [
        "Vaupotičeva ulica",
        "Vodnikova ulica",
        "Vodovodna ulica",
        "Vrazova ulica",
        "Vrečova cesta",
        "Vrtna ulica",
        "Cankarjeva cesta",
        "Cesta na Stadion",
        "Ciril-Metodova cesta",
        "Čremošnikova ulica",
        "Dajnkova ulica",
        "Delavska pot",
        "Gasilska cesta",
        "Gorkega ulica",
        "Grajska cesta",
        "Gubčeva cesta",
        "Horvatova ulica",
        "Ilirska ulica",
        "Jurkovičeva ulica",
        "Kajuhova ulica",
        "Kerenčičeva ulica",
        "Kocljeva ulica",
        "Lackova ulica",
        "Leninova ulica",
        "Ljutomerska cesta",
        "Maistrov trg",
        "Mala ulica",
        "Mariborska cesta",
        "Miklošičeva ulica",
        "Mladinska ulica",
        "Ob potoku",
        "Ob progi",
        "Panonska ulica",
        "Partizanska cesta",
        "Pintaričeva cesta",
        "Pod gozdom",
        "Pokopališka cesta",
        "Polička cesta",
        "Porabska ulica",
        "Prečna ulica",
        "Prešernova cesta",
        "Prežihova ulica",
        "Prvomajska ulica",
        "Rozmanova ulica",
        "Simoničev breg",
        "Strma ulica",
        "Šlebingerjev breg",
        "Trate",
        "Trg svobode",
        "Trstenjakova ulica",
        "Trubarjeva ulica",
        "Ulica Marije Rožman",
        "Ulica Moše Pijade",
        "Ulica Silvire Tomassini",
        "Vaupotičeva ulica",
        "Vodnikova ulica",
        "Vodovodna ulica",
        "Vrazova ulica",
        "Vrečova cesta",
        "Vrtna ulica",
        "Apaška cesta"
    ],
    "Mežica": [
        "Celovška cesta",
        "Fužinarska pot",
        "Gosposvetska ulica",
        "Knapovška ulica",
        "Kopališka pot",
        "Lenartova ulica",
        "Leška cesta",
        "Malgajeva ulica",
        "Mariborska cesta",
        "Moderndorferjeva pot",
        "Mrvovo",
        "Ob Meži",
        "Ob Šumcu",
        "Partizanska cesta",
        "Pod gmajno",
        "Podjunska ulica",
        "Podpeška pot",
        "Polena",
        "Prežihova ulica",
        "Senčna vas",
        "Smrečnikovo",
        "Stržovo",
        "Šolska pot",
        "Trg 4. aprila",
        "Trg svobode",
        "Ulica kralja Matjaža"
    ],
    "Dravograd": [
        "Dravska ulica",
        "Koroška cesta",
        "Mariborska cesta",
        "Meža",
        "Pod gradom",
        "Prežihova ulica",
        "Ribiška pot",
        "Robindvor",
        "Trg 4. julija",
        "Viška cesta",
        "1"
    ],
    "Zreče": [
        "Bork",
        "Breg",
        "Brinjeva gora",
        "Cankarjeva ulica",
        "Cesta na Gorenje",
        "Cesta na Roglo",
        "Cesta pod hribom",
        "Cesta talcev",
        "Dobroveljska cesta",
        "Dravinjska cesta",
        "Ilirska pot",
        "Jamniška cesta",
        "Kovaška cesta",
        "Kratka pot",
        "Mladinska ulica",
        "Ob gozdu",
        "Obrtniška cesta",
        "Obvozna cesta",
        "Ogljarska ulica",
        "Pohorska cesta",
        "Prešernova cesta",
        "Rudniška cesta",
        "Slomškova ulica",
        "Strma cesta",
        "Šarhova ulica",
        "Šolska cesta",
        "Štajerska ulica",
        "Tovarniška cesta",
        "Ulica 12. oktober",
        "Ulica Borisa Vinterja",
        "Ulica bratov Mernik",
        "Ulica Janeza Koprivnika",
        "Ulica Jurija Vodovnika",
        "Ulica Pohorskega bataljona",
        "Vinogradna cesta",
        "Vodnikova ulica",
        "Vodovodna cesta",
        "Zlakovška cesta"
    ],
    "Šoštanj": [
        "Aškerčeva cesta",
        "Cankarjeva cesta",
        "Cesta heroja Gašperja",
        "Cesta heroja Rozmana",
        "Cesta heroja Šercerja",
        "Cesta Lole Ribarja",
        "Cesta Matije Gubca",
        "Cesta talcev",
        "Glavni trg",
        "Goriška cesta",
        "Gregorčičeva cesta",
        "Kajuhova cesta",
        "Koroška cesta",
        "Levstikova cesta",
        "Metleče",
        "Nova ulica",
        "Partizanska pot",
        "Prešernov trg",
        "Primorska cesta",
        "Šlandrova pot",
        "Tekavčeva cesta",
        "Tovarniška pot",
        "Trg Jožeta Lampreta",
        "Trg svobode",
        "Vodnikova cesta",
        "Trg bratov Mravljakov",
        "425"
    ],
    "Železniki": [
        "Češnjica",
        "Dašnica",
        "Jesenovec",
        "Log",
        "Na Kresu",
        "Na Plavžu",
        "Otoki",
        "Racovnik",
        "Škovine",
        "Trnje",
        "Železniki",
        "635",
        "Studeno"
    ],
    "Lendava / Lendva": [
        "Industrijska ulica",
        "Tomšičeva ulica / Tomšič utca",
        "Kolodvorska ulica / Vasút utca",
        "Kranjčeva ulica / Kranjec utca",
        "Rudarska ulica / Bányász utca",
        "Panonsko naselje / Pannon-lakótelep",
        "Kidričeva ulica / Kidric utca",
        "Ulica heroja Mohorja / Hős Mohor utca",
        "Mlinska ulica / Malom utca",
        "Ulica Sv. Štefana / Szent István utca",
        "Slomškovo naselje / Slomšek-lakótelep",
        "Glavna ulica / Fő utca",
        "Ulica Vladimira Nazorja / Vladimir Nazor utca",
        "Cankarjeva ulica / Cankar utca",
        "Kajuhova ulica / Kajuh utca",
        "Gregorčičeva ulica / Gregorčič utca",
        "Kajuhova ulica"
    ],
    "Ormož": [
        "Ptujska cesta",
        "Ulica kneza Koclja",
        "Vrtnarska ulica",
        "Cvetlična ulica",
        "Gubčeva ulica",
        "Ulica dr. Hrovata",
        "Dravska ulica",
        "Ulica heroja Megle",
        "Ulica Ksaverja Meška",
        "Ulica dr. Kelemine",
        "Dobravska ulica",
        "Skolibrova ulica",
        "Poštna ulica",
        "Žigrova ulica",
        "Ilirska ulica",
        "Kolodvorska cesta",
        "Vrazova ulica",
        "Raičeva ulica",
        "Gregoričeva ulica",
        "Rakuševa ulica",
        "Ljutomerska cesta",
        "Vinarska ulica",
        "Ulica dr. Kovačiča",
        "Ulica heroja Kerenčiča",
        "Ulica dr. Ozvalda",
        "Ob ribniku",
        "Opekarniška cesta",
        "Dolga lesa",
        "Novakova cesta"
    ],
    "Radeče": [
        "Kolenov graben",
        "Pot na brod",
        "Na Dobravo",
        "Ulica Milana Majcna",
        "Šolska pot",
        "Ulica talcev",
        "Starograjska ulica",
        "Trg",
        "Ulica OF",
        "Pot na stadion",
        "Pokopališka cesta",
        "Titova ulica",
        "Pot na jez",
        "Nad Mliščico",
        "Krakovo",
        "Ulica 11. maja",
        "Pod Knapovko",
        "Strma ulica",
        "Vodovodna ulica",
        "Pod skalo",
        "Ulica Marjana Nemca",
        "Ulica Milana Kosa",
        "Pod Pečico"
    ],
    "Bovec": [
        "Brdo",
        "Dvor",
        "Industrijska cona",
        "Kaninska vas",
        "Klanc",
        "Kot",
        "Ledina",
        "Mala vas",
        "Podklopca",
        "Ravni laz",
        "Rupa",
        "Trg golobarskih žrtev",
        "Vodenca",
        "Zavrzelno"
    ],
    "Višnja Gora": [
        "Cesta Dolenjskega odreda",
        "Cesta na Polževo",
        "Cesta talcev",
        "Ciglerjeva ulica",
        "Čandkova ulica",
        "Gasilska ulica",
        "Grintovec",
        "Jurčičeva cesta",
        "Kopališka ulica",
        "Mestni trg",
        "Partizanska cesta",
        "Pot v Košco",
        "Preglje",
        "Sodnijska ulica",
        "Sokolska ulica",
        "Turnherjeva ulica",
        "Ulica Antona Tomšiča",
        "Žabjek",
        "Partizanska cesta"
    ],
    "Kostanjevica na Krki": [
        "Gorjanska cesta",
        "Gorjupova cesta",
        "Grajska cesta",
        "Hmeljska cesta",
        "Kambičev trg",
        "Krška cesta",
        "Ljubljanska cesta",
        "Oražnova ulica",
        "Resljeva cesta",
        "Ulica Talcev",
        "Vodeniška cesta"
    ]
}

export default SLOVINSKE_MESTA;