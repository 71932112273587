import { useEffect, useState } from "react";

import LogginComponent from "../../components/home/Home";
import Store from "./Store";

import Loading from "./Loading";

import { localHostConf, url } from "../../config";
const axios = require("axios").default;

export default function UserLogged(props) {
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.addEventListener("sign-out-event", signOut)

        axios.get(url + "/private/rest/auth-check")
            .then(res => {
                Store.setObject("loggedUser", res.data);
                setIsLogged(true);
            })
            .catch(err => { })
            .then(() => {
                setLoading(false);
            });

        return () => {
            window.removeEventListener("sign-out-event", signOut)
        }
    }, [])

    const signOut = () => {
        if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !localHostConf.enabled) {
            axios.defaults.headers.common['Authorization'] = "";
        }

        setIsLogged(false);
    }

    return (
        loading ? (
            <Loading loadingType='fullscreenSpinner'></Loading>
        ) : (
            <>
                {isLogged ? { ...props.children } : <LogginComponent
                    onSignIn={() => setIsLogged(true)}
                    onSignOut={() => setIsLogged(false)}
                />}
            </>
        )
    )
}