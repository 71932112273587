import React, { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import { Loading, ModalContent } from "../../../../common";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { url } from "../../../../config";
const axios = require("axios").default;

export default function Banners({ initDetailData, loadBanners, closeDetail }) {
    const [modalConfig, setModalConfig] = useState({ show: false });
    const [showHtmlEditor, setShowHtmlEditor] = useState(false);
    const [detailData, setDetailData] = useState(initDetailData);
    const [loading, setLoading] = useState(true);

    const [content, setContent] = useState();

    useEffect(() => {
        setContent(initDetailData.content)
    }, [])

    const toggleShowEditHtml = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setShowHtmlEditor(!showHtmlEditor);
    };

    const deleteBanner = () => {
        let responseMessage = "";

        axios.delete(url + "/private/rest/admin/custom-content/" + detailData.id)
            .then(res => {
                loadBanners();
                responseMessage = "Vymazanie úspešné";
            })
            .catch(err => {
                responseMessage = "Vymazanie sa voláko nepodarilo";
                console.log(err)
            })
            .then(res => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            });
    }

    const deleteBannerModal = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vymazať tento banner ? </b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => deleteBanner()}>Vymazať</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const saveDataHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());

        if (detailData.active) {
            let publishedFrom = new Date(formDataObj.publishedFrom);
            let publishedTo = new Date(formDataObj.publishedTo);

            var localISOpublishedFrom = publishedFrom.toISOString();
            var localISOpublishedTo = publishedTo.toISOString();
        }

        //ten blbý wysiwyg pridáva medzeru za iframe, tak ho vymažem
        let _content = content;
        if (_content.endsWith("<p><br></p>")) {
            _content = _content.substring(0, _content.length - 11);
        }


        let finalDataObj = {
            ...detailData,
            ...formDataObj,
            content: _content,
            publishedFrom: localISOpublishedFrom,
            publishedTo: localISOpublishedTo,
        }

        setLoading(true);

        if (detailData.id === 'CREATE') {
            axios
                .post(url + `/private/rest/admin/custom-content`, finalDataObj)
                .then((res) => {
                    //loadingDorobim
                    setLoading(false);
                    loadBanners();
                    closeDetail();
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {
            axios
                .put(url + `/private/rest/admin/custom-content/` + detailData.id, finalDataObj)
                .then((res) => {
                    //loadingDorobim
                    setLoading(false);
                    loadBanners();
                    closeDetail();
                })
                .catch((err) => {
                    console.log(err);
                });
        }

    };

    return (
        <>
            <Form onSubmit={(val) => saveDataHandler(val)} className="border mb-3 mx-1 row adminDetail">
                {
                    detailData.id == "CREATE" && (
                        <h4 className="color-kuzel mt-3">Vytvorenie nového banneru</h4>
                    )
                }

                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Názov</Form.Label>
                    <Form.Control size="md" type="text" required={true} defaultValue={detailData.name} name="name" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Hlavička</Form.Label>
                    <Form.Control size="md" type="text" required={true} defaultValue={detailData.title} name="title" />
                </Form.Group>
                <div className="my-3">
                    <ReactQuill theme="snow" value={content} onChange={setContent} />
                </div>

                <div className="mb-3">
                    <button className="btn btn-secondary " onClick={(e) => toggleShowEditHtml(e)}>Uprav HTML</button>
                </div>

                {
                    showHtmlEditor && (
                        <Form.Group className="col-12 mb-2">
                            <Form.Control size="md" type="text" as="textarea" rows={8} required="{true}" name="content" defaultValue={content} onBlur={(e) => setContent(e.target.value)} />
                        </Form.Group>
                    )
                }

                <Form.Group className="col-12 mb-2">
                    <Form.Label>Popis</Form.Label>
                    <Form.Control size="md" type="text" rows={2} name="description" defaultValue={detailData.description} />
                </Form.Group>

                <Form.Group className="col-12 mt-3">
                    <Form.Check
                        inline
                        label="Aktívny"
                        type="radio"
                        id="active-1"
                        checked={detailData.active}
                        onChange={() => {
                            setDetailData({ ...detailData, active: true });
                        }}
                    />
                    <Form.Check
                        inline
                        label="Neaktívny"
                        type="radio"
                        id="active-2"
                        checked={!detailData.active}
                        onChange={() => {
                            setDetailData({ ...detailData, active: false });
                        }}
                    />
                </Form.Group>

                {
                    detailData.active && (
                        <>
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Publikované od</Form.Label>
                                <Form.Control size="md" type="date" required="{true}" defaultValue={detailData.publishedFrom?.substring(0, 10)} name="publishedFrom" />
                            </Form.Group>
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Publikované do</Form.Label>
                                <Form.Control size="md" type="date" required="{true}" defaultValue={detailData.publishedTo?.substring(0, 10)} name="publishedTo" />
                            </Form.Group>
                        </>
                    )
                }

                <div className="mt-3 mb-2">
                    <button type="submit" className="btn btn-success">Uložiť</button>
                    <button className="btn btn-light ms-2" onClick={() => closeDetail()}>Zrušiť</button>
                    {
                        detailData.id !== "CREATE" && (
                            <button className="btn btn-danger ms-2" onClick={(e) => deleteBannerModal(e)}>Vymazať</button>
                        )
                    }
                </div>
            </Form>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}