import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalContent from "./ModalContent";
//import heic2any from "heic2any";

export default function Crop({ onImageChange }) {
    let [imageRef, setImageRef] = useState();
    let [src, setSrc] = useState();
    let [hideCrop, setHideCrop] = useState(true);
    let [fileName, setFileName] = useState();
    let [crop, setCrop] = useState({
        unit: "%",
        x: 0,
        y: 0,
        width: 100,
        height: 100,
    });
    let [aspect, setAspect] = useState(3 / 4)
    let [modalConfig, setModalConfig] = useState({ show: false });

    const onClickToggleAspect = e => {
        if (aspect === (4 / 3)) {
            setCrop({ ...crop, height: 100, unit: "%" });
            setAspect(3 / 4);
        }
        else {
            setCrop({ ...crop, width: 100, unit: "%" });
            setAspect(4 / 3);
        }
    }

    const onClickRemoveImage = e => {
        onImageChange({ image: null, name: "", crop: null });
        setSrc(null);
        setHideCrop(true);
        setImageRef(null);
    }

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setSrc(reader.result),
                setFileName(e.target.files[0].name),
            );

            if (e.target.files[0].type.includes('image')) {
                reader.readAsDataURL(e.target.files[0]);
            } else {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nepodporovaný formát !</div>,
                    footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                });
            }

            //heic2any({
            //    blob: e.target.files[0],
            //    toType: "image/jpg",
            //})
            //    .then(function (resultBlob) {
            //        reader.readAsDataURL(resultBlob);
            //    })
            //    .catch(function () {
            //        reader.readAsDataURL(e.target.files[0]);
            //    });
        }
    };

    // If you setCrop the crop in here you should return false.
    const onImageLoaded = image => {
        setImageRef(image);
        if (image.width > image.height) {
            setAspect(4 / 3);
            setTimeout(() => {
                setCrop({ ...crop, width: image.width });
                setHideCrop(false);
            }, 600);
        }
        else {
            setAspect(3 / 4);
            setTimeout(() => {
                setCrop({ ...crop, height: image.height, width: image.height * aspect });
                setHideCrop(false);
            }, 600);
        }

        onImageChange({ image: image, name: fileName, crop: { ...crop, aspect: aspect } });
    };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = (crop, percentCrop) => {
        if (crop.unit === 'px') {
            if (crop.width < 20 || crop.height < 20) {
                crop.unit = '%'
                crop.width = 100;
            }
        }
        setCrop(crop);
    };

    const makeClientCrop = (crop) => {
        if (imageRef && crop.width && crop.height) {
            onImageChange({ image: imageRef, name: fileName, crop: { ...crop, aspect: aspect } });
        }
    }

    return (
        <>
            {src ? (
                <>
                    <div className="text-center w-100">
                        <ReactCrop
                            className={`selectedImage ${hideCrop ? "hideCrop" : ""}`}
                            src={src}
                            crop={{ ...crop, aspect: aspect }}
                            ruleOfThirds
                            onImageLoaded={onImageLoaded}
                            onComplete={onCropComplete}
                            onChange={onCropChange}
                        />
                    </div>
                    <div className="text-center">
                        <span onClick={onClickToggleAspect} role="button" >
                            <span style={{ width: '105px', fontSize: '13px', userSelect: 'none' }} className="d-inline-block">
                                {crop.aspect === (4 / 3) ? 'Otočiť na výšku ?' : 'Otočiť na šírku ?'}
                            </span>
                            <FontAwesomeIcon className={`opacityHover me-2 fs-5 col-1 ${(crop.aspect === (4 / 3)) ? "rotate0deg" : "rotate90deg"}`} icon="fa-solid fa-grip" />
                        </span>
                        <FontAwesomeIcon className="opacityHover mx-2 fs-5 col-1" onClick={onClickRemoveImage} icon="fa-solid fa-trash" />
                    </div>
                </>
            ) : (
                <input type="file" className="notSelectedImage w-100" accept="image/*" onChange={onSelectFile} style={{ color: 'trnasparent !important' }} />
            )}

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}


export function getCroppiedImage(imageData, onCroppiedCallback, setModalConfigCallback) {
    let image = imageData.image;
    let crop = imageData.crop;
    let canvas = document.createElement("canvas");
    let scaleX = image.naturalWidth / image.width;
    let scaleY = image.naturalHeight / image.height;

    let reducedScale;

    if (crop.aspect === (4 / 3)) {
        if (scaleX > 7) {
            let scaleDivider = scaleX / 7;

            reducedScale = scaleX / scaleDivider;
        } else {
            reducedScale = scaleX;
        }
        reducedScale = reducedScale * (image.width / crop.width);
    } else {
        if (scaleY > 7) {
            let scaleDivider = scaleY / 7;

            reducedScale = scaleY / scaleDivider;
        } else {
            reducedScale = scaleY;
        }
        reducedScale = reducedScale * (image.height / crop.height);
    }

    canvas.width = crop.width * reducedScale;
    canvas.height = crop.height * reducedScale;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * reducedScale,
        crop.height * reducedScale
    );

    new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                setModalConfigCallback({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nepodporovaný formát fotodokumentácie !</div>,
                    footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfigCallback({ show: false })}>Rozumiem</button>
                });
                return;
            }

            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
                onCroppiedCallback(reader.result);
            };
        }, "image/jpeg");
    })
}