import React, { useEffect, useState } from "react";
import { Table, Tab, Tabs } from 'react-bootstrap';
import { Store, Pagination, Loading, ConeImagesGallery, ConeImageDetail } from "../../../../common";

import { format } from "date-fns";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { url } from "../../../../config";
const axios = require("axios").default;

export default function ConeEventsModal() {
    const [data, setData] = useState([]);
    const [detailDataId, setDetailDataId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showGallery, setShowGallery] = useState(false);

    useEffect(() => {
        loadData(0);
    }, [])

    const loadData = (page) => {
        let groupId = Store.getObject("selectedGroup").id;
        let baseUrl = `/private/rest/kuzel/newest${groupId ? `/by-group/${groupId}` : ''}`

        axios.get(url + baseUrl + "?page=" + page + "&size=10")
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(err => { });
    }

    return (
        <>
            <Tabs
                onSelect={(e) => (e === 'gallery') ? setShowGallery(true) : ''}
                defaultActiveKey="list"
                id="uncontrolled-tab-example"
                className="mb-2 mb-lg-3"
                justify
            >
                <Tab eventKey="list" title={
                    <>
                        <FontAwesomeIcon icon="fa-solid fa-list-ul" className="me-2" />
                        Zoznam
                    </>
                }>
                    <Table>
                        <thead>
                            <tr className="align-middle">
                                <th className="col-1"></th>
                                <th className="col-5">Kužeľníci</th>
                                <th className="col-4">Lokalizácia</th>
                                <th className="col-4">Dátum</th>
                                <th className="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr className="text-center">
                                        <td colSpan="100%" className="border-0">
                                            <Loading marginTop='170px'></Loading>
                                        </td>
                                    </tr>
                                ) : (
                                    data.data.map((coneEvent, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1 + data.page * 10}.</td>
                                                <td>{coneEvent.kuzelniks.map(e => e.nickname ?? e.name).join(", ")}</td>
                                                <td>{coneEvent.city + ", " + coneEvent.country.toUpperCase()}</td>
                                                <td>{format(new Date(coneEvent.created), 'dd.MM.yyyy')}</td>
                                                <td>
                                                    <FontAwesomeIcon onClick={() => setDetailDataId(coneEvent.id)} size="lg" className="me-1" icon="fa-regular fa-image" />
                                                    {
                                                        detailDataId === coneEvent.id && (
                                                            <ConeImageDetail detailData={coneEvent} onHideModal={() => setDetailDataId(null)} ></ConeImageDetail>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-center">
                        <Pagination page={data.page} totalPages={data.totalPage} onPageChange={page => loadData(page)} />
                    </div>
                </Tab>
                <Tab eventKey="gallery" title={
                    <>
                        <FontAwesomeIcon icon="fa-regular fa-images" className="me-2" />
                        Galéria
                    </>
                }>
                    {
                        showGallery && (
                            <ConeImagesGallery></ConeImagesGallery>
                        )
                    }
                </Tab>
            </Tabs>
        </>
    );
}