import React, { useState } from "react";
import { Form, Modal, ListGroup } from 'react-bootstrap';
import { ModalContent, Loading, Crop, getCroppiedImage, GlobalFunctions } from "../../../../common";

import { url } from "../../../../config";

const axios = require("axios").default;

export default function StoriesAdd({ onLoadData, onHideStoriesAdd }) {
    let [loading, setLoading] = useState(false);

    let [imageData, setImageData] = useState({ image: null, fileName: '', crop: null });
    let [description, setDescription] = useState('');

    let [modalConfig, setModalConfig] = useState({ show: false });

    const onImageCroppiedHandler = (result) => {
        let data = {
            description: description,
            imageFile: result,
            imageName: imageData.fileName,
            orientation: (imageData.crop.aspect === (4 / 3)) ? "LANDSCAPE" : "PORTRAIT",
        };

        let message = '';

        axios
            .post(url + `/private/rest/story`, data)
            .then((res) => {
                onLoadData();

                message = 'Dobrodružstvo úspešne pridané !';
            })
            .catch((err) => {
                message = 'Nepodarilo sa pridať dobrodružstvo !';
            })
            .then(() => {
                onHideStoriesAdd();

                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{message}</div>,
                    footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                });
            })
    }

    const saveDataModal = () => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš uverejniť toto dobrodružstvo ?</b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => saveData()}>Uverejniť</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const saveData = () => {
        setModalConfig({ show: false });
        if (imageData.image) {
            setLoading(true);
            getCroppiedImage(imageData, onImageCroppiedHandler, setModalConfig);
        } else {
            setModalConfig({
                show: true,
                headerContent: null,
                bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nevybral si žiadny obrázok !</div>,
                footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
            });
        }
    }

    return (
        <>
            <Modal
                show={true}
                size='md'
                fullscreen="sm-down"
                scrollable="false"
                onHide={() => { onHideStoriesAdd() }}
                centered>

                {loading && (
                    <Loading loadingType="fullscreenCone" marginTop="160px" marginBottom="0" text="Ukladám"></Loading>
                )}

                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100 color-kuzel h5">
                        Pridanie dobrodružstva
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <Form.Group className="mb-1">
                            <Crop onImageChange={(imageData) => setImageData({
                                image: imageData.image,
                                fileName: imageData.name,
                                crop: imageData.crop
                            })}></Crop>
                        </Form.Group>
                        <Form.Group className="mb-2">
                            <Form.Label>Popis</Form.Label>
                            {/*<Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)}/>*/}
                            <Form.Control value={description} onChange={(e) => setDescription(e.target.value)} />
                        </Form.Group>
                        <ListGroup className="mt-3">
                            <ListGroup.Item className="text-center">
                                <small>
                                    <i>Dobrodružstvá slúžia na zdieľanie nášho každodenného spolunažívania s kužeľmi</i>
                                </small>
                            </ListGroup.Item>
                            <ListGroup.Item className="text-center">
                                <small>
                                    <i>Pri ich vytváraní nie je potrebné dodržiavať pravidlá kužeľovej charty</i>
                                </small>
                            </ListGroup.Item>
                            <ListGroup.Item className="text-center">
                                <small>
                                    <i>Dobrodružstvo bude dostupné po dobu 72 hodín</i>
                                </small>
                            </ListGroup.Item>
                            <ListGroup.Item className="text-center">
                                <small>
                                    <i>Nie je viazané na komunitu, ale je dostupné pre všetkých kužeľníkov</i>
                                </small>
                            </ListGroup.Item>
                        </ListGroup>
                    </>
                </Modal.Body>
                <Modal.Footer className="py-1">
                    <button className="btn-kuzel w-100 mx-0" onClick={() => saveDataModal()}>Uverejniť</button>
                </Modal.Footer>
            </Modal>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })}></ModalContent>
        </>
    );
}