import React, { useEffect, useState } from "react";
import { ModalContent } from "../../../../common";
import StoriesDetail from "./StoriesDetail";
import StoriesAdd from "./StoriesAdd";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from "react-infinite-scroll-component";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function Stories({ isPopupOrMenuOpened }) {
    let [stories, setStories] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });
    let [loading, setLoading] = useState(true);

    let [showStoriesAdd, setShowStoriesAdd] = useState(false);

    let [storiesDetailIndex, setStoriesDetailIndex] = useState(null);

    let [calledAt, setCalledAt] = useState(0);

    let [modalConfig, setModalConfig] = useState({ show: false });

    useEffect(() => {
        loadData(0);
    }, [])

    const loadData = (page, reload = false) => {
        let baseUrl = `/private/rest/story/newest${calledAt > 0 && !reload ? '/' + calledAt : ''}`;
        axios.get(url + baseUrl + "?page=" + page + "&size=15")
            .then(res => {
                setCalledAt(res.data.calledAt);

                if (reload) {
                    setStories(res.data);
                }
                else {
                    setStories({ ...res.data, data: [...stories.data, ...res.data.data] });
                }

                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const onSeenStoryChangeHandler = (entry) => {
        let storiesData = stories.data;

        for (let story of storiesData) {
            if (story.id == entry.id) {
                story.seen = true;
            }
        }
    }

    return (
        <>
            {
                !loading && (
                    <div id={"storyScrollableContainer"} className={'storiesContainer pe-1 ' + (isPopupOrMenuOpened ? 'd-none' : '')}>
                        <div onClick={() => setShowStoriesAdd(true)}
                            className="story addStory opacityHover fs-5 d-flex align-items-center justify-content-center">
                            <FontAwesomeIcon icon="fa-solid fa-plus" />
                        </div>

                        <div className="stories">
                            <InfiniteScroll
                                dataLength={stories.data.length} //This is important field to render the next data
                                next={() => loadData(stories.page + 1)}
                                hasMore={stories.page < stories.totalPage - 1}
                                scrollableTarget="storyScrollableContainer"
                            >
                                {
                                    stories.data?.map((story, index) => {
                                        return (
                                            <div onClick={() => setStoriesDetailIndex(index + 1)}
                                                className={'story opacityHover my-2 ' + (story.seen ? 'viewed' : '')}
                                                key={index}>
                                                <img src={url + story.photo + "?width=80"} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                )
            }

            {
                storiesDetailIndex && (
                    <StoriesDetail index={storiesDetailIndex} onHideStoriesDetail={() => setStoriesDetailIndex(null)} onSeenStoryChange={(entry) => onSeenStoryChangeHandler(entry)}></StoriesDetail>
                )
            }

            {
                showStoriesAdd && (
                    <StoriesAdd onLoadData={() => loadData(0, true)} onHideStoriesAdd={() => setShowStoriesAdd(false)} ></StoriesAdd>
                )
            }

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })}></ModalContent>
        </>
    );
}