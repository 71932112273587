import React, { useState } from "react";
import ModalContent from "../utils/ModalContent";
import Loading from '../utils/Loading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Geolocation() {
    let [modalConfig, setModalConfig] = useState({ show: false });
    let [loading, setLoading] = useState(false);

    let options = {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 0,
    }

    const geolocate = () => {
        setModalConfig({ show: false });
        setLoading(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(geolocateSuccess, geolocateFail, options)
        } else {
            geolocateFail();
        }
    }

    const geolocateSuccess = (position) => {
        setLoading(false);

        let location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
        }
        window.dispatchEvent(new CustomEvent("map-set-view", { 'detail': location }));
    }

    const geolocateFail = () => {
        setLoading(false);

        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Nepodarilo sa získať polohu !</div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => geolocate()}>Skúsiť znova</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }
    return (
        <>
            <FontAwesomeIcon
                onClick={() => geolocate()}
                icon="fa-solid fa-location-crosshairs"
                className="geolocationControl opacityHover"
                style={{
                    position: 'absolute',
                    zIndex: '1000',
                    top: '225px',
                    left: '10px',
                    width: '18px',
                    height: '18px',
                    backgroundColor: '#fff',
                    border: '2px solid rgba(0,0,0,0.28)',
                    borderRadius: '4px',
                    padding: '6px',
                    color: 'dimgray',
                }}
            />

            <ModalContent modalConfig={modalConfig} ></ModalContent>

            {loading && (
                <Loading text="Získavam polohu" marginTop="160px" loadingType="fullscreenCone"></Loading>
            )}
        </>
    );
}