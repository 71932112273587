import React, { useEffect, useState } from "react";
import { Spinner } from 'react-bootstrap';

import { Link, useSearchParams } from "react-router-dom";

import { url } from "../../../config";
const axios = require("axios").default;

export default function RegistrationFinish() {
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();

    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [showRepeatVerificationEmailButton, setShowRepeatVerificationEmailButton] = useState(false);
    const [showRepeatVerificationEmailSuccess, setShowRepeatVerificationEmailSuccess] = useState(false);

    const [publicId, setPublicId] = useState("");

    useEffect(() => {
        axios
            .put(url + `/public/rest/registration/verify/${searchParams.get("h")}`)
            .then((res) => {
                if (res.data.success) {
                    setVerificationSuccess(true);
                    setLoading(false);
                }
            })
            .catch((err) => {
                //ked nemaš aktívny učet (po expiracii) tak sprav toto:
                if (err.response.data?.body?.repeatVerification) {
                    setShowRepeatVerificationEmailButton(true);
                    setVerificationSuccess(false);
                    setPublicId(err.response.data.body.publicId);
                }

                setLoading(false);
            });
    }, [])

    const requestNewVerificationEmail = () => {
        axios
           .put(url + "/public/rest/registration/repeat-verification", {id: publicId})
           .then((res) => {
               setShowRepeatVerificationEmailButton(false);
               setShowRepeatVerificationEmailSuccess(true);
           })
           .catch(() => {

           });
    };


    return (
        <>
            {loading ?
                <div className="text-center my-5">
                    <Spinner animation="border" />
                </div>
                :
                <>
                    {verificationSuccess ?
                        <span className="mt-2 fs-6">Váš účet bol aktivovaný, teraz sa môžete <Link to='/'>prihlásiť</Link></span>
                        :
                        <>
                            {
                                showRepeatVerificationEmailButton && (
                                    <div className="mb-2" onClick={() => requestNewVerificationEmail()}>
                                        <span>Platnosť verifikačného odkazu vypršala. </span><br/>
                                        <a onClick={requestNewVerificationEmail}><u>Požiadať o nový môžete tu.</u></a>
                                    </div>
                                )
                            }
                            {
                                showRepeatVerificationEmailSuccess && (
                                    <div className="mb-2">Nová žiadosť o verifikáciu úspešne odoslaná na Váš email</div>
                                )
                            }
                        </>
                    }
                </>
            }
        </>
    );
}