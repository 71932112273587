export default function AboutUsModal() {
    return (
        <>
            <p>Vo svete plnom dobrodružstva a objavovania existovala skupina vášnivých jednotlivcov známych ako „Kužeľníci“. Títo neohrození cestovatelia boli hlboko fascinovaní dopravnými kužeľmi a vydali sa na misiu zachytiť jedinú selfie s dopravným kužeľom v každom meste alebo dedine, ktorú navštívili.</p>

            <p>Kužeľníci pochádzali zo všetkých oblastí života, spájala ich spoločná láska k cestovaniu a ich jedinečné hľadanie. Verili, že dopravné kužele predstavujú viac než len značky na ceste – symbolizujú pulzujúcu energiu a ducha každého miesta, s ktorým sa stretli.</p>

            <p>Vyzbrojení svojimi kamerami a mapou naplnenou potenciálnymi destináciami sa Kužeľníci vydali na svoju cestu. Odvážili sa do rušných miest, malebných dedín a odľahlých miest, z ktorých každé malo svoj osobitý charakter a šarm.</p>

            <p>Po príchode do nového mesta alebo dediny sa Kužeľníci ponorili do miestnej kultúry, užívali si pamiatky, zvuky a chute, ktoré toto miesto definovali. Potulovali sa po uliciach a hľadali dokonalý dopravný kužeľ, ktorý by zachytili na selfie.</p>

            <p>V srdci rušných metropol našli medzi vysokými mrakodrapmi dopravné kužele, ktoré zachytávajú podstatu mestského života. Každou snímkou sa snažili zapuzdrovať energiu a dynamiku mesta – pulzujúcu zrážku kultúr, symfóniu trúbiacich klaksónov a štebotajúcich hlasov.</p>

            <p>V pokojných dedinách narazili na dopravné kužele zdobiace úzke vidiecke uličky a malebné námestia. Kužeľníci si užívali kúzlo a pokoj týchto miest s cieľom zachovať pokoj a jednoduchosť dedinského života vo svojich kužeľových selfie.</p>

            <p>Počas svojich ciest sa Kužeľníci stretli s nespočetnými priateľskými miestnymi obyvateľmi, ktorých ich misia zaujala. Dedinčania a obyvatelia miest ich dychtivo poukazovali na tie najunikátnejšie a najkultovejšie kužele a zdieľali príbehy a legendy spojené s týmito značkami ciest.</p>

            <p>Niekedy Kužeľníci narazili na dopravné kužele, ktoré niesli známky opotrebovania, dôkazy o plynutí času a príbehy, ktoré mohli rozprávať. Tieto kužele, zvetrané a odolné, sa stali symbolmi komunít, ktoré sa na ne spoliehali pri riadení toku dopravy a udržiavaní bezpečnosti svojich obyvateľov.</p>

            <p>Keď Kužeľníci zbierali svoje selfie z rôznych miest a dedín, vytvorili album, ktorý ukázal neuveriteľnú rozmanitosť miest, ktoré navštívili. Zdieľali svoje fotografie so spolucestujúcimi, podnietili zvedavosť a inšpirovali ostatných, aby sa pustili do vlastných dobrodružstiev pri zachytávaní kužeľov.</p>

            <p>Kužeľníci nielen dokumentovali svoje cesty týmito selfie; oslavovali jedinečného ducha každého miesta. Ich misia presahovala honbu za dokonalou fotografiou – išlo o vytváranie spojení, oceňovanie rozmanitých svetových tapisérií a prijímanie radosti z objavovania.</p>

            <p>V priebehu času sa komunita kúželníkov rozrástla a k ich pátraniu sa pripojili cestujúci z celého sveta. Vytvorili putá a vymieňali si príbehy, čím vytvorili globálnu sieť nadšencov kužeľov, ktorí oslavovali krásu a div sveta, jednu selfie kužeľa za druhou.</p>

            <p>A tak Kužeľníci pokračovali vo svojich dobrodružstvách a zanechali svoje stopy v každom meste a dedine, s ktorou sa stretli. Ich kužeľové selfie sa stali dôkazom ich lásky k cestovaniu, uznania rôznych kultúr a ich neochvejného ducha zvedavosti.</p>

            <p>Ich dedičstvo žilo ďalej, keď sa cestovatelia zo všetkých kútov sveta vydávali na svoje vlastné cesty zbieraním kužeľov, spoznávajúc kúzlo, ktoré možno nájsť na tých najneočakávanejších miestach. Každá selfie sa stala cenným mementom, pripomínajúcim ľudí, ktorých stretli, príbehy, ktoré zdieľali, a neuveriteľnú rozmanitosť nášho sveta.</p>

            <div className="d-flex justify-content-center">
                <iframe className="video col-12 col-md-9 mb-3" src="https://www.youtube.com/embed/kdN9gZUenpU" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style={{ aspectRatio: 16/9 }} allowFullScreen></iframe>
            </div>
        </>
    );
}