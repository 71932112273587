import {url} from "../../config";
const axios = require("axios").default;

export default class ConeComponentsService {
    static loadComments(kuzelId, callback) {
        axios.get(url + `/private/rest/kuzel/components/comment/all/${kuzelId}`)
            .then(res => callback(res.data))
            .catch((err) => {console.error(err) });
    }

    static addComment(kuzelId, comment, callback) {
        axios.post(url + `/private/rest/kuzel/components/comment`, {kuzelId, comment})
            .then(res => callback(res.data.body))
            .catch((err) => {console.error(err) });
    }

    static updateComment(commentId, kuzelId, comment, callback) {
        axios.put(url + `/private/rest/kuzel/components/comment/${kuzelId}/${commentId}`, {value: comment})
            .then(res => callback(res.data.body))
            .catch((err) => {console.error(err) });
    }

    static deleteComment(commentId, kuzelId, callback) {
        axios.delete(url + `/private/rest/kuzel/components/comment/${kuzelId}/${commentId}`)
            .then(res => callback(res.data.body))
            .catch((err) => {console.error(err) });
    }

    static loadLikes(kuzelId, callback) {
        axios.get(url + `/private/rest/kuzel/components/like/summary/${kuzelId}`)
            .then(res => callback(res.data))
            .catch((err) => {console.error(err) });
    }

    static addUpdateOrRemoveLike(kuzelId, likeType, callback) {
        axios.post(url + `/private/rest/kuzel/components/like`, {kuzelId, type: likeType})
            .then(res => callback(res.data.body))
            .catch((err) => {console.error(err) });
    }
}