export default class GlobalFunctions {
    static getDateText(date) {
        let date1 = new Date();
        let date2 = new Date(date);

        const diffTime = Math.abs(date1 - date2);
        const diffMinutes = Math.ceil(diffTime / (1000 * 60)) - 1;
        const diffHours = Math.ceil(diffTime / (1000 * 60 * 60)) - 1;

        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        const diffTimeWithoutTime = Math.abs(date1 - date2);
        const diffDays = Math.ceil(diffTimeWithoutTime / (1000 * 60 * 60 * 24));
        const diffWeeks = Math.ceil(diffDays / 7);
        const diffMonths = Math.ceil(diffDays / 30.5);
        const diffYears = Math.ceil(diffDays / 365.25);

        if (diffMinutes < 2) {
            return "Teraz";
        }
        else if (diffHours < 1) {
            return "Pred " + diffMinutes + " minútami";
        }
        else if (diffHours === 1) {
            return "Pred 1 hodinou";
        }
        else if (diffDays < 1) {
            return "Pred " + diffHours + " hodinami";
        }
        else if (diffDays === 1) {
            return "Včera";
        }
        else if (diffWeeks < 2) {
            return "Pred " + diffDays + " dňami";
        }
        else if (diffMonths < 3) {
            return "Pred " + diffWeeks + " týždňami";
        }
        else if (diffMonths < 12) {
            return "Pred " + diffMonths + " mesiacmi";
        }
        else if (diffYears === 1) {
            return "Pred rokom";
        }
        else {
            return "Pred " + diffYears + " rokmi";
        }
    }

    static getDiffHours(date) {
        let date1 = new Date();
        let date2 = new Date(date);

        const diffTime = Math.abs(date1 - date2);
        return Math.ceil(diffTime / (1000 * 60 * 60)) - 1;
    }

    static getTime(dateString) {
        let date = new Date(dateString);

        let hours = date.getHours();
        let minutes = date.getMinutes();

        hours = (hours.toString().length === 2) ? hours : '0' + hours;
        minutes = (minutes.toString().length === 2) ? minutes : '0' + minutes;

        return hours + ":" + minutes;
    }
}

