import React, { useEffect, useState } from "react";
import { Form, Table, Collapse } from 'react-bootstrap';
import { Loading, ModalContent, Pagination } from "../../../../common";

import UsersDetail from './UsersDetail';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../config";
const axios = require("axios").default;

export default function Users() {
    const [users, setUsers] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    const [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);

    const [detailData, setDetailData] = useState({});

    const [showFilter, setShowFilter] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        loadUsers(0);
    }, [])

    const loadUsers = (page, search = "") => {
        axios.get(url + `/private/rest/admin/kuzelnik/all?page=${page}&size=10&search=${search}&sort=id,asc`)
            .then(res => {
                setUsers(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const openDetail = (data) => {
        if (data.id === detailData.id) {
            setDetailData({});
        }
        else {
            setDetailData(data);
        }
    }

    return (
        <>
            <button className="btn-kuzel secondary w-100 mb-3" onClick={() => setShowFilter(!showFilter)}>Filter</button>
            <div>
                <Collapse in={showFilter} className="w-100">
                    <div>
                        <div className="w-100 p-2 pt-0 mb-2">
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Meno (dajme sem prezývku)</Form.Label>
                                <Form.Control size="md" type="text" required="{true}" name="name"
                                    onChange={e => {
                                        loadUsers(0, e.currentTarget.value);
                                        setFilterValue(e.currentTarget.value);
                                    }} />
                            </Form.Group>
                        </div>
                    </div>
                </Collapse>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th className="col"></th>
                        <th className="col-6">Prezývka</th>
                        <th className="col-6">Meno</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='170px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            users.data.map((user, index) => {
                                return (
                                    <>
                                        <tr key={user.id} onClick={() => openDetail(user)} role="button">
                                            <td>{index + 1 + users.page * 10}.</td>
                                            <td>
                                                {user.nickname}
                                                <FontAwesomeIcon icon="fa-solid fa-xmark" className={"text-danger ms-2  " + (user.enabled ? 'd-none' : '')} />
                                            </td>
                                            <td>{user.name}</td>
                                        </tr>
                                        <tr key={user.id + "opc"} className="opacityHoverNone">
                                            <td colSpan="100%" className="border-0 p-0">
                                                <Collapse in={user.id === detailData.id} className="w-100">
                                                    <div>
                                                        {
                                                            user.id === detailData.id && (
                                                                <UsersDetail initDetailData={detailData} loadUsers={() => loadUsers(users.page, filterValue)} closeDetail={() => setDetailData({})}></UsersDetail>
                                                            )
                                                        }
                                                    </div>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>

            <div className="d-flex justify-content-center">
                <Pagination page={users.page} totalPages={users.totalPage} onPageChange={page => loadUsers(page, filterValue)} />
            </div>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}