import React, { useState } from "react";
import { Form, Image, Collapse } from 'react-bootstrap';
import { Loading, ModalContent, ConeComponentsService, Crop, getCroppiedImage, GlobalFunctions } from "../../../../common";

import Select from "react-select";

import CountryCodes from "../../../../resources/enums/countryCodes";
import Rules from "../../../../resources/enums/rules";

import { format } from "date-fns";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../config";
import {defaultWidth} from "../../../../common/utils/ImageConstants";
const axios = require("axios").default;

export default function ConeEventsDetail({ initDetailData, loadConeEvents, closeDetail }) {
    let [modalConfig, setModalConfig] = useState({ show: false });

    let [loading, setLoading] = useState(true);

    let [showPhoto, setShowPhoto] = useState(false);
    let [changePhoto, setChangePhoto] = useState(false);
    let [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
    let [showDeleteCircumstances, setShowDeleteCircumstances] = useState(false);
    let [showComments, setShowComments] = useState(false);

    let [detailData, setDetailData] = useState(initDetailData);
    let [imageData, setImageData] = useState({ image: null, fileName: '', crop: null });

    let [image, setImage] = useState(null);

    let [kuzelniks, setKuzelniks] = useState([]);
    let [actualValue, setActualValue] = useState('');
    let [blockageReason, setBlockageReason] = useState();
    let [comments, setComments] = useState([]);

    const deleteConeEvent = () => {
        axios.put(url + "/private/rest/admin/kuzel/" + detailData.id, { value: blockageReason.text })
            .then(res => {
                loadConeEvents();
            })
            .catch(err => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Vymazanie sa voláko nepodarilo</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            })
    }

    const deleteConeEventModal = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (blockageReason) {
            if (blockageReason.index === "no-rule") {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vymazať kužeľovú udalosť bez udania dôvodu a odoslania notifikácie ?</b></div>,
                    footerContent: <>
                        <button className="btn-kuzel" onClick={() => deleteConeEvent()}>Vymazať</button>
                        <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                    </>
                });
            } else {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <>
                        <div className="text-center" style={{ fontSize: '15px' }}>
                            <b>Naozaj chceš vymazať kužeľovú udalosť z dôvodu porušenia pravida č. {blockageReason.index + 1} ?</b>
                            <div dangerouslySetInnerHTML={{ __html: blockageReason.text }} className="mt-2"></div>
                        </div>
                    </>,
                    footerContent: <>
                        <button className="btn-kuzel" onClick={() => deleteConeEvent()}>Vymazať</button>
                        <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                    </>
                });
            }
        } else {
            setModalConfig({
                show: true,
                headerContent: null,
                bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Nevybral si dôvod vymazania !</b></div>,
                footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            });
        }

    }

    const deleteComment = (comment) => {
        //    ConeComponentsService.deleteComment(comment.id, detailData.id, setComments)
        //    setModalConfig({ show: false });
    }

    const deleteCommentModal = (comment) => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vymazať komentár ? </b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => deleteComment(comment)}>Vymazať</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const refreshDetail = (data) => {
        axios
            .get(url + "/private/rest/kuzel/detail/" + data.id)
            .then((res) => {
                setDetailData(res.data);
                //setLoading(false);
            })
            .catch((err) => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Nepodarilo sa načítať korektne</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            });
    }

    const fetchKuzelniks = (text) => {
        if (text.length >= 2) {
            axios
                .get(url + `/private/rest/kuzelnik/find/` + text)
                .then((res) => {
                    setKuzelniks(res.data);
                    setActualValue(text);
                })
                .catch((err) => { });
        }
        else {
            setKuzelniks([]);
            setActualValue(text);
        }
    };

    const toggleAdditionalSettingsHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setShowAdditionalSettings(!showAdditionalSettings);
    }

    const togglePhotoHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!showPhoto) {
            if (!image) {
                axios.get(url + detailData.photo + (defaultWidth > 0 ? `?width=${defaultWidth}` : ""), { responseType: "blob" })
                    .then(function (res) {
                        var reader = new window.FileReader();
                        reader.readAsDataURL(res.data);
                        reader.onload = function () {
                            var imageDataUrl = reader.result;
                            setImage(imageDataUrl);

                            setShowPhoto(true);
                        }
                    })
            } else {
                setShowPhoto(true);
            }
        } else {
            setShowPhoto(false);
        }

        setShowComments(false);
        setShowDeleteCircumstances(false);
    }

    const toggleDeleteCircumstances = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setShowDeleteCircumstances(!showDeleteCircumstances);

        setShowPhoto(false);
        setShowComments(false);
    }

    const toggleCommentsHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!showComments) {
            ConeComponentsService.loadComments(detailData.id, function (e) {
                setComments(e);
                setShowComments(true);
            });
        } else {
            setShowComments(false);
        }

        setShowDeleteCircumstances(false);
        setShowPhoto(false);
    }

    const closeAll = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setShowPhoto(false);
        setShowComments(false);
        setShowDeleteCircumstances(false);
    }

    const savePhotoModal = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš nahradiť fotku ? </b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => getCroppiedImage(imageData, onImageCroppiedHandler, setModalConfig)}>Nahradiť</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const onImageCroppiedHandler = (result) => {
        let data = { image: result, fileName: imageData.name, orientation: (imageData.crop.aspect === (4 / 3)) ? "LANDSCAPE" : "PORTRAIT" };

        let responseMessage = "";

        axios
            .put(url + `/private/rest/admin/kuzel/photo/` + detailData.id, data)
            .then((res) => {
                //loadingDorobim...možno
                responseMessage = "Fotka úspešne nahradená";
                refreshDetail(detailData);
            })
            .catch((err) => {
                responseMessage = "Nepodarilo sa nahradiť fotku";
            })
            .then(res => {
                setShowPhoto(false);
                setChangePhoto(false);
                setImage(null);

                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            });
    };

    const saveDataHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());

        let created = new Date(formDataObj.createdDate)
        let hours = formDataObj.createdTime.substring(0, 2);
        let minutes = formDataObj.createdTime.substring(3, 5);
        created.setHours(hours, minutes, 0);

        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(created - tzoffset)).toISOString().slice(0, -1);

        let finalDataObj = { ...detailData, ...formDataObj, created: localISOTime }

        finalDataObj.kuzelniks = finalDataObj.kuzelniks.map(k => k.id ? k.id : k);

        setLoading(true);

        axios
            .put(url + `/private/rest/admin/kuzel`, finalDataObj)
            .then((res) => {
                //loadingDorobim
                setLoading(false);
                loadConeEvents();
                closeDetail();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Form onSubmit={(val) => saveDataHandler(val)} className="border mb-3 mx-1 row adminDetail">
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Štát</Form.Label>
                    <Form.Control
                        as="select"
                        defaultValue={detailData.country}
                        required="{true}"
                        name="country"
                    >
                        {
                            CountryCodes.map((country) => {
                                return (
                                    <option value={country.code.toLowerCase()}>{country.name}</option>
                                )
                            })
                        }

                    </Form.Control>
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Mesto</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.city} required="{true}" name="city" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Zúčastnení kužeľníci</Form.Label>
                    <Select
                        onChange={(e) => {
                            setDetailData({ ...detailData, kuzelniks: e.map((anga) => anga.id) });
                        }}
                        defaultValue={detailData.kuzelniks}
                        placeholder={'Zadaj meno'}
                        closeMenuOnSelect={false}
                        isMulti
                        getOptionLabel={(option) => ((option.nickname ?? "") + (option.name && option.nickname ? " (" : "") + (option.name ?? "") + (option.name && option.nickname ? ")" : ""))}
                        getOptionValue={(option) => option.id}
                        options={kuzelniks}
                        onInputChange={(val) => fetchKuzelniks(val)}
                        noOptionsMessage={() => (actualValue.length >= 2) ? 'Žiadne výsledky vyhľadávania' : 'Zadaj minimálne 2 znaky pre vyhľadanie kužeľníka !'}
                    />
                </Form.Group>
                <Form.Group className="col-6 col-lg-3">
                    <Form.Label>Dátum vytvorenia</Form.Label>
                    <Form.Control size="md" type="date" defaultValue={detailData.created?.substring(0, 10)} required="{true}" name="createdDate" />
                </Form.Group>
                <Form.Group className="col-6 col-lg-3">
                    <Form.Label>Čas vytvorenia</Form.Label>
                    <Form.Control size="md" type="time" defaultValue={GlobalFunctions.getTime(detailData.created)} required="{true}" name="createdTime" />
                </Form.Group>
                <Form.Group className="col-12">
                    <Form.Label>Popis</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.description} name="description" maxLength="70" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Komunita</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.group !== "" ? detailData.group : 'Veľkomunita'} disabled={true} name="group" />
                </Form.Group>
                <Form.Group className="col-6 col-lg-3">
                    <Form.Label>Počet like-ov</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.likes.summary.LIKE ?? "0"} disabled={true} name="group" />
                </Form.Group>
                <Form.Group className="col-6 col-lg-3">
                    <Form.Label>Počet komentárov</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.commentCount} disabled={true} name="group" />
                </Form.Group>
                <span className="mt-2">
                    <span className="text-white w-auto opacityHover" onClick={(e) => toggleAdditionalSettingsHandler(e)} role="button">
                        <FontAwesomeIcon icon="fa-solid fa-arrow-up" className={`me-2 ${showAdditionalSettings ? "rotate180deg" : "rotate0deg"}`} />
                        Rozšírené nastavenia
                    </span>
                </span>

                <Collapse in={showAdditionalSettings} className="w-100">
                    <div className="row mx-0 px-0">
                        <Form.Group className="col-12 col-lg-6">
                            <Form.Label>Súradnice - dĺžka (lng)</Form.Label>
                            <Form.Control size="md" type="text" defaultValue={detailData.lng} required="{true}" name="lng" />
                        </Form.Group>
                        <Form.Group className="col-12 col-lg-6">
                            <Form.Label>Súradnice - šírka (lat)</Form.Label>
                            <Form.Control size="md" type="text" defaultValue={detailData.lat} required="{true}" name="lat" />
                        </Form.Group>
                        <Form.Group className="col-12 col-lg-6">
                            <Form.Label>Adresa fotky</Form.Label>
                            <Form.Control size="md" type="text" defaultValue={detailData.photo} required="{true}" name="photo" />
                        </Form.Group>
                        <Form.Group className="col-12 col-lg-6">
                            <Form.Label>Orientácia fotky</Form.Label>
                            <Form.Control
                                as="select"
                                defaultValue={detailData.orientation}
                                name="orientation"
                            >
                                <option value="PORTRAIT">Na výšku</option>
                                <option value="LANDSCAPE">Na ležato</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="col-12">
                            <Form.Label>Adresa ID</Form.Label>
                            <Form.Control size="md" type="text" defaultValue={detailData.addressId} disabled={true} name="addressId" />
                        </Form.Group>
                    </div>
                </Collapse>

                <div className="my-3">
                    <button type="submit" className="btn btn-success">Uložiť</button>
                    <button className="btn btn-light ms-2" onClick={() => closeDetail()}>Zrušiť</button>
                </div>

                <div className="mb-3">
                    <button className="btn btn-primary ms-2" onClick={(e) => togglePhotoHandler(e)}>Fotka</button>
                    <button className="btn btn-info ms-2" onClick={(e) => toggleCommentsHandler(e)}>Komentáre</button>
                    <button className="btn btn-secondary ms-2" onClick={(e) => toggleDeleteCircumstances(e)}>Vymazať</button>
                </div>

                <Collapse in={showPhoto} className="w-100">
                    <div className="justify-content-center mt-1 mb-3">
                        {
                            changePhoto ? (
                                <>
                                    <>
                                        <div className="row justify-content-center">
                                            <Form.Group className="my-2 px-0 bg-white rounded-2" style={{ maxWidth: '301px' }}>
                                                <Crop onImageChange={(data) => setImageData(data)}></Crop>
                                            </Form.Group>
                                        </div>
                                        <div className="mt-2">
                                            <button className="btn btn-success me-2" onClick={(e) => savePhotoModal(e)}>Uložiť</button>
                                            <button className="btn btn-secondary" onClick={() => setChangePhoto(false)}>Zrušiť</button>
                                        </div>
                                    </>
                                </>
                            ) : (
                                <>
                                    <Image
                                        src={image}
                                        className="mb-3"
                                        style={{ height: detailData.orientation === 'PORTRAIT' ? '420px' : 'unset', width: detailData.orientation === 'PORTRAIT' ? 'unset' : '100%', aspectRatio: detailData.orientation === 'PORTRAIT' ? 3 / 4 : 4 / 3 }}
                                        alt={detailData.city + " kuzel"}
                                        thumbnail
                                    />
                                    <div>
                                        <button className="btn btn-secondary me-2" onClick={(e) => setChangePhoto(true)}>Nahradiť fotku</button>
                                        <button className="btn btn-light" onClick={(e) => closeAll(e)}>Zrušiť</button>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </Collapse>

                <Collapse in={showComments} className="w-100">
                    <div>
                        {
                            comments.length ? (
                                <>
                                    <div className="szh-menu__divider my-2 bg-kuzel"></div>
                                    {
                                        comments.map((comment, index) => {
                                            return (
                                                <>
                                                    <div key={index} className="text-white">
                                                        <span>{comment.comment}</span>
                                                        <div>
                                                            <small className="text-secondary">
                                                                <span> {comment.author.nickname + (comment.author.name ? (' (' + comment.author.name + ')') : '')} </span>
                                                                <span>{" - " + format(new Date(comment.created), 'dd.MM.yyyy')}</span>
                                                            </small>
                                                            <FontAwesomeIcon className="opacityHover ms-2 text-danger" onClick={() => deleteCommentModal(comment)} icon="fa-solid fa-trash" />
                                                        </div>
                                                    </div>
                                                    <div className="szh-menu__divider my-2 bg-kuzel"></div>
                                                </>
                                            )
                                        })
                                    }
                                </>
                            ) : (
                                <span className="text-white">Žiadne komentáre...</span>
                            )
                        }

                        <div className="my-3">
                            <button className="btn btn-light" onClick={(e) => closeAll(e)}>Zrušiť</button>
                        </div>
                    </div>
                </Collapse>

                <Collapse in={showDeleteCircumstances} className="w-100">
                    <div className="px-3 text-white">
                        <div className="text-start mb-3">
                            <div className="my-2">
                                <input type="radio" id="no-rule" name="rules" onChange={(e) => setBlockageReason({ index: "no-rule" })}></input>
                                <label role="button" htmlFor="no-rule" className="ms-2 mb-2 d-inline">bez udania dôvodu a odoslania notifikácie</label>
                            </div>
                            <u>Porušenie pravidiel:</u>
                            {
                                Rules.map((rule, index) => {
                                    return (
                                        <div key={rule.id} className="mt-1 ">
                                            <input type="radio" id={'rule-' + rule.code} name="rules" onChange={(e) => setBlockageReason({ ...rule, index: index })}></input>
                                            <label role="button" htmlFor={'rule-' + rule.code} className="ms-2 mb-2 d-inline" dangerouslySetInnerHTML={{ __html: rule.text }}></label>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="my-3">
                            <button className="btn btn-danger me-2" onClick={(e) => deleteConeEventModal(e)}>Vymazať</button>
                            <button className="btn btn-light" onClick={(e) => closeAll(e)}>Zrušiť</button>
                        </div>
                    </div>
                </Collapse>

            </Form>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}