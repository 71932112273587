import React, { useEffect, useState } from "react";
import { Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { url } from "../../../config";
const axios = require("axios").default;

export default function Registration() {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();

    const [form, setForm] = useState({
        name: '',
        email: '',
        nickname: '',
        password: '',
        passwordRepeat: '',
        fromEmail: ''
    })

    const [errors, setErrors] = useState({});

    const [message, setMessage] = useState('')

    useEffect(() => {
        let email = searchParams.get("email");
        if (email && email !== '') setForm({ ...form, email: email, fromEmail: email })
    }, [])

    const validateField = (field, value) => {
        if (field === 'name') {
            setErrors({
                ...errors,
                name: validateName({ [field]: value })
            })
        }
        else if (field === 'email') {
            setErrors({
                ...errors,
                email: validateEmail({ [field]: value })
            })
        }
        else if (field === 'nickname') {
            setErrors({
                ...errors,
                nickname: validateNickname({ [field]: value })
            })
        }
        else if (field === 'password') {
            let newErrors = {
                ...errors,
                password: validatePassword({ [field]: value }),
            }

            if (form.passwordRepeat && form.passwordRepeat.length > 0) {
                newErrors.passwordRepeat = validatePasswordRepeat({ password: value, passwordRepeat: form['passwordRepeat'] })
            }
            setErrors(newErrors)
        }
        else if (field === 'passwordRepeat') {
            setErrors({
                ...errors,
                passwordRepeat: validatePasswordRepeat({ [field]: value, password: form['password'] })
            })
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if (field === 'name') {
            validateName({ [field]: value })
        }
        else if (field === 'email') {
            validateEmail({ [field]: value })
        }
        else if (field === 'nickname') {
            validateNickname({ [field]: value })
        }
        else if (field === 'password') {
            validatePassword({ [field]: value })
        }
        else if (field === 'passwordRepeat') {
            validatePasswordRepeat({ [field]: value, password: form['password'] })
        }

    }

    let validateName = (form) => {
        let errorMessage = '';
        if (!form.name) errorMessage = 'Zadaj meno !'
        return errorMessage;
    }

    let validateEmail = (form) => {
        let errorMessage = '';
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (!form.email) errorMessage = 'Zadaj email !'
        else if (!form.email.match(validRegex)) errorMessage = 'Nesprávny formát emailovej adresy'
        return errorMessage;
    }

    let validateNickname = (form) => {
        let errorMessage = '';
        if (!form.nickname) errorMessage = 'Zadaj prezývku !'
        else if (form?.nickname?.length < 5) errorMessage = 'Minimálna dĺžka prazývky je 5 znakov !'
        return errorMessage;
    }

    let validatePassword = (form) => {
        let errorMessage = '';
        if (!form.password) errorMessage = 'Zadaj heslo !'
        else if (form?.password?.length < 8) errorMessage = 'Minimálna dĺžka hesla je 8 znakov !'
        else if (!/[A-Z]/.test(form.password)) errorMessage = 'Heslo musí obsahovať minimálne jedno veľké písmeno !'
        else if (!/[0-9]/.test(form.password)) errorMessage = 'Heslo musí obsahovať minimálne jedno číslo !'
        return errorMessage;
    }

    let validatePasswordRepeat = (form) => {
        let errorMessage = '';
        if (!form.passwordRepeat) errorMessage = 'Zadaj nové heslo !'
        else if (form.password !== form.passwordRepeat && form.password.length) errorMessage = 'Heslá sa nezhodujú !'
        return errorMessage;
    }

    const handleSubmitRegistration = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let newErrors = {
            name: validateName(form),
            email: validateEmail(form),
            nickname: validateNickname(form),
            password: validatePassword(form),
            passwordRepeat: validatePasswordRepeat(form)
        };
        setErrors(newErrors);

        if (newErrors.name || newErrors.email || newErrors.nickname || newErrors.password || newErrors.passwordRepeat) return;

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());

        axios
            .post(url + `/public/rest/registration/register`, formDataObj)
            .then((res) => {
                if (res.data.success) {
                    navigate("/registration-success");
                }
            })
            .catch((err) => {
                console.log(err.response.data.message)
                setMessage(err.response.data.message)
            });
    }

    return (
        <>
            <Form onSubmit={handleSubmitRegistration}>
                <Form.Group className="mb-1">
                    <Form.Control size="md" type="text" onChange={e => setField('name', e.target.value)} onBlur={e => validateField('name', e.target.value)} isInvalid={!!errors.name} name="name" placeholder="meno a priezvisko" />
                    <Form.Control.Feedback type='invalid'>
                        {errors.name}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control size="md" type="text" value={form.email } onChange={e => setField('email', e.target.value)} onBlur={e => validateField('email', e.target.value)} isInvalid={!!errors.email} name="email" placeholder="email" />
                    <Form.Control.Feedback type='invalid'>
                        {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control size="md" type="text" onChange={e => setField('nickname', e.target.value)} onBlur={e => validateField('nickname', e.target.value)} isInvalid={!!errors.nickname} name="nickname" placeholder="prezývka" />
                    <Form.Control.Feedback type='invalid'>
                        {errors.nickname}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control size="md" type="password" onChange={e => setField('password', e.target.value)} onBlur={e => validateField('password', e.target.value)} isInvalid={!!errors.password} name="password" placeholder="heslo" />
                    <Form.Control.Feedback type='invalid'>
                        {errors.password}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-1">
                    <Form.Control size="md" type="password" onChange={e => setField('passwordRepeat', e.target.value)} onBlur={e => validateField('passwordRepeat', e.target.value)} isInvalid={!!errors.passwordRepeat} name="passwordRepeat" placeholder="potvrdenie hesla" />
                    <Form.Control.Feedback type='invalid'>
                        {errors.passwordRepeat}
                    </Form.Control.Feedback>
                </Form.Group>
                <button type="submit">Zaregistrovať</button>
            </Form>
            {
                message && (
                    <div className="my-2 d-flex justify-content-center color-kuzel">
                        {message}
                    </div>
                )
            }
            <div className="color-kuzel mt-2">Si už zaregistovaný? <Link to='/'>Prihlás sa</Link></div>
        </>
    );
}