import React, { useState } from "react";
import { Loading, ModalContent } from "../../../../common";

import { url } from "../../../../config";

//vymaz maaaaa
import L from "leaflet";

const axios = require("axios").default;

export default function GeneralFunctions() {
    let [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);

    const [activities, setActivities] = useState([
        {
            name: 'DELETE_WITH_DELETED_KUZEL',
            label: 'Odstrániť notifikácie, ktoré odkazujú na neexistujúci kužeľ',
            endpoint: '/private/rest/admin/clean-up/notification/with-deleted-kuzel'
        },
        {
            name: 'REPLACE_KUZELNIK_NAME_FOR_NICKNAME',
            label: 'Nahradiť meno v notifikáciach za prezývku',
            endpoint: '/private/rest/admin/clean-up/notification/replace-name'
        },
        {
            name: 'UPDATE_NOTIFICATION_KUZEL_PHOTO',
            label: 'Aktualizovať fotku kužela v notifikáciach, pri ktorých sa zmenila',
            endpoint: '/private/rest/admin/clean-up/notification/photo-update'
        },
        {
            name: 'UPDATE_NOTIFICATION_CITY_AND_COUNTRY',
            label: 'Aktualizovať mesto a štát v notifikáciach, pri ktorých boli zmenené',
            endpoint: '/private/rest/admin/clean-up/notification/city-country'
        },
    ]);

    const doActivityModal = (activity) => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <>
                <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vykonať: "{activity.label}" ? </b></div>
            </>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => doActivity(activity)}>Áno</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const doActivity = (activity) => {
        let responseMessage = "";

        axios
            .get(url + activity.endpoint)
            .then((res) => {
                responseMessage = "Hotovo";
            })
            .catch((err) => {
                responseMessage = "Nezdarilo sa"
            })
            .then((res) => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            })
    }

    //vymaz maaaaaaaa
    const spravTo = () => {
        var geocoder = L.Control.Geocoder.nominatim({ reverseQueryParams: { 'accept-language': 'en' } });

        axios
            .get(url + '/private/rest/admin/kuzel/all?page=0&size=18000')
            .then((res) => {
                var kokot = 0;
                for (let address of res.data.data) {
                    console.log(address);

                    setTimeout(function () {

                        geocoder.reverse(
                            { lat: address.lat, lng: address.lng },
                            67108864,
                            (results) => {
                                console.log(results);
                                var r = results[0].properties.address;
                                if (r) {
                                    var addressString = (r.country_code ?? '_').trim() + '-' + (address.city ?? '_').trim() + '-' + (r.county ?? '_').trim() + '-' + (r.region ?? '_').trim() + '-' + (r.state ?? '_').trim() + '-' + (r.province ?? '_').trim();

                                    axios
                                        .put(url + '/private/rest/admin/kuzel/address-id/' + address.id, { value: addressString })
                                        .then((res) => {

                                        })

                                    console.log(addressString);
                                }
                            }
                        );
                    }, kokot)

                    kokot = kokot + 5000;
                }
            })
            .catch((err) => {

            })
    }

    return (
        <>
            <h4 className="mt-2 mb-3">Notifikácie</h4>

            {
                activities.map((activity) => {
                    return (
                        <div className="mt-2">
                            <button className="btn-kuzel secondary opacityHover ms-1 mb-1" onClick={() => doActivityModal(activity)}>{activity.label}</button>
                        </div>
                    )
                })
            }

            {/*vymaz maaaaaaaa*/}
            <button className="btn-kuzel secondary opacityHover ms-1 my-2" onClick={() => spravTo()}>sprav to</button>

            <div className="szh-menu__divider my-3"></div>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })}></ModalContent>
        </>
    );
}