import React, { useEffect, useState } from "react";
import { Table, Form, Tab, Tabs, ListGroup, ButtonGroup, ToggleButton, Modal} from 'react-bootstrap';
import { Store, ModalContent, Loading, Pagination } from "../../../../../../common";

import Select from "react-select";

import { format } from 'date-fns';

import { url } from "../../../../../../config";
const axios = require("axios").default;

export default function MyGroupsDetail({ windowConfig, onCloseDetail }) {
    const [kuzelniksInGroup, setKuzelniksInGroup] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    const [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);
    const [showType, setShowType] = useState('default');

    const [detailData, setDetailData] = useState(windowConfig.groupData);

    const [isPublic, setIsPublic] = useState();

    let [kuzelniks, setKuzelniks] = useState([]);
    let [selectedKuzelnikToInvite, setSelectedKuzelnikToInvite] = useState([]);

    let [actualValue, setActualValue] = useState('');

    useEffect(() => {
        let publicVisible = detailData?.publicVisible;
        setIsPublic(publicVisible ? "1" : "2");

        loadKuzelniks(0);
    }, [])

    const loadKuzelniks = (page) => {
        axios.get(url + `/private/rest/kuzel-group/members/${detailData.id}?page=${page}&size=10`)
            .then(res => {
                setKuzelniksInGroup(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
            });
    }


    const leaveGroupModal = () => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <>
                <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš opustiť túto komunitu ? </b></div>
            </>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => leaveGroup()}>Opustiť</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const leaveGroup = () => {
        //let responseMessage = "";

        //axios
        //    .put(url + `/private/rest/kuzel-group/leave/${detailData.id}`)
        //    .then((res) => {
        //        responseMessage = res.data.message;
        //    })
        //    .catch((err) => {
        //        if (err.response.data.message) {
        //            responseMessage = err.response.data.message;
        //        }
        //    })
        //    .then(() => {
        //        setModalConfig({
        //            show: true,
        //            headerContent: null,
        //            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>,
        //            footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
        //        });
        //    });
    }

    const archiveGroupModal = () => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <>
                <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš archivovať túto komunitu ? </b></div>
            </>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => archiveGroup()}>Archivovať</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const archiveGroup = () => {
        //let responseMessage = "";

        //axios
        //    .put(url + `/private/rest/kuzel-group/archive/${detailData.id}`)
        //    .then((res) => {
        //        responseMessage = res.data.message;
        //    })
        //    .catch((err) => {
        //        if (err.response.data.message) {
        //            responseMessage = err.response.data.message;
        //        }
        //    })
        //    .then(() => {
        //        setModalConfig({
        //            show: true,
        //            headerContent: null,
        //            bodyContent:  <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>,
        //            footerContent:  <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
        //        });
        //    });
    }

    const handleChangeGroupSettings = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            let formData = new FormData(event.target);
            let formDataObj = Object.fromEntries(formData.entries());
            let data = { ...detailData, ...formDataObj };

            axios
                .put(url + `/private/rest/kuzel-group/${detailData.id}`, data)
                .then((res) => {
                    setShowType('default');
                    setDetailData({ ...detailData, ...res.data.body });
                    window.dispatchEvent(new CustomEvent("reload-my-groups"));
                })
                .catch((err) => {
                    setModalConfig({
                        show: true,
                        headerContent: null,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>Uloženie sa nepodarilo </div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                });
        }
    }

    const showMap = () => {
        window.dispatchEvent(new CustomEvent("close-modals-and-canvas"));
        window.dispatchEvent(new CustomEvent("map-set-view", {}));

        let activeGroup = Store.getObject("selectedGroup").id;
        let newGroup = detailData.id;

        if (activeGroup !== newGroup) {
            window.dispatchEvent(new CustomEvent("change-group-from-outside", {
                'detail': {
                    groupId: detailData.id
                }
            }));
        }
    }

    const changeVisibility = (visibility) => {
        setIsPublic(visibility);

        axios
            .put(url + `/private/rest/kuzel-group/update-visibility/${detailData.id}/${visibility == "1"}`)
            .then((res) => {
                if (res.data.success) {
                    setModalConfig({
                        show: true,
                        headerContent: null,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{res.data.message}</div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                }
                window.dispatchEvent(new CustomEvent("reload-my-groups"));

            })
            .catch((err) => {
                if (err.response.data.message) {
                    setModalConfig({
                        show: true,
                        headerContent: null,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{err.response.data.message}</div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                }
            });
    }

    const fetchKuzelniks = (text) => {
        if (text.length >= 2) {
            axios
                .get(url + `/private/rest/kuzelnik/find/${text}?notInGroupId=${detailData.id}`)
                .then((res) => {
                    //aby neponukalo prihlaseneho kuzelnika
                    let kuzelniks = res.data.filter(function (kuzelnik) {
                        return kuzelnik.id !== Store.getObject("loggedUser").id;
                    });
                    
                    setKuzelniks(kuzelniks);
                    setActualValue(text);
                })
                .catch((err) => { });
        }
        else {
            setKuzelniks([]);
            setActualValue(text);
        }
    };

    const inviteKuzelnikViaName = () => {
        if (selectedKuzelnikToInvite.length === 0) return;

        let kuzelniks = selectedKuzelnikToInvite.map(kuzelnik => kuzelnik.id);

        axios
            .post(url + `/private/rest/kuzel-group/request/invite-all`, {
                groupId: detailData.id,
                kuzelniks
            })
            .then((res) => {
                if (res.data.success) {
                    setModalConfig({
                        show: true,
                        headerContent: null,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{res.data.message}</div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                }
            })
            .catch((err) => {
                if (err.response.data.message) {
                    setModalConfig({
                        show: true,
                        headerContent: null,
                        bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{err.response.data.message}</div>,
                        footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                    });
                }
            });
    }

    const inviteKuzelnikViaEmial = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            let formData = new FormData(event.target);
            let formDataObj = Object.fromEntries(formData.entries());

            axios
                .post(url + `/private/rest/kuzel-group/request/invite`, {
                    groupId: detailData.id,
                    email: formDataObj.email
                })
                .then((res) => {
                    if (res.data.success) {
                        setModalConfig({
                            show: true,
                            headerContent: null,
                            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{res.data.message}</div>,
                            footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                        });
                    }
                })
                .catch((err) => {
                    if (err.response.data.message) {
                        setModalConfig({
                            show: true,
                            headerContent: null,
                            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}>{err.response.data.message}</div>,
                            footerContent: <button className="btn-kuzel w-100" onClick={() => setModalConfig({ show: false })}>Rozumiem</button>
                        });
                    }
                });
        }
    }

    return (
        <>
            <Modal
                animation={false}
                show={true}
                size='xl'
                fullscreen="md-down"
                scrollable="false"
                onHide={() => onCloseDetail()}
                className='fullHeight'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100 color-kuzel">
                        {detailData.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex row">
                        {
                            detailData.role == "ADMIN" ? (
                                <>
                                    <div className="col-12 col-lg-6">
                                        <ListGroup className="mb-2 mb-lg-0">
                                            <ListGroup.Item>Popis: <b> {detailData.description}</b></ListGroup.Item>
                                            <ListGroup.Item>Počet kužeľníkov: <b> {detailData.membersCount}</b></ListGroup.Item>
                                            <ListGroup.Item>Počet kužeľových udalostí: <b> {detailData.kuzelCount}</b></ListGroup.Item>
                                            <ListGroup.Item>Posledná kužeľová udalosť: <b> {detailData.lastKuzelDate ? format(new Date(detailData.lastKuzelDate), 'dd.MM.yyyy') : ""}</b></ListGroup.Item>
                                        </ListGroup>
                                    </div>

                                    <div className="d-flex flex-column col-12 col-lg-6 px-3 mt-2 mt-lg-0 mb-2">
                                        {
                                            showType === 'default' && (
                                                <>
                                                    <ButtonGroup>
                                                        <ToggleButton
                                                            key="1"
                                                            id="radio-1"
                                                            type="radio"
                                                            variant="outline-success"
                                                            name="radio"
                                                            value="1"
                                                            checked={isPublic === "1"}
                                                            onChange={(e) => changeVisibility(e.currentTarget.value)}
                                                        >
                                                            Verejná
                                                        </ToggleButton>
                                                        <ToggleButton
                                                            key="2"
                                                            id="radio-2"
                                                            type="radio"
                                                            variant="outline-secondary"
                                                            name="radio"
                                                            value="2"
                                                            checked={isPublic === "2"}
                                                            onChange={(e) => changeVisibility(e.currentTarget.value)}
                                                        >
                                                            Uzavretá
                                                        </ToggleButton>
                                                    </ButtonGroup>

                                                    <button className="btn-kuzel secondary mt-1" onClick={() => setShowType('editGroup')}>Upraviť komunitu</button>
                                                    <button className="btn-kuzel secondary mt-1" onClick={() => setShowType('inviteNewKuzelnik')}>Pozvať kužeľníka</button>
                                                    <button className="btn-kuzel secondary mt-1" onClick={() => showMap()}>Zobraziť mapu</button>
                                                    {/*<div>
                                                        <button className="btn-kuzel secondary mt-1 w-50" onClick={() => leaveGroupModal()}>Opustiť komunitu</button>
                                                        <button className="btn-kuzel secondary mt-1 w-50" onClick={() => archiveGroupModal()}>Archivovať</button>
                                                    </div>*/}
                                                </>
                                            )
                                        }
                                        {
                                            showType === 'inviteNewKuzelnik' && (
                                                <>
                                                    <div className="d-flex justify-content-center color-kuzel form-label">
                                                        <b>Pozvánka kužeľníka</b>
                                                    </div>
                                                    <Tabs
                                                        defaultActiveKey="viaNickname"
                                                        id="uncontrolled-tab-example"
                                                        justify
                                                    >
                                                        <Tab eventKey="viaNickname" title="Meno">
                                                            <Select
                                                                className="my-1"
                                                                onChange={(e) => {
                                                                    setSelectedKuzelnikToInvite(e);
                                                                }}
                                                                placeholder={'Zadaj prezývku alebo meno'}
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                getOptionLabel={(option) => (option.nickname + (option.name ? (' (' + option.name + ')') : ''))}
                                                                getOptionValue={(option) => option.id}
                                                                options={kuzelniks}
                                                                onInputChange={(val) => fetchKuzelniks(val)}
                                                                noOptionsMessage={() => (actualValue.length >= 2) ? 'Žiadne výsledky vyhľadávania' : 'Zadaj minimálne 2 znaky pre vyhľadanie kužeľníka !'}
                                                            />
                                                            <button className="btn-kuzel w-50" onClick={() => inviteKuzelnikViaName()}>Pozvať</button>
                                                            <button className="w-50 btn-kuzel secondary" onClick={() => setShowType('default')}>Zrušiť</button>
                                                        </Tab>
                                                        <Tab eventKey="viaEmail" title="Email">
                                                            <Form onSubmit={inviteKuzelnikViaEmial} >
                                                                <Form.Group>
                                                                    <Form.Control className="my-1" size="md" type="email" required="{true}" name="email" placeholder="Zadaj email" />
                                                                </Form.Group>
                                                                <button type="submit" className="btn-kuzel w-50" >Pozvať</button>
                                                                <button className="w-50 btn-kuzel secondary" onClick={() => setShowType('default')}>Zrušiť</button>
                                                            </Form>
                                                        </Tab>
                                                    </Tabs>
                                                </>
                                            )
                                        }
                                        {
                                            showType === 'editGroup' && (
                                                <Form onSubmit={handleChangeGroupSettings}>
                                                    <Form.Group>
                                                        <label>Názov</label>
                                                        <Form.Control size="md" defaultValue={detailData.name} required="{true}" minLength={3} maxLength={30} name="name" placeholder="Zadaj názov" />
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <label>Popis</label>
                                                        <Form.Control size="md" defaultValue={detailData.description} maxLength={95} name="description" placeholder="Zadaj popis" />
                                                    </Form.Group>
                                                    <button className="w-50 btn-kuzel mt-1">Uložiť</button>
                                                    <button className="w-50 btn-kuzel secondary mt-1" onClick={() => setShowType('default')}>Zrušiť</button>
                                                </Form>
                                            )
                                        }

                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="d-flex justify-content-center">
                                        <ListGroup className="col-12 col-lg-6">
                                            <ListGroup.Item>Popis: <b> {detailData.description}</b></ListGroup.Item>
                                            <ListGroup.Item>Počet kužeľníkov: <b> {detailData.membersCount}</b></ListGroup.Item>
                                            <ListGroup.Item>Počet kužeľových udalostí: <b> {detailData.kuzelCount}</b></ListGroup.Item>
                                            <ListGroup.Item>Posledná kužeľová udalosť: <b> {detailData.lastKuzelDate ? format(new Date(detailData.lastKuzelDate), 'dd.MM.yyyy') : ""}</b></ListGroup.Item>
                                        </ListGroup>
                                    </div>


                                    <div className="d-flex justify-content-center mt-2">
                                        {/*   <button className="btn-kuzel secondary col-12 col-lg-6" onClick={() => leaveGroupModal()}>Opustiť komunitu</button> */}
                                        <button className="btn-kuzel secondary col-12 col-lg-6" onClick={() => showMap()}>Zobraziť mapu</button>
                                    </div>

                                </>
                            )
                        }
                    </div>

                    <Table className="mt-2">
                        <thead>
                            <tr>
                                <th className="col-1"></th>
                                <th className="col-4">Kužeľník</th>
                                <th className="col-4">Počet udalostí</th>
                                <th className="col-5">Posledná udalosť</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr className="text-center">
                                        <td colSpan="100%" className="border-0">
                                            <Loading marginTop='30px'></Loading>
                                        </td>
                                    </tr>
                                ) : (
                                    kuzelniksInGroup.data.map((kuzelnik, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1 + kuzelniksInGroup.page * 10}.</td>
                                                <td>{kuzelnik.nickname}</td>
                                                <td>{kuzelnik.kuzelCount}</td>
                                                <td>{kuzelnik.lastKuzel ? format(new Date(kuzelnik.lastKuzel), 'dd.MM.yyyy') : ""}</td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-center">
                        <Pagination page={kuzelniksInGroup.page} totalPages={kuzelniksInGroup.totalPage} onPageChange={page => loadKuzelniks(page)} />
                    </div>
                </Modal.Body>
            </Modal>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}