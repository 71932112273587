import React from "react";
import { Modal } from 'react-bootstrap';
import Loading from '../utils/Loading';

export default function ModalContent({ modalConfig, loading = false, loadingText = "Ukladám", onClose }) {
    return (
        <Modal
            show={modalConfig.show}
            size={modalConfig.size ?? 'md'}
            backdrop={modalConfig.backdrop ?? true}
            fullscreen={modalConfig.fullscreen ? "md-down" : false}
            scrollable="false"
            onHide={() => onClose()}
            centered>
            {
                modalConfig.headerContent && (
                    <Modal.Header closeButton>
                        <Modal.Title className="text-center w-100">
                            {modalConfig.headerContent}
                        </Modal.Title>
                    </Modal.Header>
                )
            }
            {
                modalConfig.bodyContent && (
                    <Modal.Body>
                        {modalConfig.bodyContent}
                    </Modal.Body>
                )
            }
            {
                modalConfig.footerContent && (
                    <Modal.Footer>
                        {modalConfig.footerContent}
                    </Modal.Footer>
                )
            }

            {loading && (
                <Loading text={loadingText} marginTop="160px" loadingType="fullscreenCone"></Loading>
            )}
        </Modal>
    );
}