import React, { useState } from "react";
import { Form, Collapse } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../../../config";
const axios = require("axios").default;

export default function Useranvas() {
    let [showChangePassword, setShowChangePassword] = useState(false);

    const [form, setForm] = useState({ oldPassword: '', newPassword: '', newPasswordRepeat: '' })

    const [errors, setErrors] = useState({})

    const [message, setMessage] = useState('')

    const validateField = (field, value) => {
        if (field === 'oldPassword') {
            setErrors({
                ...errors,
                oldPassword: validateOldPassword({ [field]: value })
            })
        }
        else if (field === 'newPassword') {
            let newErrors = {
                ...errors,
                newPassword: validateNewPassword({ [field]: value }),
            }

            if (form.newPasswordRepeat && form.newPasswordRepeat.length > 0) {
                newErrors.newPasswordRepeat = validateNewPasswordRepeat({ newPassword: value, newPasswordRepeat: form['newPasswordRepeat'] })
            }
            setErrors(newErrors)
        }
        else if (field === 'newPasswordRepeat') {
            setErrors({
                ...errors,
                newPasswordRepeat: validateNewPasswordRepeat({ [field]: value, newPassword: form['newPassword'] })
            })
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if (field === 'oldPassword') {
            validateOldPassword({ [field]: value })
        }
        else if (field === 'newPassword') {
            validateNewPassword({ [field]: value })
        }
        else if (field === 'newPasswordRepeat') {
            validateNewPasswordRepeat({ [field]: value, newPassword: form['newPassword'] })
        }
    }

    let validateOldPassword = (form) => {
        let errorMessage = '';
        if (!form.oldPassword) errorMessage = 'Zadaj staré heslo !'
        return errorMessage;
    }

    let validateNewPassword = (form) => {
        let errorMessage = '';
        if (!form.newPassword) errorMessage = 'Zadaj nové heslo !'
        else if (form?.newPassword?.length < 8) errorMessage = 'Minimálna dĺžka hesla je 8 znakov !'
        else if (!/[A-Z]/.test(form.newPassword)) errorMessage = 'Heslo musí obsahovať minimálne jedno veľké písmeno !'
        else if (!/[0-9]/.test(form.newPassword)) errorMessage = 'Heslo musí obsahovať minimálne jedno číslo !'
        return errorMessage;
    }

    let validateNewPasswordRepeat = (form) => {
        let errorMessage = '';
        if (!form.newPasswordRepeat) errorMessage = 'Zadaj nové heslo !'
        else if (form.newPassword !== form.newPasswordRepeat && form.newPassword.length) errorMessage = 'Heslá sa nezhodujú !'
        return errorMessage;
    }

    const handleSubmitChangePassword = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let newErrors = {
            oldPassword: validateOldPassword(form),
            newPassword: validateNewPassword(form),
            newPasswordRepeat: validateNewPasswordRepeat(form)
        };
        setErrors(newErrors);

        if (newErrors.oldPassword || newErrors.oldPassword || newErrors.newPasswordRepeat) return;

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());

        axios
            .put(url + `/private/rest/kuzelnik/change-password`, formDataObj)
            .then((res) => {
                if (res.data.success) {
                    setShowChangePassword(false);
                    setMessage('Heslo bolo úspešne zmenené !');
                }
            })
            .catch((err) => {
                setErrors({ oldPassword: 'Zadané heslo nieje správne !' });
                console.log(err)
            });
    };

    return (
        <>
            <button
                onClick={() => setShowChangePassword(!showChangePassword)}
                aria-expanded={showChangePassword}
                className="btn-kuzel secondary w-100 my-1"
            >
                <FontAwesomeIcon icon="fa-solid fa-lock" className="me-2" />Zmeniť heslo
            </button>
            <Collapse in={showChangePassword}>
                <div>
                    <Form onSubmit={handleSubmitChangePassword} className="p-2 my-2 border">
                        <Form.Group className="mb-1">
                            <Form.Label>Staré heslo</Form.Label>
                            <Form.Control size="md" onChange={e => setField('oldPassword', e.target.value)} onBlur={e => validateField('oldPassword', e.target.value)} isInvalid={!!errors.oldPassword} type="password" name="oldPassword" />
                            <Form.Control.Feedback type='invalid'>
                                {errors.oldPassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Form.Label>Nové heslo</Form.Label>
                            <Form.Control size="md" onChange={e => setField('newPassword', e.target.value)} onBlur={e => validateField('newPassword', e.target.value)} isInvalid={!!errors.newPassword} type="password" name="newPassword" />
                            <Form.Control.Feedback type='invalid'>
                                {errors.newPassword}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-1">
                            <Form.Label>Zopakuj nové heslo</Form.Label>
                            <Form.Control size="md" onChange={e => setField('newPasswordRepeat', e.target.value)} onBlur={e => validateField('newPasswordRepeat', e.target.value)} isInvalid={!!errors.newPasswordRepeat} type="password" name="newPasswordRepeat" />
                            <Form.Control.Feedback type='invalid'>
                                {errors.newPasswordRepeat}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <button type="submit" className="btn-kuzel mt-2 w-100" >Zmeniť</button>
                    </Form>
                </div>
            </Collapse>
            {
                message && (
                    <div className="my-2 d-flex justify-content-center color-kuzel" style={{ fontSize: '18px' }} >
                        {message}
                    </div>
                )
            }
        </>
    );
}