import React, { useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';

import MyGroups from './GroupModalModules/MyGroups';
import MyGroupsCreate from './GroupModalModules/MyGroupsCreate';
import MyGroupsDetail from './GroupModalModules/MyGroupsDetail';
import PublicGroups from './GroupModalModules/PublicGroups';
import PublicGroupsDetail from './GroupModalModules/PublicGroupsDetail';

export default function GroupsModal() {
    const [windowConfig, setWindowConfig] = useState({ type: 'home' })

    return (
        <>
            <div className='row px-3 justify-content-center'>
                <img src={require('./../../../../../resources/groups.png')} alt="komunity" className='col-8 col-md-3 px-3' style={{ aspectRatio: '1.53' }} />
            </div>
            <div className='row mt-2 px-3 justify-content-center'>
                <button className="btn-kuzel col-12 col-md-4" onClick={() => setWindowConfig({ type: 'myGroupsCreate' })}>Vytvoriť novú komunitu</button>
            </div>
            <div className="szh-menu__divider my-2"></div>
            <Tabs
                defaultActiveKey="myGroups"
                id="uncontrolled-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="myGroups" title="Moje">
                    <MyGroups onClickShowDetail={(data) => setWindowConfig({ type: 'myGroupsDetail', groupData: data })}></MyGroups>
                </Tab>

                <Tab eventKey="publicGroups" title="Verejné">
                    <PublicGroups onClickShowDetail={(data) => setWindowConfig({ type: 'publicGroupsDetail', groupData: data })}></PublicGroups>
                </Tab>

                {/*<Tab eventKey="archiveGroups" title="Archívne">*/}

                {/*</Tab>*/}
            </Tabs>
            {
                (windowConfig.type === 'myGroupsCreate') && (
                    <MyGroupsCreate onCloseDetail={() => setWindowConfig({ type: 'home' })}></MyGroupsCreate>
                )
            }
            {
                (windowConfig.type === 'myGroupsDetail') && (
                    <MyGroupsDetail windowConfig={windowConfig} onCloseDetail={() => setWindowConfig({ type: 'home' })}></MyGroupsDetail>
                )
            }
            {
                (windowConfig.type === 'publicGroupsDetail') && (
                    <PublicGroupsDetail windowConfig={windowConfig} onCloseDetail={() => setWindowConfig({ type: 'home' })}></PublicGroupsDetail>
                )
            }
        </>
    );
}