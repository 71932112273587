import React, { useState } from "react";
import { Form } from 'react-bootstrap';
import { Loading, ModalContent } from "../../../../common";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function GroupsDetail({ initDetailData, loadGroups, closeDetail }) {
    let [modalConfig, setModalConfig] = useState({ show: false });

    let [loading, setLoading] = useState(true);

    let [detailData, setDetailData] = useState(initDetailData);

    let [disableLockedReason, setDisableLockedReason] = useState(!detailData.locked);

    const deleteGroup = () => {
        let responseMessage = "";

        //axios.delete(url + "/private/rest/admin/kuzel-group/" + detailData.id)
        //    .then(res => {
        //        loadUsers();
        //        responseMessage = "Vymazanie úspešné";
        //    })
        //    .catch(err => {
        //        responseMessage = "Vymazanie sa voláko nepodarilo";
        //        console.log(err)
        //    })
        //    .then(res => {
        //        setModalConfig({
        //            show: true,
        //            headerContent: null,
        //            bodyContent: <>
        //                <div className="text-center" style={{ fontSize: '15px' }}><b>{responseMessage}</b></div>
        //            </>,
        //            footerContent: <>
        //                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
        //            </>
        //        });
        //    });
    }

    const deleteGroupModal = (event) => {
        event.preventDefault();
        event.stopPropagation();

        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vymazať túto skupinu ? </b></div>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => deleteGroup()}>Vymazať</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const saveDataHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());
        formDataObj = { ...detailData, ...formDataObj };
        setLoading(true);

        axios
            .put(url + `/private/rest/admin/kuzel-group/` + detailData.id, formDataObj)
            .then((res) => {
                //loadingDorobim
                setLoading(false);
                loadGroups();
                closeDetail();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <Form onSubmit={(val) => saveDataHandler(val)} className="border mb-3 mx-1 row adminDetail">
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Názov</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.name} required="{true}" name="name" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Popis</Form.Label>
                    <Form.Control size="md" type="text" defaultValue={detailData.description} name="description" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Autor</Form.Label>
                    <Form.Control size="md" type="text" disabled="disabled" defaultValue={detailData.author.name} name="author" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Dátum vytvorenia</Form.Label>
                    <Form.Control size="md" type="date" disabled="disabled" defaultValue={detailData.created?.substring(0, 10)} required="{true}" name="created" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Počet členov</Form.Label>
                    <Form.Control size="md" type="text" disabled="disabled" defaultValue={detailData.membersCount} name="membersCount" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6">
                    <Form.Label>Počet kužeľových udalostí</Form.Label>
                    <Form.Control size="md" type="text" disabled="disabled" defaultValue={detailData.kuzelCount} name="kuzelCount" />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6 mt-3 mt-lg-5">
                    <Form.Check
                        inline
                        label="Aktívna"
                        type="radio"
                        id="archived-1"
                        checked={detailData.archived == false}
                        onChange={() => {
                            setDetailData({ ...detailData, archived: false }); 
                        }}
                    />
                    <Form.Check
                        inline
                        label="Archivovaná"
                        type="radio"
                        id="archived-2"
                        checked={detailData.archived == true}
                        onChange={() => {
                            setDetailData({ ...detailData, archived: true }); 
                        }}
                    />
                </Form.Group>
                <Form.Group className="col-12 col-lg-6 mt-3 mt-lg-5">
                    <Form.Check
                        inline
                        label="Verejná"
                        type="radio"
                        id="archived-1"
                        checked={detailData.publicVisible == true}
                        onChange={() => {
                            setDetailData({ ...detailData, publicVisible: true });
                        }}
                    />
                    <Form.Check
                        inline
                        label="Neverejná"
                        type="radio"
                        id="archived-2"
                        checked={detailData.publicVisible == false}
                        onChange={() => {
                            setDetailData({ ...detailData, publicVisible: false });
                        }}
                    />
                </Form.Group>
                <div className="mt-3 mb-2">
                    <button type="submit" className="btn btn-success">Uložiť</button>
                    <button className="btn btn-light ms-2" onClick={() => closeDetail()}>Zrušiť</button>
                    {/*<button className="btn btn-danger ms-2" onClick={(e) => deleteGroupModal(e)}>Vymazať</button>*/}
                </div>
            </Form>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}