import React, { useEffect, useState } from "react";
import { Table, Collapse, Form } from 'react-bootstrap';
import { Loading, ModalContent, Pagination } from "../../../../common";

import ConeEventsDetail from './ConeEventsDetail';

import { format } from "date-fns";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function ConeEvents() {
    const [coneEvents, setConeEvents] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    let [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);

    const [detailData, setDetailData] = useState({});

    const [showFilter, setShowFilter] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        loadConeEvents(0);
    }, [])

    const loadConeEvents = (page, search = "") => {
        axios.get(url + "/private/rest/admin/kuzel/all?page=" + page + "&size=10&search=" + search)
            .then(res => {
                setConeEvents(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const openDetail = (data) => {
        if (data.id === detailData.id) {
            setDetailData({});
        }
        else {
            getDetailData(data);
        }
    }

    const getDetailData = (data) => {
        axios
            .get(url + "/private/rest/kuzel/detail/" + data.id)
            .then((res) => {
                setDetailData({ ...data, ...res.data });
                //setLoading(false);
            })
            .catch((err) => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Nepodarilo sa načítať korektne</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            });
    }

    return (
        <>
            <button className="btn-kuzel secondary w-100 mb-3" onClick={() => setShowFilter(!showFilter)}>Filter</button>
            <div>
                <Collapse in={showFilter} className="w-100">
                    <div>
                        <div className="w-100 p-2 pt-0 mb-2">
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Lokalizácia</Form.Label>
                                <Form.Control size="md" type="text" required="{true}" name="city"
                                    onChange={e => {
                                        loadConeEvents(0, e.currentTarget.value);
                                        setFilterValue(e.currentTarget.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Collapse>
            </div>

            <Table>
                <thead>
                    <tr>
                        <th className="col"></th>
                        <th className="col-6">Lokalizácia</th>
                        <th className="col-6">Dátum vytvorenia</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='170px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            coneEvents.data.map((coneEvent, index) => {
                                return (
                                    <>
                                        <tr key={index} onClick={() => openDetail(coneEvent)} role="button">
                                            <td>{index + 1 + coneEvents.page * 10}.</td>
                                            <td>{coneEvent.city + ", " + coneEvent.country.toUpperCase()}</td>
                                            <td>{format(new Date(coneEvent.created), 'dd.MM.yyyy')}</td>
                                        </tr>
                                        <tr className="opacityHoverNone">
                                            <td colSpan="100%" className="border-0 p-0">
                                                <Collapse in={coneEvent.id === detailData.id} className="w-100">
                                                    <div>
                                                        {
                                                            coneEvent.id === detailData.id && (
                                                                <ConeEventsDetail initDetailData={detailData} loadConeEvents={() => loadConeEvents(coneEvents.page)} closeDetail={() => setDetailData({})}></ConeEventsDetail>
                                                            )
                                                        }
                                                    </div>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>

            <div className="d-flex justify-content-center">
                <Pagination page={coneEvents.page} totalPages={coneEvents.totalPage} onPageChange={page => loadConeEvents(page, filterValue)} />
            </div>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}