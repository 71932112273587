import React, { useEffect, useState } from "react";
import { Form, Table, Badge, Collapse } from 'react-bootstrap';
import { Loading, Pagination } from "../../../../common";

import RoleDetail from "./RoleDetail";
import { FaPlus } from "react-icons/fa";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function Roles() {
    const [roles, setRoles] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    const [loading, setLoading] = useState(true);

    const [detailData, setDetailData] = useState({});

    const [showFilter, setShowFilter] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        loadRoles(0);
    }, [])

    const loadRoles = (page, search = "") => {
        axios.get(url + "/private/rest/admin/role/all?page=" + page + "&size=10&search=" + search)
            .then(res => {
                setRoles(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            });
    }

    const openDetail = (data) => {
        if (data.id === detailData.id) {
            setDetailData({});
        }
        else {
            setDetailData(data);
        }
    }

    return (
        <>
            <button className="btn-kuzel secondary w-100 mb-3" onClick={() => setShowFilter(!showFilter)}>Filter</button>
            <div>
                <Collapse in={showFilter} className="w-100">
                    <div>
                        <div className="w-100 p-2 pt-0 mb-2">
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Názov</Form.Label>
                                <Form.Control size="md" type="text" required="{true}" name="city"
                                    onChange={e => {
                                        loadRoles(0, e.currentTarget.value);
                                        setFilterValue(e.currentTarget.value);
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Collapse>
            </div>

            <div className="add-new-button">
                <button type="submit" className="btn btn-success" onClick={() => setDetailData({ id: "CREATE" })}><FaPlus className="me-1 mb-1" />Vytvoriť novú</button>
            </div>

            <Collapse in={detailData.id === "CREATE"} className="w-100">
                <div>
                    {
                        detailData.id === "CREATE" && (
                            <RoleDetail callback={() => loadRoles(roles.page, filterValue)} closeDetail={() => setDetailData({})} />
                        )
                    }
                </div>
            </Collapse>

            <Table>
                <thead>
                    <tr>
                        <th className="col-2">Názov</th>
                        <th className="col-2">Popis</th>
                        {/*<th className="col-4">Práva</th>*/}
                        {/*<th className="col-4">Prístupy</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='170px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            roles.data.map((role, index) => {
                                return (
                                    <>
                                        <tr key={role.id} onClick={() => openDetail(role)} role="button">
                                            <td>{role.name}</td>
                                            <td>{role.description}</td>
                                            {/*<td align="left">{role.permissions.map(perm => <Badge key={perm.value} color="primary" className="badge-role">{perm.value}</Badge>)}</td>*/}
                                            {/*<td align="left">{role.accesses.map(access => <Badge key={access.value} color="primary" className="badge-role">{access.value}</Badge>)}</td>*/}
                                        </tr>
                                        <tr key={"hv" + role.id} className="opacityHoverNone">
                                            <td colSpan="100%" className="border-0 p-0">
                                                <Collapse in={role.id === detailData.id} className="w-100">
                                                    <div>
                                                        {
                                                            role.id === detailData.id && (
                                                                <RoleDetail id={role.id} callback={() => loadRoles(roles.page, filterValue)} propsData={role} closeDetail={() => setDetailData({})} />
                                                            )
                                                        }
                                                    </div>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>

            <div className="d-flex justify-content-center">
                <Pagination page={roles.page} totalPages={roles.totalPage} onPageChange={page => loadRoles(page, filterValue)} />
            </div>
        </>
    );
}