import React, { useEffect, useState } from "react";
import { Store } from "../../../../common";
import { Menu, MenuItem, MenuHeader, MenuDivider, MenuButton } from '@szhsin/react-menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../config";
const axios = require("axios").default;

export default function GroupsDropdown() {
    const [selectedGroup, setSelectedGroup] = useState({ name: 'Veľkomunita', id: null });

    const [myGroups, setMyGroups] = useState([]);
    const [publicGroups, setPublicGroups] = useState([]);

    const handleSelectGroup = (group) => {
        if (group.id !== selectedGroup.id) {
            setSelectedGroup({ id: group.id, name: group.name });

            Store.setObject("selectedGroup", { id: group.id, name: group.name });

            window.dispatchEvent(new CustomEvent("map-set-view", {}));
        }
    }

    useEffect(() => {
        loadMyGroups();
        loadPublicGroups();

        let sg = Store.getObject("selectedGroup");
        if (sg && sg.id && sg.id > 0)
            setSelectedGroup(sg);

        window.addEventListener("reload-my-groups", () => { loadMyGroups() });
        window.addEventListener("change-group-from-outside", handleChangeGroupFromOutside, false);
    }, [])

    useEffect(() => {
        //musel som to spraviť cez window lebo inak nechcel zoznam groups vstupovať do addEventListenera
        window.allGroups = [...myGroups, ...publicGroups];
    }, [myGroups, publicGroups])

    const handleChangeGroupFromOutside = function (e) {
        let newGroupId = e.detail.groupId;

        if (newGroupId == null) {
            setSelectedGroup({ name: 'Veľkomunita', id: null });
            Store.setObject("selectedGroup", { name: 'Veľkomunita', id: null });
        } else {
            let groups = window.allGroups;
            for (let group of groups) {
                if (group.id == newGroupId) {
                    setSelectedGroup(group);
                    Store.setObject("selectedGroup", group);
                }
            }
        }
    };

    const loadMyGroups = () => {
        axios.get(url + "/private/rest/kuzel-group/my-groups")
            .then(res => {
                setMyGroups(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    const loadPublicGroups = () => {
        axios.get(url + "/private/rest/kuzel-group/public-groups")
            .then(res => {
                setPublicGroups(res.data)
            })
            .catch(err => {
                console.log(err)
            });
    }

    return (
        <Menu
            menuButton={<MenuButton
                className='groupsButton opacityHover d-flex justify-content-between'>
                {selectedGroup.name.length > 14 ? selectedGroup.name.substring(0, 11) + '...' : selectedGroup.name}
                <FontAwesomeIcon icon="fa-solid fa-chevron-down" /></MenuButton>}
            overflow='auto'
            position='anchor'
            transition
        >
            <MenuItem onClick={() => handleSelectGroup({ id: null, name: "Veľkomunita" })}
                className={selectedGroup.id === null ? 'bg-kuzel' : ''}
                style={{ minWidth: '200px' }}>Veľkomunita</MenuItem>
            <MenuDivider />
            <MenuHeader>Moje komunity</MenuHeader>
            {
                myGroups.map(group => (
                    <MenuItem key={group.id}
                        onClick={() => handleSelectGroup(group)}
                        className={`d-flex justify-content-between ${selectedGroup.id === group.id ? 'bg-kuzel' : ''}`}>
                        {group.name}
                        {group.admin && (<FontAwesomeIcon size='sm' className='ms-1' icon="fa-solid fa-user-gear" />)}
                    </MenuItem>
                ))
            }
            <MenuDivider />
            <MenuHeader>Verejné komunity</MenuHeader>
            {
                publicGroups.map(group => (
                    <MenuItem key={group.id}
                        onClick={() => handleSelectGroup(group)}
                        className={`d-flex justify-content-between ${selectedGroup.id === group.id ? 'bg-kuzel' : ''}`}>
                        {group.name}
                    </MenuItem>
                ))
            }
        </Menu>
    );
}