import React, { useEffect, useState } from "react";
import { Spinner, Form } from 'react-bootstrap';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { url } from "../../../config";
const axios = require("axios").default;

export default function ResetPassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        axios
            .put(url + `/public/rest/reset-password/verify/${searchParams.get("h")}`)
            .then((res) => {
                if (res.data.success)
                    setLoading(false);
            })
            .catch((err) => {
                console.log(err)
            });
    }, [])

    const [form, setForm] = useState({
        password: '',
        passwordRepeat: '',
    })

    const [errors, setErrors] = useState({});

    const [message, setMessage] = useState('')

    const validateField = (field, value) => {
        if (field === 'password') {
            let newErrors = {
                ...errors,
                password: validatePassword({ [field]: value }),
            }

            if (form.passwordRepeat && form.passwordRepeat.length > 0) {
                newErrors.passwordRepeat = validatePasswordRepeat({ password: value, passwordRepeat: form['passwordRepeat'] })
            }
            setErrors(newErrors)
        }
        else if (field === 'passwordRepeat') {
            setErrors({
                ...errors,
                passwordRepeat: validatePasswordRepeat({ [field]: value, password: form['password'] })
            })
        }
    }

    const setField = (field, value) => {
        setForm({
            ...form,
            [field]: value
        })

        if (field === 'password') {
            validatePassword({ [field]: value })
        }
        else if (field === 'passwordRepeat') {
            validatePasswordRepeat({ [field]: value, password: form['password'] })
        }
    }

    let validatePassword = (form) => {
        let errorMessage = '';
        if (!form.password) errorMessage = 'Zadaj heslo !'
        else if (form?.password?.length < 8) errorMessage = 'Minimálna dĺžka hesla je 8 znakov !'
        else if (!/[A-Z]/.test(form.password)) errorMessage = 'Heslo musí obsahovať minimálne jedno veľké písmeno !'
        else if (!/[0-9]/.test(form.password)) errorMessage = 'Heslo musí obsahovať minimálne jedno číslo !'
        return errorMessage;
    }

    let validatePasswordRepeat = (form) => {
        let errorMessage = '';
        if (!form.passwordRepeat) errorMessage = 'Zadaj nové heslo !'
        else if (form.password !== form.passwordRepeat && form.password.length) errorMessage = 'Heslá sa nezhodujú !'
        return errorMessage;
    }

    const handleSubmitChangePassword = (event) => {
        event.preventDefault();
        event.stopPropagation();

        let newErrors = {
            password: validatePassword(form),
            passwordRepeat: validatePasswordRepeat(form)
        };
        setErrors(newErrors);

        if (newErrors.password || newErrors.passwordRepeat) return;

        let formData = new FormData(event.target);
        let formDataObj = Object.fromEntries(formData.entries());

        axios
            .post(url + `/public/rest/reset-password/change`, {
                password: formDataObj.password,
                hash: searchParams.get("h")
            })
            .then((res) => {
                if (res.data.success)
                    navigate("/reset-password-success");
            })
            .catch((err) => {
                //setResetPasswordData({...resetPasswordData, errorMessage: err.response.data.message})
            });
    }

    return (
        loading ?
            <div className="text-center my-5">
                <Spinner animation="border" />
            </div>
            :
            <>
                <Form onSubmit={handleSubmitChangePassword}>
                    <Form.Group className="mb-1">
                        <Form.Control size="md" type="password" onChange={e => setField('password', e.target.value)} onBlur={e => validateField('password', e.target.value)} isInvalid={!!errors.password} name="password" placeholder="heslo" />
                        <Form.Control.Feedback type='invalid'>
                            {errors.password}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Control size="md" type="password" onChange={e => setField('passwordRepeat', e.target.value)} onBlur={e => validateField('passwordRepeat', e.target.value)} isInvalid={!!errors.passwordRepeat} name="passwordRepeat" placeholder="potvrdenie hesla" />
                        <Form.Control.Feedback type='invalid'>
                            {errors.passwordRepeat}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <button type="submit">Zmeniť heslo</button>
                </Form>

                <div className="color-kuzel mt-2">Späť na <Link to='/'>prihlásenie</Link></div>
            </>
    );
}