import React, { useEffect, useState } from "react";
import { Offcanvas, ListGroup, Carousel } from 'react-bootstrap';
import { Store, UserDetail } from "../../../../../common";

import { Link } from "react-router-dom";

import Settings from "./UserCanvasModules/Settings";
import ChangePassword from "./UserCanvasModules/ChangePassword";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../../../../../config";
const axios = require("axios").default;

export default function UserCanvas({ setParentModalConfig }) {
    const [showCanvas, setShowCanvas] = useState(false);
    const [showMyConeEventsModal, setShowMyConeEventsModal] = useState(false);
    const [stats, setStats] = useState({ kuzelCount: null, rank: null, lastKuzelDate: null })

    useEffect(() => {
        window.addEventListener("close-modals-and-canvas", () => { setShowCanvas(false) });
    }, [])

    const logout = () => {
        axios.get(url + "/user-logout")
            .then(() => {
                localStorage.clear();
                Store.setObject("loggedUser", {});
                window.dispatchEvent(new CustomEvent("sign-out-event"));
            })
            .catch(err => {
                console.log(err)
            });
    }

    const getDays = (date) => {
        //pozor date moze byt null ak je novy pouzivatel, pripadne novacek v skupine a nema ziadny kuzel tam
        if (!date) return "-";

        let date1 = new Date();
        let date2 = new Date(date);
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
        const diffTime = Math.abs(date1 - date2);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) return "0 dní"
        else if (diffDays === 1) return "1 deň"
        else if (diffDays <= 4) return diffDays + " dni"
        else return diffDays + " dní";
    }

    const loadData = () => {
        let groupId = Store.getObject("selectedGroup").id;
        //axios.get(url + `/private/rest/kuzelnik/stats${groupId ? `/${groupId}` : ''}`)  //ked si to Dominik opravi a da tam normalny ranking tak to možme používat aj podla komunity
        axios.get(url + `/private/rest/kuzelnik/stats`)
            .then(res => {
                setStats(res.data);
            })
            .catch(err => { });
    }

    return (
        <>
            <FontAwesomeIcon className="mainIcon" onClick={() => setShowCanvas(true)} icon="fa-regular fa-user" />

            <Offcanvas show={showCanvas} onShow={loadData} onHide={() => setShowCanvas(false)} placement="end" >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="color-kuzel">{Store.getObject("loggedUser").nickname ?? Store.getObject("loggedUser").name}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="pt-0">
                    <ListGroup className="my-2">
                        <ListGroup.Item
                            role="button"
                            className="opacityHover"
                            onClick={() => function () {
                                setShowMyConeEventsModal(true);
                                setShowCanvas(false);
                            }()}>
                            <span>Počet kužeľových udalostí:</span>
                            <b className="ms-1 me-2">{stats.kuzelCount}</b>
                            <FontAwesomeIcon icon="fa-solid fa-images" />
                        </ListGroup.Item>
                        <ListGroup.Item
                            role="button"
                            className="opacityHover"
                            onClick={() => function () {
                                setParentModalConfig({ show: 'Rebríček kužeľníkov' });
                                setShowCanvas(false);
                            }()}>
                            <span>Veľkomunitný ranking:</span>
                            <b className="ms-1">{stats.rank}</b>
                        </ListGroup.Item>
                        <ListGroup.Item
                            role="button"
                            className="opacityHover"
                            onClick={() => function () {
                                setShowMyConeEventsModal(true);
                                setShowCanvas(false);
                            }()}>
                            <span>Dĺžka kužeľovej abstinencie:</span>
                            <b className="ms-1">{getDays(stats.lastKuzelDate)}</b>
                        </ListGroup.Item>
                    </ListGroup>

                    {Store.getObject("adminAccess") === true && (
                        <Link to='/admin/home'>
                            <button className="btn-kuzel secondary w-100 my-1"><FontAwesomeIcon icon="fa-solid fa-gears" className="me-2" />Admin</button>
                        </Link>
                    )}

                    <Settings></Settings>
                    <ChangePassword></ChangePassword>
                    <button onClick={logout} className="btn-kuzel secondary w-100 my-1"><FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" className="me-2" />Odhlásiť sa</button>
                </Offcanvas.Body>
            </Offcanvas>

            {
                showMyConeEventsModal && (
                    <UserDetail user={Store.getObject("loggedUser")} onHideModal={() => setShowMyConeEventsModal(false)} ></UserDetail>
                )
            }
        </>
    );
}