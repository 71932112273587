import React, { useEffect, useState } from "react";
import { Offcanvas, Accordion } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function FAQcanvas({ setParentModalConfig }) {
    const [showCanvas, setShowCanvas] = useState(false);

    useEffect(() => {
        window.addEventListener("close-modals-and-canvas", () => { setShowCanvas(false) });
    }, [])

    return (
        <>
            <FontAwesomeIcon className="mainIcon" onClick={() => setShowCanvas(true)} icon="fa-solid fa-info" />

            <Offcanvas show={showCanvas} onHide={() => setShowCanvas(false)} placement="end" >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Často kladené otázky</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Accordion alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Ako nahlásiť kužeľovú udalosť ?</Accordion.Header>
                            <Accordion.Body>
                                <div><b>PC: </b> kliknutím pravým tlačidlom na myši nad mestom/obcou, v ktorom chceme nahlásiť kužeľovú udalosť.</div>
                                <div className="mt-2"><b>Dotykové zariadenie: </b> podržaním prsta nad mestom/obcou, v ktorom chceme nahlásiť kužeľovú udalosť.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Je potrebné dodržiavať pravidlá ?</Accordion.Header>
                            <Accordion.Body>
                                <span>Áno, riadime sa pravidlami, ktoré sú spísané v </span>
                                <u role="button" className="opacityHover" onClick={() => setParentModalConfig({ show: 'Kužeľová charta' })}>kužeľovej charte</u>
                                <span>. V prípade nedodržania pravidiel bude kužeľová udalosť odstránená.</span>
                            </Accordion.Body>
                        </Accordion.Item>
                        {/*<Accordion.Item eventKey="2">*/}
                        {/*    <Accordion.Header>Čo ak niekto porušuje pravidlá ?</Accordion.Header>*/}
                        {/*    <Accordion.Body>*/}
                        {/*        <span>V prípade že spozorujeme nedodržanie pravidiel, je možné to nahlásiť pomocou formulára v </span>*/}
                        {/*        <u role="button" className="opacityHover" onClick={() => setParentModalConfig({ show: 'Kužeľové udalosti' })}>kužeľových udalostiach</u>.*/}
                        {/*    </Accordion.Body>*/}
                        {/*</Accordion.Item>*/}
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Čo sú to komunity ?</Accordion.Header>
                            <Accordion.Body>
                                <span>Rád sa zabávaš so svojimi priateľmi či rodinou? Vytvorte si svoju </span>
                                <u role="button" className="opacityHover" onClick={() => setParentModalConfig({ show: 'Komunity', fullHeight: true })}>komunitu</u>
                                <span> a zdieľajte spoločne svoju vášeň a lásku ku kužeľom!</span>
                                <div className="mt-2"><b>verejná:</b> viditeľná pre všetkých kužeľníkov. Ktokoľvek môže vidieť nahlásené kužeľové udalosti a taktiež požiadať o vstup do komunity.</div>
                                <div className="mt-1"><b>uzavretá:</b> viditeľná iba pre členov danej komunity. Vstup je umožnený iba na základe pozvánky od správcu komunity.</div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Čo sú to dobrodružstvá ?</Accordion.Header>
                            <Accordion.Body>
                                <div>
                                    Dobrodružstvá slúžia na zdieľanie nášho každodenného <b>spolunažívania s kužeľmi</b>.
                                </div>
                                <div>
                                    Zo sociálnych sieti ich poznáme pod názvom <b>príbehy/storky</b>.
                                    Pri ich vytváraní <b>nie je potrebné</b> dodržiavať pravidlá <b>kužeľovej charty</b>.
                                    Dobrodružstvo bude po uverejnení dostupné po dobu <b>72 hodín</b>.
                                    Zobrazené sú v pravej časti obrazovky. Pridávame ich pomocou tlačidla <b>"+"</b>.
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>Aký je účel kužeľovej výzvy ?</Accordion.Header>
                            <Accordion.Body>
                                <span> Zabaviť sa, zasúťažiť si a zdielať navštívené miesta s priateľmi či rodinou. </span>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>Aké je hlavné motto kužeľovej výzvy ?</Accordion.Header>
                            <Accordion.Body>
                                <span> <i>„Hráme poctivo a čestne“</i> - niekedy je náročné 100%-ne preukázať dodržiavanie pravidiel, preto je dôležité riadiť sa týmto mottom :) </span>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>Som v poriadku, ak sa fotím s kužeľmi ?</Accordion.Header>
                            <Accordion.Body>
                                Samozrejme, si elita spoločnosti.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>Chceš pomôcť kužeľu napredovať ?</Accordion.Header>
                            <Accordion.Body>
                                <span> Povedz svojim priateľom o tom aký je kužeľ skvelý a prilákaj ich hrať s nami! Čím viac kužeľníkov tým väčšia zábava! </span>
                                <span>Sleduj novinky na našej </span>
                                <a href="https://www.facebook.com/profile.php?id=61555462739478" target="_blank" rel="noopener noreferrer" className="tooltip-cone-on-facebook">facebookovej fanpage</a>.
                                <div className="szh-menu__divider mt-2 mb-1"></div>
                                <span>Ak chceš, </span>
                                <a href="https://www.facebook.com/sharer/sharer.php?u=kuzel.online" target="_blank">zazdielaj nás na facebooku</a>.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                    <div className="d-flex justify-content-center mt-1">
                        <img src={require('./../../../../resources/selfie.png')} alt="komunity" className='col-8' />
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}