import React, { useState } from "react";
import CompetitionsTable from "./CompetitionsTable";
import { Tab, Tabs } from 'react-bootstrap';

export default function CompetitionsModal() {
    const [actualMonth, setActualMonth] = useState(new Date().getMonth() < 8 ? new Date().getMonth() + 1 : 8);

    return (
        <>
            <Tabs
                defaultActiveKey={actualMonth}
                id="uncontrolled-tab-example"
                className="mb-3"
                justify
            >
                <Tab eventKey="6" title="Jún">
                    <CompetitionsTable month="6"></CompetitionsTable>
                </Tab>

                <Tab eventKey="7" title="Júl">
                    <CompetitionsTable month="7"></CompetitionsTable>
                </Tab>

                <Tab eventKey="8" title="August">
                    <CompetitionsTable month="8"></CompetitionsTable>
                </Tab>
            </Tabs>
        </>
    );
}