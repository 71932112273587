import React, { useState } from "react";
import { Loading } from "../../../../common";

import { format } from "date-fns";

import { ReactComponent as KuzelLikeIcon } from "../../../../resources/kuzelLike.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ConeEventPopup({ data, image }) {
    const [likes, setLikes] = useState(data.likes ?? { summary: { like: 0 }, likeByMe: null });

    const getLikeCountByType = (type) => {
        let count = likes?.summary[type];
        if (count && count > 0) return count;
        return 0;
    }

    return (
        <div className="kuzel-popup">
            {
                image ? (
                    <>
                        <img
                            src={image}
                            alt={data.city + " kuzel"}
                            style={{ width: '100%', aspectRatio: data.orientation === 'PORTRAIT' ? 3 / 4 : 4 / 3 }}
                        />
                    </>
                ) : (
                    <div className="text-center" style={{ width: '100%', aspectRatio: data.orientation === 'PORTRAIT' ? 3 / 4 : 4 / 3, backgroundColor: '#fff' }}>
                        <div className="d-inline-block" style={{ marginTop: data.orientation === 'PORTRAIT' ? 'calc(50% + 50px)' : 'calc(50% - 60px)' }}>
                            <Loading marginTop='0px'></Loading>
                        </div>
                    </div>
                )
            }

            <div className="kuzel-popup-info">
                <div className="position-absolute w-100 m-0" style={{ top: 'calc(-0.7rem - 21px)' }}>
                    <div className="justify-content-between" style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, .5)', padding: '5px 10px 0' }}>
                        <span>
                            <span id="likeButton" className="opacityHover" style={{ fontSize: '1.2rem' }} role="button">
                                {getLikeCountByType("LIKE")}
                                <KuzelLikeIcon className={`opacityHover mb-1 ${likes.likeByMe === 'LIKE' ? " kuzelLikeActive" : ""} `} style={{ height: '1.4rem' }} />
                            </span>

                            <span id="commentsButton" className="opacityHover ms-2" style={{ fontSize: '1.2rem' }} role="button">
                                {data.commentCount}
                                <FontAwesomeIcon icon="fa-regular fa-comments" className="ms-1" style={{ height: '1.2rem' }} />
                            </span>
                        </span>

                        <span className="fs-6 mb-1">
                            {data.created ? format(new Date(data.created), 'dd.MM.yyyy') : ""}
                        </span>
                    </div>
                </div>

                <div className="kuzel-popup-overflow-effect"></div>
                <div className="kuzel-popup-overflow"></div>
                <div className="w-100 px-2"><b>{(data.city ?? '\u00A0') + (data.country ? ", " + data.country?.toUpperCase() : "")}</b></div>
                <hr style={{ margin: "9px 0" }} />
                <div className="w-100 px-2">
                    {
                        data.kuzelniks ? (
                            data.kuzelniks.map((kuzelnik, index) => {
                                return (
                                    <span key={index} className="me-1">{kuzelnik.nickname}{index + 1 !== data.kuzelniks?.length ? ',' : ''}</span>
                                )
                            })
                        ) : (
                            <div>{'\u00A0'}</div>
                        )
                    }
                </div>
                {
                    data.description && (
                        <>
                            <hr style={{ margin: "9px 0" }} />
                            <div className="w-100 px-1">„{data.description}“</div>
                        </>
                    )
                }
            </div>
        </div>
    )
}