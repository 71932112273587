import React from "react";
import { Spinner } from 'react-bootstrap';

export default function KuzelLoading({ loadingType = "cone", marginTop = '50px', marginBottom = '50px', text = "Načítavam" }) {
    return (
        <>
            {
                loadingType === "cone" && (
                    <div className="animate-flicker" style={{ marginTop: marginTop, marginBottom: marginBottom }}>
                        <div className="d-flex justify-content-center w-100">
                            <img src="cone.png" alt="" className="animate-flicker-kuzel" style={{ height: '50px', width: '50px' }} />
                        </div>
                        <div className="d-flex justify-content-center w-100 mt-1 color-kuzel">
                            {text}...
                        </div>
                    </div>
                )
            }
            {
                loadingType === "fullscreenCone" && (
                    <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: '1000', backgroundColor: 'rgb(255,255,255, 0.8)' }}>
                        <div className="animate-flicker" style={{ marginTop: marginTop, marginBottom: marginBottom }}>
                            <div className="d-flex justify-content-center w-100">
                                <img src="cone.png" alt="" className="animate-flicker-kuzel" style={{ height: '50px', width: '50px' }} />
                            </div>
                            <div className="d-flex justify-content-center w-100 mt-1 color-kuzel">
                                {text}...
                            </div>
                        </div>
                    </div>
                )
            }
            {
                loadingType === "spinner" && (
                    <div className="text-center align-middle" style={{ marginTop: marginTop, marginBottom: marginBottom }}>
                        <Spinner animation="border" style={{ width: '40px', height: '40px' }} size="sm" />
                    </div>
                )
            }
            {
                loadingType === "fullscreenSpinner" && (
                    <div className="body">
                        <div className="loginBody"></div>
                        <div className="grad"></div>
                        <div className="text-center align-middle" style={{ position: 'absolute', top: 'calc(50% - 35px)', left: 'calc(50% - 35px)' }}>
                            <Spinner animation="border" style={{ width: '90px', height: '90px', color: '#fff' }} size="lg" />
                            <div className="mt-1">
                                {text}...
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}