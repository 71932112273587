import React, { useEffect, useState } from "react";
import { Table, ListGroup, Modal } from 'react-bootstrap';
import { Loading, Store, ModalContent, Pagination } from "../../../../../../common";

import { format } from 'date-fns';

import { url } from "../../../../../../config";
const axios = require("axios").default;

export default function PublicGroupsDetail({ windowConfig, onCloseDetail }) {
    const [kuzelniksInGroup, setKuzelniksInGroup] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    let [modalConfig, setModalConfig] = useState({ show: false });

    const [detailData, setDetailData] = useState(windowConfig.groupData);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadKuzelniks(0);
    }, [])

    const loadKuzelniks = (page) => {
        axios.get(url + `/private/rest/kuzel-group/members/${windowConfig?.groupData.id}?page=${page}&size=10`)
            .then(res => {
                setKuzelniksInGroup(res.data);
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
            });
    }

    const requestRequestModal = (event) => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <>
                <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš požiadať o pridanie do komunity ? </b></div>
            </>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => requestRequest()}>Áno</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const requestRequest = () => {
        let groupId = windowConfig?.groupData.id;

        axios.post(url + "/private/rest/kuzel-group/request/request/" + groupId)
            .then(res => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <>
                        <div className="text-center" style={{ fontSize: '15px' }}><b>Požiadanie o pridanie do komunity úspešne odoslané !</b></div>
                    </>,
                    footerContent: <>
                        <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                    </>
                });
            })
            .catch(err => {
                console.log(err)
            });
    }

    const showMap = () => {
        window.dispatchEvent(new CustomEvent("close-modals-and-canvas"));
        window.dispatchEvent(new CustomEvent("map-set-view", {}));

        let activeGroup = Store.getObject("selectedGroup").id;
        let newGroup = detailData.id;

        if (activeGroup !== newGroup) {
            window.dispatchEvent(new CustomEvent("change-group-from-outside", {
                'detail': {
                    groupId: detailData.id
                }
            }));
        }
    }

    return (
        <>
            <Modal
                animation={false}
                show={true}
                size='xl'
                fullscreen="md-down"
                scrollable="false"
                onHide={() => onCloseDetail()}
                className='fullHeight'
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100 color-kuzel">
                        {detailData.name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <ListGroup className="col-12 col-lg-6">
                            <ListGroup.Item>Popis: <b> {detailData.description}</b></ListGroup.Item>
                            <ListGroup.Item>Počet kužeľníkov: <b> {detailData.membersCount}</b></ListGroup.Item>
                            <ListGroup.Item>Počet kužeľových udalostí: <b> {detailData.kuzelCount}</b></ListGroup.Item>
                            <ListGroup.Item>Posledná kužeľová udalosť: <b> {detailData.lastKuzelDate ? format(new Date(detailData.lastKuzelDate), 'dd.MM.yyyy') : ""}</b></ListGroup.Item>
                        </ListGroup>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-lg-6">
                            <button className="btn-kuzel secondary my-2 w-100" onClick={() => requestRequestModal()}>Požiadať o pridanie do komunity</button>
                            <button className="btn-kuzel secondary mb-2 w-100" onClick={() => showMap()}>Zobraziť mapu</button>
                        </div>
                    </div>

                    <Table>
                        <thead>
                            <tr>
                                <th className="col-1"></th>
                                <th className="col-4">Kužeľník</th>
                                <th className="col-4">Počet udalostí</th>
                                <th className="col-5">Posledná udalosť</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? (
                                    <tr className="text-center">
                                        <td colSpan="100%" className="border-0">
                                            <Loading marginTop='80px'></Loading>
                                        </td>
                                    </tr>
                                ) : (
                                    kuzelniksInGroup.data.map((kuzelnik, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1 + kuzelniksInGroup.page * 10}.</td>
                                                <td>{kuzelnik.nickname}</td>
                                                <td>{kuzelnik.kuzelCount}</td>
                                                <td>{kuzelnik.lastKuzel ? format(new Date(kuzelnik.lastKuzel), 'dd.MM.yyyy') : ""}</td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    </Table>

                    <div className="d-flex justify-content-center">
                        <Pagination page={kuzelniksInGroup.page} totalPages={kuzelniksInGroup.totalPage} onPageChange={page => loadKuzelniks(page)} />
                    </div>

                </Modal.Body>
            </Modal>
            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}