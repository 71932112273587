import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { url } from "../../../config";
const axios = require("axios").default;

export default function ResetPasswordRequest() {
    const navigate = useNavigate();

    const [resetPasswordData, setResetPasswordData] = useState({
        email: "",
        errorMessage: ""
    });

    const resetPassword = () => {
        axios
            .post(url + `/public/rest/reset-password`, {value: resetPasswordData.email})
            .then((res) => {
                if (res.data.success)
                    navigate("/reset-password-request-success");
            })
            .catch((err) => {
                setResetPasswordData({ ...resetPasswordData, errorMessage: err.response.data.message })
            });
    }

    return (
        <>
            <input
                type="text"
                placeholder="email"
                name="email"
                onChange={(e) => setResetPasswordData({
                    ...resetPasswordData,
                    email: e.target.value,
                    errorMessage: ""
                })}
            />
            <input type="button" value="Obnovenie hesla" onClick={resetPassword} />

            <div className="color-kuzel mt-2">Späť na <Link to='/'>prihlásenie</Link></div>
        </>
    );
}