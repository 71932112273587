import TURECKE_MESTA from "../../resources/enums/TurkeyHelper";
import SLOVINSKE_MESTA from "../../resources/enums/SloveniaHelper";

export function determineCity(address) {
    if (address.country_code === 'gb') {
        if (address.city === 'Birmingham' || address.city === 'Lancaster') {
            return address.city;
        }
        return address.town ||
            address.village ||
            address.city;
    } else if (address.country_code === 'tr') {
        if (address.city === 'Kayseri' || address.city === 'Tekirdağ' ||
            address.city === 'Tokat' || address.city === 'Trabzon' || address.city === 'Yalova Merkez') {
            return address.city;
        } else if (address.village) {
            return address.village;
        } else if (address.province) {
            if (TURECKE_MESTA[address.province] !== undefined &&
                TURECKE_MESTA[address.province].find((district) => district === address.town ||
                    district === address.city || district === address.village)) {
                return address.province
            } else if (address.city && TURECKE_MESTA[address.province] !== undefined && address.city.includes('Mahallesi')) {
                return address.province
            } else return address.village || address.town || address.city || address.province
        } else {
            return address.town
        }
    } else if (address.country_code === 'gr') {
        if (address.city && (address.city.includes('Municipal Unit of') ||
            address.city.includes(' Municipal Unit') ||
            address.city.includes('M unicipal Unit of ') ||
            address.city.includes('Municipality of ') ||
            address.city.includes('Municipa Unit of '))) {
            let city = address.town ||
                address.village ||
                address.hamlet ||
                address.city_district ||
                address.city;
            if (city.includes(' District of ') || city.includes(' Community of ')) {
                city = address.city;
            }
            return city.replace('Community of ', '')
                .replace(' Municipal Unit', '')
                .replace('Municipal Unit of', '')
                .replace(' Community', '')
                .replace('Municipality of ', '');
        } else {
            let city = address.town ||
                address.city ||
                address.village ||
                address.municipality ||
                address.suburb;
            if (city) {
                return city.replace('Municipal Unit of ', '').replace('Municipality of ', '').replace(' Municipal Unit', '');
            } else return null;
        }
    } else if (address.country_code === 'si') {
        if (SLOVINSKE_MESTA[address.town] !== undefined &&
            SLOVINSKE_MESTA[address.town].find((road) => road === address.road || road === address.village)) {
                if (address.town === 'Kočevje' && address.village === 'Šalka vas' && address.road === 'Šalka vas') {
                    return address.village;
                } else if (address.town === 'Murska Sobota' && address.village === 'Rakičan' && 
                    ["Cvetkova ulica", "Ulica Štefana Kovača", "Cankarjeva ulica", "Prešernova ulica", "Vrtna ulica", "Tomšičeva ulica", "Partizanska ulica", "Panonska ulica", "Mladinska ulica", "Ulica dr. Vrbnjaka"].indexOf(address.road) > -1) {
                    return address.village;
                } else if (address.town === 'Izola / Isola' && address.village === 'Jagodje' && 
                    ["Prešernova cesta"].indexOf(address.road) > -1 && address.suburb !== 'Izola / Isola (naselje)') {
                    return address.village;
                } else if (address.town === 'Bovec' && address.road === 'Dvor') {
                    return address.town;
                } else if (address.hamlet && address.road === address.hamlet) {
                    return address.hamlet;
                }
                return address.town;
        } else if (SLOVINSKE_MESTA[address.municipality] !== undefined &&
            SLOVINSKE_MESTA[address.municipality].find((road) => road === address.road )) {
                if (address.municipality && address.municipality === 'Lenart') {
                    return 'Lenart v Slovenskih goricah';
                } 
                return address.municipality;
        } else if (address.town === 'Kamnik' && address.village === 'Nevlje' && address.suburb === 'Mekinje' &&
            ["Cesta treh talcev", "Pot na Jeranovo", "Cesta dr. T. Zajca", "Polčeva pot", "Molkova pot", "Cankarjeva cesta", "Neveljska pot", "Drnovškova pot"].indexOf(address.road) > -1) {
                return address.suburb;
        } else if (address.town === 'Šoštanj' && address.hamlet === 'Florjan' && !address.road) {
            return address.town;
        } else if (address.hamlet && address.road === address.hamlet) {
            return address.hamlet;
        } else if (address.hamlet && !address.village) {
            return address.hamlet;
        } else {
            return address.city || 
            address.village || 
            address.town;
        }
    } else if (address.country_code === 'sk') {
        return address.village ||
            address.town ||
            address.city ||
            address.suburb;
    } else {
        return address.city ||
            address.town ||
            address.village ||
            address.suburb;
    }
};

export function normalizeCity(city) {
    if (city !== null && city !== undefined) {
        return city.replace('/', '-');
    } else return null;
};