import React, { useEffect, useState } from "react";
import { Modal, Carousel } from 'react-bootstrap';
import { ModalContent, Loading, GlobalFunctions } from "../../../../common";

import { defaultWidth } from "../../../../common/utils/ImageConstants";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function StoriesDetail({ index, onHideStoriesDetail, onSeenStoryChange }) {
    let [loading, setLoading] = useState(true);
    let [stories, setStories] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    let [modalConfig, setModalConfig] = useState({ show: false });

    let [numberOfStories, setNumberOfStories] = useState(0);

    let [showStoriesIndex, setShowStoriesIndex] = useState(index);

    useEffect(() => {
        loadData(0);
    }, [])

    const loadData = (page, reload = false) => {
        let baseUrl = `/private/rest/story/newest${reload > 0 && !reload ? '/' + reload : ''}`;

        axios.get(url + baseUrl + "?page=" + page + "&size=10000") //viem, naschvál šialené číslo lebo nemame doriešene donačítavanie
            .then(res => {
                let _stories = { ...res.data, data: [...stories.data, ...res.data.data] };
                setStories(_stories);

                setNumberOfStories(res.data.totalElements);

                setLoading(false);

                if (!reload) {
                    onCarouselChangeHandler(showStoriesIndex, _stories);
                }
            })
            .catch(err => {
                console.log(err)
            });
    }

    const onCarouselChangeHandler = (index, _stories = { data: [] }) => {
        let storiesData = (_stories.data.length > 0) ? _stories : stories;
        setShowStoriesIndex(index);

        let entry = storiesData.data[index - 1];

        if (!entry.seen) {
            axios
                .put(url + `/private/rest/story/${entry.id}/seen`)
                .then((res) => {
                    entry.seen = true;
                    onSeenStoryChange(entry);
                })
                .catch((err) => { })
        }
    }

    const getWidth = (e) => {
        let createDate = stories.data[showStoriesIndex - 1]?.created;
        let hours = GlobalFunctions.getDiffHours(createDate);

        let percentageWidth = hours / 0.72;

        return percentageWidth;
    }

    return (
        <>
            <Modal
                show={true}
                size='md'
                fullscreen="sm-down"
                className="fullHeight"
                scrollable="false"
                onHide={() => onHideStoriesDetail()}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center w-100 color-kuzel h5">
                        Kužeľové dobrodružstvá
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row justify-content-center">
                    {
                        loading ? (
                            <Loading loadingType="fullscreenCone" marginTop="160px"></Loading>
                        ) : (
                            <div className="col-12 col-md-10">
                                <div className="w-100 bg-kuzel">
                                    <div className="bg-dark" style={{ height: '5px', marginBottom: '4px', width: getWidth() + '%' }}>
                                    </div>
                                </div>
                                <Carousel
                                    showThumbs={false}
                                    showIndicators={false}
                                    showCarousel={false}
                                    useKeyboardArrows={true}
                                    autoFocus={true}
                                    emulateTouch={true}
                                    transitionTime={300}
                                    activeIndex={showStoriesIndex - 1}
                                    onSelect={(e) => onCarouselChangeHandler(e + 1)}
                                >
                                    {
                                        Array(numberOfStories).fill(null).map((number, index) => {
                                            return (
                                                <Carousel.Item key={index + 1} className="bg-dark" style={{ aspectRatio: 3 / 4 }}>
                                                    <div className="d-flex align-items-center" style={{ height: "100%" }}>
                                                        <img
                                                            src={url + stories.data[index]?.photo + (defaultWidth > 0 ? `?width=${defaultWidth}` : "")}
                                                            alt="" />
                                                    </div>
                                                    <Carousel.Caption>
                                                        <h3>{stories.data[index]?.creator?.nickname ?? ''}</h3>
                                                        {
                                                            stories.data[index]?.description && (
                                                                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5) ', padding: '3px 9px' }}>
                                                                    {stories.data[index].description ?? ''}
                                                                </div>
                                                            )
                                                        }
                                                    </Carousel.Caption>
                                                </Carousel.Item>
                                            )
                                        })
                                    }
                                </Carousel>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })}></ModalContent>
        </>
    );
}