const cache = { data: {} }
const listeners = { data: {} }
export default class Store {
    static getValue(key) {
        if (key in cache.data) {
            return cache.data[key]
        } else {
            let val = localStorage.getItem(key);
            cache.data[key] = val;
            return val;
        }
    }

    static setValue(key, value) {
        localStorage.setItem(key, value);
        cache.data[key] = value;

        if (listeners.data[key]) {
            listeners.data[key].forEach(callback => callback(value))
        }
    }

    static setValueOnlyToCache(key, value) {
        cache.data[key] = value;
    }
    static getObject(key) {
        if (key in cache.data) {
            return cache.data[key]
        } else {
            let val = localStorage.getItem(key);
            if (val && val !== '') {
                let obj = JSON.parse(val);
                cache.data[key] = obj;
                return obj;
            } else {
                return {};
            }
        }
    }

    static setObject(key, obj) {
        localStorage.setItem(key, JSON.stringify(obj));
        cache.data[key] = obj;

        if (listeners.data[key]) {
            listeners.data[key].forEach(callback => callback(obj))
        }
    }

    static addListener(key, callback) {
        if (key in listeners.data) {
            if (!listeners.data[key].includes(callback)) {
                listeners.data[key].push(callback);
            }
        } else {
            listeners.data[key] = [callback];
        }
    }
    static removeListener(key, callback) {
        if (key in listeners.data) {
            const index = listeners.data[key].indexOf(callback);
            if (index > -1) {
                listeners.data[key].splice(index, 1);
            }
        }
    }
}