const mesta = {
    "Balıkesir": [
        "Altıeylül",
        "Karesi",
        "Üçpınar"
    ],
    "Bilecik": [
    ],
    "Bingöl": [
    ],
    "Bitlis": [
    ],
    "Bolu": [
    ],
    "Burdur": [
    ],
    "Bursa": [
        "Yıldırım",
        "Osmangazi"
    ],
    "Canakkale": [
        "Çan",
        "Kepez Beldesi"
    ],
    "Çankırı": [
    ],
    "Çorum": [  
    ],
    "Denizli": [
        "Merkezefendi",
        "Pamukkale"
    ],
    "Diyarbakır": [
        "Yenişehir",
        "Kayapınar",
        "Bağlar",
        "Sur"
    ],
    "Edirne": [
    ],
    "Elazığ": [
    ],
    "Erzincan": [
    ],
    "Erzurum": [
        "Palandöken",
        "Yakutiye"
    ],
    "Eskişehir": [
        "Tepebaşı",
        "Odunpazarı"
    ],
    "Gaziantep": [
        "Şahinbey",
        "Şehitkamil"
    ],
    "Giresun": [
        "Bayazıt",
        "Giresun District"
    ],
    "Gümüşhane": [
    ],
    "Hakkâri": [
    ],
    "Hatay Province": [
    ],
    "Isparta": [
    ],
    "Mersin": [
        "Akdeniz",
        "Toroslar",
        "Karaisalı",
        "Karaisalı",
        "Yenişehir"
    ],
    "Istanbul": [
        "Fatih",
        "Beşiktaş",
        "Zeytinburnu",
        "Güngören",
        "Bahçelievler",
        "Ataşehir",
        "Şişli",
        "Kâğıthane",
        "Beykoz",
        "Eyüpsultan",
        "Üsküdar",
        "Kadıköy",
        "Ümraniye",
        "Beylikdüzü",
        "Esenyurt",
        "Avcılar",
        "Başakşehir",
        "Bağcılar",
        "Küçükçekmece",
        "Esenler",
        "Bakırköy",
        "Bayrampaşa",
        "Gaziosmanpaşa",
        "Sultangazi",
        "Beyoğlu",
        "Sarıyer",
        "Çekmeköy",
        "Sancaktepe",
        "Pendik",
        "Kartal",
        "Maltepe"
    ],
    "Izmir": [
        "Güzelbahçe",
        "Narlıdere",
        "Balçova",
        "Konak",
        "Karabağlar",
        "Buca",
        "Bayraklı",
        "Bornova",
        "Karşıyaka",
        "Çiğli"
    ],
    "Kars": [
    ],
    "Kastamonu": [
    ],
    "Kayseri": [
        "Melikgazi",
        "Kocasinan",
        "Talas"
    ],
    "Kırklareli": [
    ],
    "Kırşehir": [
    ],
    "Kocaeli": [
        "İzmit",
        "Gölcük",
        "Başiskele",
        "Körfez",
        "Derince",
        "Kartepe"
    ],
    "Konya": [
        "Meram",
        "Karatay",
        "Selçuklu"
    ],
    "Kütahya": [
    ],
    "Malatya": [
        "Battalgazi",
        "Yeşilyurt"
    ],
    "Manisa": [
        "Yunusemre",
        "Emlakdere"
    ],
    "Kahramanmaraş": [
        "Onikişubat",
        "Dulkadiroğlu"
    ],
    "Mardin Province": [
        "Artuklu"
    ],
    "Muğla": [
    ],
    "Muş": [
    ],
    "Nevşehir": [
    ],
    "Niğde": [
    ],
    "Ordu": [
        "Altınordu"
    ],
    "Rize": [
    ],
    "Sakarya": [
        "Erenler",
        "Serdivan"
    ],
    "Samsun": [
        "İlkadım",
        "Canik",
        "Atakum"
    ],
    "Siirt": [
    ],
    "Sinop": [
    ],
    "Sivas": [
        "Sivas Belediyesi"
    ],
    "Tekirdağ": [
    ],
    "Tokat": [
    ],
    "Trabzon": [
    ],
    "Tunceli": [
    ],
    "Şanlıurfa": [
        "Karaköprü",
        "Haliliye",
        "Eyyübiye"
    ],
    "Uşak": [
    ],
    "Van": [
        "Tuşba",
        "İpekyolu",
        "Edremit"
    ],
    "Yozgat": [
    ],
    "Zonguldak": [
    ],
    "Aksaray": [
    ],
    "Bayburt": [
    ],
    "Karaman": [
    ],
    "Kırıkkale": [
    ],
    "Batman": [
    ],
    "Şırnak": [
    ],
    "Bartın": [
    ],
    "Ardahan": [
    ],
    "Iğdır": [
    ],
    "Yalova": [
    ],
    "Karabük": [
    ],
    "Kilis": [
    ],
    "Osmaniye": [
        "Karacalar",
        "Dereobası"
    ],
    "Düzce": [
    ],
    "Adana": [
        "Seyhan",
        "Çukurova",
        "Yüreğir",
        "Sarıçam"
    ],
    "Adıyaman": [
    ],
    "Afyonkarahisar": [
    ],
    "Ağrı": [
    ],
    "Amasya": [
    ],
    "Ankara": [
        "Etimesgut",
        "Yenimahalle",
        "Keçiören",
        "Mamak",
        "Çankaya",
        "Altındağ",
        "Sincan"
    ],
    "Antalya": [
        "Muratpaşa",
        "Konyaaltı",
        "Kepez"
    ],
    "Artvin": [
    ],
    "Aydın": [
        "Efeler",
        "Tepecik"
    ]
}

export default mesta;