import React, { useEffect, useState } from "react";
import { Modal, Image } from 'react-bootstrap';

import { format } from "date-fns";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Tooltip } from 'react-tooltip'

import UserDetail from "./UserDetail";
import ConeComment from "./ConeComment";
import ImageClaim from "./ImageClaim";
import Store from "./utils/Store";
import Loading from './utils/Loading';
import ConeComponentsService from "./utils/ConeComponentsService";
import ModalContent from "./utils/ModalContent";

import { ReactComponent as KuzelLikeIcon } from "../resources/kuzelLike.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { url } from "../config";
import {defaultWidth} from "./utils/ImageConstants";
const axios = require("axios").default;

export default function ConeImageDetail({ onHideModal, detailData }) {
    const [modalConfig, setModalConfig] = useState({ show: false });
    const [image, setImage] = useState(null);
    const [userDetailId, setUserDetailId] = useState(null);
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [likes, setLikes] = useState({ summary: { like: 0 }, likeByMe: null });

    useEffect(() => {
        window.addEventListener("close-modals-and-canvas", (e) => { onHideModal() });
    }, [])

    useEffect(() => {
        axios.get(url + detailData.photo + (defaultWidth > 0 ? `?width=${defaultWidth}` : ""), { responseType: "blob" })
            .then(function (res) {
                var reader = new window.FileReader();
                reader.readAsDataURL(res.data);
                reader.onload = function () {
                    var imageDataUrl = reader.result;
                    setImage(imageDataUrl);
                }
            })
            .catch((err) => { });

    }, [detailData.photo])

    useEffect(() => {
        if (detailData.id) {
            ConeComponentsService.loadLikes(detailData.id, setLikes);
            ConeComponentsService.loadComments(detailData.id, setComments);
        }
    }, [detailData.id])

    const showConeEventOnMap = () => {
        window.dispatchEvent(new CustomEvent("close-modals-and-canvas"));
        window.dispatchEvent(new CustomEvent("map-set-view", { 'detail': detailData }));

        let activeGroup = Store.getObject("selectedGroup").id;
        let newGroup = detailData.groupId;

        //info o newGroup prichádza iba po kliku na notifikaciu, pri ostatnych zdrojoch detailu nieje potrebne prehadzovať komunitu (preto newGroup === undefined)
        if (newGroup === undefined || activeGroup === newGroup) {
            window.dispatchEvent(new CustomEvent("open-cone-event-popup", { 'detail': detailData }));
        } else {
            window.dispatchEvent(new CustomEvent("change-group-from-outside", { 'detail': detailData }));
            //timeout aby sa stihli načitat kuželove udalosti po zmene komunity a až potom otvorilo tu správnu udalost 
            //ked to za 0.6 sekundy nestihne tak ti tu udalost neotvori - so sad
            //ked prídem na legitímnejšie riešenie tak to prerobím
            setTimeout(() => {
                window.dispatchEvent(new CustomEvent("open-cone-event-popup", { 'detail': detailData }));
            }, "600");
        }
    }

    const likeClickHandler = () => {
        setLikes({ ...likes, likeByMe: !likes.likeByMe })
        ConeComponentsService.addUpdateOrRemoveLike(detailData.id, "LIKE", setLikes)
    }

    const addComment = () => {
        ConeComponentsService.addComment(detailData.id, comment, setComments)
        setComment("");
    }

    const deleteComment = (comment) => {
        ConeComponentsService.deleteComment(comment.id, detailData.id, setComments)
        setModalConfig({ show: false });
    }

    const deleteCommentModal = (comment) => {
        setModalConfig({
            show: true,
            headerContent: null,
            bodyContent: <>
                <div className="text-center" style={{ fontSize: '15px' }}><b>Naozaj chceš vymazať komentár ? </b></div>
            </>,
            footerContent: <>
                <button className="btn-kuzel" onClick={() => deleteComment(comment)}>Vymazať</button>
                <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
            </>
        });
    }

    const getLikeCountByType = (type) => {
        let count = likes?.summary[type];
        if (count && count > 0) return count;
        return 0;
    }

    return (
        <Modal
            animation={false}
            scrollable="false"
            show={true}
            size='md'
            fullscreen="md-down"
            onHide={() => onHideModal({ ...detailData, likes: likes, comments: comments })}
            className='fullHeight'
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100 color-kuzel">
                    {(detailData.city ?? '\u00A0') + (detailData.country ? ", " + detailData.country?.toUpperCase() + ' - ' + format(new Date(detailData.created), 'dd.MM.yyyy') : "")}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-2">
                {
                    (image && detailData.orientation) ? (
                        <>
                            {
                                detailData.description && (
                                    <>
                                        <div className="mx-2 my-1">
                                            <i>„{detailData.description}“</i>
                                        </div>
                                    </>
                                )
                            }

                            <TransformWrapper>
                                <TransformComponent>
                                    <Image
                                        src={image}
                                        style={{ height: detailData.orientation === 'PORTRAIT' ? '420px' : 'unset', width: detailData.orientation === 'PORTRAIT' ? 'unset' : '100%', aspectRatio: detailData.orientation === 'PORTRAIT' ? 3 / 4 : 4 / 3 }}
                                        alt={detailData.city + " kuzel"}
                                        thumbnail
                                    />
                                </TransformComponent>
                            </TransformWrapper>

                            <div>
                                <div className="text-center mt-1 mb-2">
                                    {
                                        detailData.kuzelniks && (
                                            detailData.kuzelniks.map((kuzelnik, index) => {
                                                return (
                                                    <>
                                                        <button key={index} className="btn-kuzel my-1 me-1" onClick={() => setUserDetailId(kuzelnik.id)}>
                                                            {kuzelnik.nickname}
                                                        </button>
                                                        {
                                                            userDetailId === kuzelnik.id && (
                                                                <UserDetail user={kuzelnik} onHideModal={() => setUserDetailId(null)} ></UserDetail>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        )
                                    }
                                </div>

                                <div className="coneEventDetailPanelIcons d-flex justify-content-between pt-1 px-2">
                                    <div className="d-flex">
                                        <FontAwesomeIcon onClick={() => showConeEventOnMap()} icon="fa-solid fa-map-location-dot" className="me-2 opacityHover tooltip-show-on-map" />
                                        <Tooltip anchorSelect=".tooltip-show-on-map" place="top">
                                            Zobraziť na mape
                                        </Tooltip>

                                        <KuzelLikeIcon onClick={() => likeClickHandler()} className={`me-1 opacityHover tooltip-like ${likes.likeByMe === 'LIKE' ? " kuzelLikeActive" : ""} `} />
                                        <i>
                                            <em className="align-middle">{getLikeCountByType("LIKE")} Kuželí sa mi to</em>
                                        </i>
                                    </div>

                                    <div className="d-flex">
                                        {/*<ImageClaim imageData={detailData}></ImageClaim>*/}
                                    </div>
                                </div>

                                <div className="szh-menu__divider m-0 mt-2 mb-3"></div>

                                <div className="text-end mt-2 mb-1 w-100 d-flex">
                                    <input name="comment"
                                        onChange={e => setComment(e.currentTarget.value)}
                                        value={comment}
                                        placeholder={comments.length === 0 ? "Pridať prvý komentár..." : "Pridať komentár..."}
                                        style={{ outline: 'none' }}
                                        className="border-0 col-10 px-2 pb-1">
                                    </input>
                                    <span className="col-2 text-center opacityHover" role="button" onClick={addComment}>Uverejniť</span>
                                </div>

                                <div className="szh-menu__divider m-0 mb-3"></div>

                                {
                                    comments.length > 0 && (
                                        <div className="mb-5">
                                            {
                                                comments.map((comment, index) => <ConeComment index={index} key={index} data={comment} deleteComment={(data) => deleteCommentModal(data)} />)
                                            }
                                        </div>
                                    )
                                }
                            </div>

                            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
                        </>
                    ) : (
                        <Loading marginTop='170px'></Loading>
                    )
                }
            </Modal.Body>
        </Modal>
    );
}