import React from "react";
import { Pagination } from 'react-bootstrap';

export default function KuzelPagination({ page, totalPages, onPageChange }) {
    return (
        <Pagination className="kuzel-pagination">
            {totalPages <= 10 ?
                <SimplePagination page={page} totalPages={totalPages} onPageChange={onPageChange} />
                :
                <AdvancedPagination page={page} totalPages={totalPages} onPageChange={onPageChange} />
            }
        </Pagination>
    )
}

const SimplePagination = ({ page, totalPages, onPageChange }) => {
    let pages = [];
    for (let number = 0; number <= totalPages - 1; number++) {
        pages.push(
            <Pagination.Item onClick={() => (number !== page) ? onPageChange(number) : ''} key={number} active={number === page}>
                {number + 1}
            </Pagination.Item>,
        );
    }

    return pages;
}

const AdvancedPagination = ({ page, totalPages, onPageChange }) => {
    let pages = [];

    if (page > 0) pages.push(<Pagination.First onClick={() => onPageChange(0)} />)
    if (page > 0) pages.push(<Pagination.Prev onClick={() => onPageChange(page - 1)} />)
    if (page > 2) pages.push(<Pagination.Item onClick={() => onPageChange(0)}>{1}</Pagination.Item>)

    if (page - 3 > 1) pages.push(<Pagination.Ellipsis />);

    let start = page - 2;
    if (start < 0) start = 0;

    let end = page + 2;
    if (end > totalPages) end = totalPages;

    for (let number = start; number <= end - 1; number++) {
        pages.push(
            <Pagination.Item onClick={() => (number !== page) ? onPageChange(number) : ''} key={number} active={number === page}>
                {number + 1}
            </Pagination.Item>,
        );
    }

    if (page + 3 < totalPages - 1) pages.push(<Pagination.Ellipsis />);

    if (page < totalPages - 3) pages.push(<Pagination.Item onClick={() => onPageChange(totalPages - 1)}>{totalPages}</Pagination.Item>);
    if (page < totalPages - 1) pages.push(<Pagination.Next onClick={() => onPageChange(page + 1)} />);
    if (page < totalPages - 1) pages.push(<Pagination.Last onClick={() => onPageChange(totalPages - 1)} />);

    return pages;
}