import React, { useEffect, useState } from "react";
import { Table } from 'react-bootstrap';
import { Store, Loading, UserDetail, Pagination } from "../../../../common";

import { format } from 'date-fns';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { url } from "../../../../config";
const axios = require("axios").default;

export default function RankingModal() {
    const [data, setData] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });
    const [loading, setLoading] = useState(true);
    const [userDetailId, setUserDetailId] = useState(null);

    useEffect(() => {
        loadData(0);
    }, [])

    const loadData = (page) => {
        let groupId = Store.getObject("selectedGroup").id;
        let baseUrl = `/private/rest/kuzelnik/top${groupId ? `/by-group/${groupId}` : ''}`

        axios.get(url + baseUrl + "?page=" + page + "&size=10")
            .then(res => {
                setData(res.data);
                setLoading(false);
            })
            .catch(err => { });
    }

    return (
        <>
            <Table>
                <thead>
                    <tr className="align-middle">
                        <th className="col-1"></th>
                        <th className="col-4">Kužeľník</th>
                        <th className="col-4">Počet udalostí</th>
                        <th className="col-5">Posledná udalosť</th>
                        <th className="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='170px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            data.data.map((user, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1 + data.page * 10}.</td>
                                        <td>{user.nickname }</td>
                                        <td>{user.count}</td>
                                        <td>{format(new Date(user.date), 'dd.MM.yyyy')}</td>
                                        <td>
                                            <FontAwesomeIcon onClick={() => setUserDetailId(user.id)} size="lg" className="me-1" icon="fa-regular fa-user" />
                                            {
                                                userDetailId === user.id && (
                                                    <UserDetail user={user} onHideModal={() => setUserDetailId(null)} ></UserDetail>
                                                )
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>
            <div className="d-flex justify-content-center">
                <Pagination page={data.page} totalPages={data.totalPage} onPageChange={page => loadData(page)} />
            </div>
        </>
    );
}