import React, { useEffect, useState } from "react";
import { Store, Loading } from "../../common";

import '../../resources/css/Admin.css';
import { Outlet } from "react-router-dom";
import Sidebar from './Sidebar';

import { url } from "../../config";
const axios = require("axios").default;

export default function Admin() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(url + "/private/rest/admin/role/grants")
            .then(res => {
                Store.setValueOnlyToCache("role-accesses", res.data.access)
                Store.setValueOnlyToCache("role-permissions", res.data.permission)
                setLoading(false);
            })
            .catch(err => console.log(err));
    })

    return (
        loading ?
            <Loading loadingType='fullscreenSpinner'></Loading>
            :
            <div className="admin">
                <Sidebar></Sidebar>

                <div className="mainContainer w-100 p-2">
                    <Outlet />
                </div>
            </div>
    );
}