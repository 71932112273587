import React, { useEffect, useState } from "react";
import { Offcanvas } from 'react-bootstrap';
import { Store } from "../../../../common";

export default function WelcomeCanvas({ setParentModalConfig }) {
    const [showCanvas, setShowCanvas] = useState(Store.getObject("loggedUser").firstLogin);

    useEffect(() => {
        window.addEventListener("close-modals-and-canvas", () => { setShowCanvas(false) });
    }, [])

    const hideCanvas = () => {
        setShowCanvas(false);
        setParentModalConfig({ show: 'Kužeľová charta' });
    };

    return (
        <>
            <Offcanvas show={showCanvas} onHide={() => hideCanvas()} placement="start" backdrop="static">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="color-kuzel">Vitaj medzi kužeľníkmi !</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="d-flex justify-content-center">
                        <img src={require('./../../../../resources/selfie.png')} alt="komunity" className='col-8' />
                    </div>
                    <div className="szh-menu__divider"></div>
                    <p>
                        Vitaj {localStorage.getItem("userName")},
                    </p>
                    <p>
                        sme nadšení, že sa pripájaš k našej komunite milovníkov cestných kužeľov! Či už si skúsený zberateľ alebo len začínaš, toto je ideálne miesto na zdieľanie tvojej vášne pre tieto ikonické bezpečnostné prvky našich ciest.
                    </p>
                    <p>
                        Od žiarivých oranžových kužeľov až po vzácne nálezy a kreatívne využitie, naša skupina je o oslave jedinečnej krásy a významu cestných kužeľov. Neváhaj predviesť svoju zbierku a podeľ sa o svoje najnovšie objavy.
                    </p>
                    <p>
                        Ešte raz ťa srdečne vítam v skupine nadšencov cestných kužeľov! Tvoja cesta svetom kužeľov bude ešte vzrušujúcejšia. Pripútaj sa a priprav sa na dobrodružstvo s chuťou kužeľa!
                    </p>
                    <p>
                        Kužeľu zdar !
                    </p>
                    <p>
                        S pozdravom, Veľkužeľníci
                    </p>
                    <div className="szh-menu__divider"></div>

                    <h4>Kužeľová charta</h4>
                    <p>
                        <span>Keďže odteraz už patríš medzi kužeľníkov, je na čase oboznámiť sa s našou </span>
                        <u role="button" className="opacityHover" onClick={() => setParentModalConfig({ show: 'Kužeľová charta' })}>kužeľovou chartou</u>.
                    </p>
                    <p>
                        <i>„Hráme poctivo a čestne“</i> - niekedy je náročné 100%-ne preukázať dodržiavanie pravidiel, preto je dôležité riadiť sa týmto mottom :)
                    </p>
                    <div className="szh-menu__divider"></div>

                    <h4>Komunity</h4>
                    <p>
                        Rád sa zabávaš so svojimi priateľmi či rodinou ? Vytvorte si svoju komunitu kužeľníkov a zdieľajte spoločne svoju vášeň a lásku ku kužeľom !
                    </p>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}