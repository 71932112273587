import React from "react";
import { Tab, Tabs, Card, ListGroup } from 'react-bootstrap';

export default function MerchModal() {
    return (
        <>
            <Tabs
                defaultActiveKey="clothes"
                id="uncontrolled-tab-example"
                className="mb-3 mt-1"
                justify
            >
                <Tab eventKey="clothes" title="Oblečenie">
                    <div className="row">
                        <div className="col-6 col-md-3 mb-3">
                            <Card>
                                <Card.Img variant="top" src="https://media.lastnightoffreedom.co.uk/images/shop/1/368/368_101_2.jpg" />
                                <Card.Body>
                                    <Card.Title>Klobúk</Card.Title>
                                    <Card.Text>
                                        biznis storočia
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>15 €</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <Card.Link href="https://www.lastnightoffreedom.co.uk/stag-night-shop/hats/traffic-cone-hat/" target="_blank" rel="noreferrer">tu si ma kuuup</Card.Link>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-6 col-md-3 mb-3">
                            <Card>
                                <Card.Img variant="top" src="https://cloud.cazaar.co.uk/ad47a40e-1a49-47f9-8540-96612de64c33.jpg" />
                                <Card.Body>
                                    <Card.Title>Kostým</Card.Title>
                                    <Card.Text>
                                        toto je top
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>45 €</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <Card.Link href="https://www.lastnightoffreedom.co.uk/stag-night-shop/costumes-&-fancy-dress/traffic-cone-costume/" target="_blank" rel="noreferrer">tu si ma kuuup</Card.Link>
                                </Card.Body>
                            </Card>
                        </div>

                        <div className="col-6 col-md-3 mb-3">
                            <Card>
                                <Card.Img variant="top" src="https://res.cloudinary.com/teepublic/image/private/s--o2mjO_IR--/t_Resized%20Artwork/c_crop,x_10,y_10/c_fit,w_326/c_crop,g_north_west,h_588,w_441,x_-5,y_-122/g_north_west,u_upload:v1446840618:production:blanks:ka6jobhx51rez10m7jnj,x_-414,y_-421/b_rgb:eeeeee/c_limit,f_auto,h_630,q_auto:good:420,w_630/v1472802204/production/designs/662999_1.jpg" />
                                <Card.Body>
                                    <Card.Title>Tričko</Card.Title>
                                    <Card.Text>
                                        toto je top
                                    </Card.Text>
                                </Card.Body>
                                <ListGroup className="list-group-flush">
                                    <ListGroup.Item>25 €</ListGroup.Item>
                                </ListGroup>
                                <Card.Body>
                                    <Card.Link href="https://www.teepublic.com/long-sleeve-t-shirt/662999-traffic-cone" target="_blank" rel="noreferrer">tu si ma kuuup</Card.Link>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="souvenirs" title="Suveníry">
                    <div className="col-6 col-md-3 mb-3">
                        <Card>
                            <Card.Img variant="top" src="https://www.nebex.sk/resize/e/800/800/files/canis/doplnky/kuzel-30-cm.jpg" />
                            <Card.Body>
                                <Card.Title>Kužeľ</Card.Title>
                                <Card.Text>
                                    toto je top
                                </Card.Text>
                            </Card.Body>
                            <ListGroup className="list-group-flush">
                                <ListGroup.Item>35 €</ListGroup.Item>
                            </ListGroup>
                            <Card.Body>
                                <Card.Link href="https://www.nebex.sk/p/5393/dopravny-kuzel-cxs?pp=d6c651dd" target="_blank" rel="noreferrer">tu si ma kuuup</Card.Link>
                            </Card.Body>
                        </Card>
                    </div>
                </Tab>
                <Tab eventKey="mother" title="Manka">
                    <br />
                    čo si akože čekal že tu bude ked si sa sem došol kuknut ?
                    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                </Tab>
            </Tabs>
        </>
    );
}