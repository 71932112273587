import React from "react";
import { Routes, Route } from "react-router-dom";

import Loggin from './homeModules/Loggin';
import Registration from './homeModules/Registration';
import RegistrationSuccess from './homeModules/RegistrationSuccess';
import RegistrationFinish from "./homeModules/RegistrationFinish";

import ResetPasswordRequest from './homeModules/ResetPasswordRequest';
import ResetPasswordRequestSuccess from './homeModules/ResetPasswordRequestSuccess';
import ResetPassword from './homeModules/ResetPassword';
import ResetPasswordSuccess from './homeModules/ResetPasswordSuccess';

export default function Home({ onSignIn }) {
    return (
        <div className="body">
            <div className="loginBody"></div>
            <div className="grad"></div>
            <div className="homePageContent">
                <div className="motto">
                    <div className="mottoContent">
                        <span className="mottoElement">Jeden <span>kužeľ</span></span>
                        <span className="mottoElement">Jedno <span>mesto</span></span>
                        <span className="mottoElement">Jedno <span>foto</span></span>
                    </div>
                </div>
                <div className="login">
                    <Routes>
                        <Route index element={<Loggin onSignIn={() => onSignIn()} />} />
                        <Route path="registration" element={<Registration />} />
                        <Route path="registration-success" element={<RegistrationSuccess />} />
                        <Route path="registration-authorize" element={<RegistrationFinish />} />
                        <Route path="reset-password-request" element={<ResetPasswordRequest />} />
                        <Route path="reset-password-request-success" element={<ResetPasswordRequestSuccess />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="reset-password-success" element={<ResetPasswordSuccess />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}