import React, { useState } from "react";
import { Form, Modal } from 'react-bootstrap';

import { url } from "../../../../../../config";
const axios = require("axios").default;

export default function GroupsModalCreate({ onCloseDetail }) {
    const [validated, setValidated] = useState(false);

    const handleSubmitCreate = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            let formData = new FormData(event.target);
            let formDataObj = Object.fromEntries(formData.entries());
            formDataObj.publicVisible = formDataObj.publicVisible === 'on';

            axios
                .post(url + `/private/rest/kuzel-group`, formDataObj)
                .then(() => {
                    onCloseDetail();
                    window.dispatchEvent(new CustomEvent("reload-my-groups"));
                })
                .catch((err) => {
                    console.log(err)
                });
        } else {
            setValidated(true);
        }
    };

    return (
        <Modal
            animation={false}
            show={true}
            size='xl'
            fullscreen="md-down"
            scrollable="false"
            onHide={() => onCloseDetail()}
            className='fullHeight'
            centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100 color-kuzel">
                    Vytvorenie komunity
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmitCreate}>
                    <Form.Group className="mb-1">
                        <Form.Label>Názov</Form.Label>
                        <Form.Control size="md" required="{true}" minLength={3} maxLength={30} name="name" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Popis</Form.Label>
                        <Form.Control as="textarea" rows={2} maxLength={95} required="{true}" name="description" />
                    </Form.Group>
                    <Form.Group className="mb-1">
                        <Form.Check
                            type='checkbox'
                            id='publicVisible'
                            name='publicVisible'
                            label='Verejná - jej obsah si budú môcť prezerať všetci prihlásení kužeľníci'
                            defaultChecked
                        />
                    </Form.Group>

                    <div className="szh-menu__divider mt-3" ></div>

                    <button type="submit" className="btn-kuzel w-100 mt-2">Vytvoriť</button>
                </Form>
            </Modal.Body>
        </Modal>
    );
}