import { useEffect, useState } from "react";
import { Store, Loading } from "./common";

import { Navigate, Route, Routes } from "react-router-dom";
import NotFoundPage from "./components/error_pages/NotFoundPage";

import { url } from "./config";
const axios = require("axios").default;

export function RouterChecker({ basicRoutes, adminRoutes }) {
    const [adminGrant, setAdminGrant] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(url + "/private/rest/perm/check/8de402fc-ccce-4f9c-ad25-fd8a4d76e9dd")
            .then(() => {
                Store.setValueOnlyToCache("adminAccess", true);
                setAdminGrant(true);
            })
            .catch(() => { })
            .then(() => {
                setLoading(false);
            });
    }, [])

    return (

        loading ?
            <Loading loadingType='fullscreenSpinner'></Loading>
            :
            (adminGrant ?
                <Routes>
                    {basicRoutes}
                    {adminRoutes}
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate to="404" />} />
                </Routes>
                :
                <Routes>
                    {basicRoutes}
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="*" element={<Navigate to="404" />} />
                </Routes>
            )
    )
}