import React, { useState } from "react";
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
} from 'cdbreact';

import { Store } from "../../common";

import { NavLink } from 'react-router-dom';

export default function Sidebar() {

    const [toggled, setToggled] = useState(true);

    return (
        <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
            <CDBSidebar textColor="#fff" backgroundColor="#333" toggled={toggled}>
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>} onClick={() => setToggled(!toggled)}>{ Store.getObject("loggedUser").nickname ?? Store.getObject("loggedUser").name }</CDBSidebarHeader>

                <CDBSidebarContent className="sidebar-content">
                    <CDBSidebarMenu>
                        <NavLink to="/admin/home" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="home">Domov</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/users" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="user">Kužeľníci</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/groups" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="users">Komunity</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/cone-events" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="images">Kužeľové udalosti</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/stories" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="icons">Dobrodružstvá</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/banners" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="newspaper">Bannery</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/roles" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="key">Roly</CDBSidebarMenuItem>
                        </NavLink>
                        <NavLink to="/admin/generalFunctions" onClick={() => setToggled(true)}>
                            <CDBSidebarMenuItem icon="tools">Všeobecné funkcie</CDBSidebarMenuItem>
                        </NavLink>
                    </CDBSidebarMenu>
                </CDBSidebarContent>

                <CDBSidebarFooter className="px-3">
                    <NavLink to="/"><button className="btn-kuzel mb-3 w-100">Odísť</button></NavLink>
                </CDBSidebarFooter>
            </CDBSidebar>
        </div>

    );
}