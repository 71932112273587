import React, { useEffect, useState } from "react";
import { Table, Collapse, Form } from 'react-bootstrap';
import { Loading, ModalContent, Pagination } from "../../../../common";

import BannersDetail from './BannersDetail';

import { format } from 'date-fns';
import { FaPlus } from "react-icons/fa";

import { url } from "../../../../config";
const axios = require("axios").default;

export default function Banners() {
    const [banners, setBanners] = useState({ page: 0, totalPage: 0, totalElements: 0, data: [] });

    const [modalConfig, setModalConfig] = useState({ show: false });

    const [loading, setLoading] = useState(true);

    const [detailData, setDetailData] = useState({});

    const [showFilter, setShowFilter] = useState(false);
    const [filterValue, setFilterValue] = useState("");

    useEffect(() => {
        loadBanners(0);
    }, [])

    const loadBanners = (page, search = "") => {
        axios.get(url + "/private/rest/admin/custom-content/all?page=" + page + "&size=10&search=" + search)
            .then(res => {
                setBanners(res.data);
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
            });
    }

    const openDetail = (data) => {
        if (data.id === detailData.id) {
            setDetailData({});
        }
        else {
            getDetailData(data);
        }
    }

    const getDetailData = (data) => {
        axios
            .get(url + "/private/rest/custom-content/detail/" + data.id)
            .then((res) => {
                setDetailData(res.data);
                //setLoading(false);
            })
            .catch((err) => {
                setModalConfig({
                    show: true,
                    headerContent: null,
                    bodyContent: <div className="text-center" style={{ fontSize: '15px' }}><b>Nepodarilo sa načítať korektne</b></div>,
                    footerContent: <button className="btn-kuzel secondary" onClick={() => setModalConfig({ show: false })}>Zrušiť</button>
                });
            });
    }

    return (
        <>
            <button className="btn-kuzel secondary w-100 mb-3" onClick={() => setShowFilter(!showFilter)}>Filter</button>
            <div>
                <Collapse in={showFilter} className="w-100">
                    <div>
                        <div className="w-100 p-2 pt-0 mb-2">
                            <Form.Group className="col-12 col-lg-6">
                                <Form.Label>Názov</Form.Label>
                                <Form.Control size="md" type="text" required="{true}" name="name"
                                    onChange={e => {
                                        loadBanners(0, e.currentTarget.value);
                                        setFilterValue(e.currentTarget.value);
                                    }} />
                            </Form.Group>
                        </div>
                    </div>
                </Collapse>
            </div>

            <div className="add-new-button">
                <button type="submit" className="btn btn-success" onClick={() => setDetailData({ id: "CREATE" })}><FaPlus className="me-1 mb-1" />Vytvoriť banner</button>
            </div>

            <Collapse in={detailData.id === "CREATE"} className="w-100">
                <div>
                    {
                        detailData.id === "CREATE" && (
                            <BannersDetail initDetailData={detailData} loadBanners={() => loadBanners(banners.page, filterValue)} closeDetail={() => setDetailData({})}></BannersDetail>
                        )
                    }
                </div>
            </Collapse>

            <Table>
                <thead>
                    <tr>
                        <th className="col"></th>
                        <th className="col-6">Názov</th>
                        <th className="col-6">Aktívny</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? (
                            <tr className="text-center">
                                <td colSpan="100%" className="border-0">
                                    <Loading marginTop='170px'></Loading>
                                </td>
                            </tr>
                        ) : (
                            banners.data.map((group, index) => {
                                return (
                                    <>
                                        <tr key={group.id} onClick={() => openDetail(group)} role="button">
                                            <td>{index + 1 + banners.page * 10}.</td>
                                            <td>{group.name}</td>
                                            <td>{group.active ? ("Aktívny do " + format(new Date(group.publishedTo), 'dd.MM.yyyy')) : "Neaktívny"}</td>
                                        </tr>
                                        <tr key={group.id + "opc"} className="opacityHoverNone">
                                            <td colSpan="100%" className="border-0 p-0">
                                                <Collapse in={group.id === detailData.id} className="w-100">
                                                    <div>
                                                        {
                                                            group.id === detailData.id && (
                                                                <BannersDetail initDetailData={detailData} loadBanners={() => loadBanners(banners.page, filterValue)} closeDetail={() => setDetailData({})}></BannersDetail>
                                                            )
                                                        }
                                                    </div>
                                                </Collapse>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                        )
                    }
                </tbody>
            </Table>

            <div className="d-flex justify-content-center">
                <Pagination page={banners.page} totalPages={banners.totalPage} onPageChange={page => loadBanners(page, filterValue)} />
            </div>

            <ModalContent modalConfig={modalConfig} onClose={(e) => setModalConfig({ show: false })} ></ModalContent>
        </>
    );
}