import React from "react";

import { Link } from "react-router-dom";

export default function AdminHome() {
    return (
        <>
            <h1 className="mt-1">Admin</h1>

            <div className='row px-3 justify-content-center'>
                <img src={require('./../../../../resources/adminCone.png')} alt="adminCone" className='col-8 col-md-3 px-3' style={{ aspectRatio: '1.53' }} />
            </div>

            <span>
                Vitajte v admin sekcii stánky kužel.online! Táto platforma vám poskytuje všetky potrebné nástroje na efektívne a pohodlné riadenie tohto zábavného a návykového koníčka. S naším intuitívnym rozhraním môžete jednoducho spravovať obsah, monitorovať výsledky a interagovať s komunitou kužeľníkov. Bez ohľadu na to, či ste nováčik alebo skúsený správca, naša admin časť vám umožní plne využiť potenciál tejto fascinujúcej činnosti. Čakáme na vás, aby sme vám pomohli vytvárať a udržiavať nezabudnuteľné kužeľnícke zážitky!
            </span>

            <div className="szh-menu__divider my-3"></div>

            <Link to="/admin/users">
                <button className="btn-kuzel secondary opacityHover m-1">Kužeľníci</button>
            </Link>
            <Link to="/admin/groups">
                <button className="btn-kuzel secondary opacityHover m-1">Komunity</button>
            </Link>
            <Link to="/admin/cone-events">
                <button className="btn-kuzel secondary opacityHover m-1">Kužeľové udalosti</button>
            </Link>
            <Link to="/admin/stories">
                <button className="btn-kuzel secondary opacityHover m-1">Dobrodružstvá</button>
            </Link>
            <Link to="/admin/banners">
                <button className="btn-kuzel secondary opacityHover m-1">Bannery</button>
            </Link>
            <Link to="/admin/roles">
                <button className="btn-kuzel secondary opacityHover m-1">Roly</button>
            </Link>
            <Link to="/admin/generalFunctions">
                <button className="btn-kuzel secondary opacityHover m-1">Všeobecné funkcie</button>
            </Link>
        </>
    );
}