import React, { useEffect, useState } from "react";

import Loading from './utils/Loading';
import Store from "./utils/Store";
import ConeComponentsService from "./utils/ConeComponentsService";
import ConeImageDetail from "./ConeImageDetail";
import UserDetail from "./UserDetail";

import { format } from "date-fns";
import { Carousel } from 'react-responsive-carousel';

import { ReactComponent as KuzelLikeIcon } from "../resources/kuzelLike.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { url } from "../config";
import { defaultWidth } from "./utils/ImageConstants";
const axios = require("axios").default;

export default function ConeImagesGallery({ user }) {
    const [coneEvents, setConeEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [numberOfConeEvents, setNumberOfConeEvents] = useState(0);
    const [highestOpenedImage, setHighestOpenedImage] = useState(1);
    const [activeCarouselIndex, setActiveCarouselIndex] = useState(0);

    const [userDetailId, setUserDetailId] = useState(null);

    const [openDetail, setOpenDetail] = useState(false);
    const [likes, setLikes] = useState({ summary: { like: 0 }, likeByMe: null });

    const likeClickHandler = () => {
        setLikes({ ...likes, likeByMe: likes.likeByMe === "LIKE" ? null : "LIKE" });
        ConeComponentsService.addUpdateOrRemoveLike(coneEvents[activeCarouselIndex].id, "LIKE", (likes) => {
            setLikes(likes);
            coneEvents[activeCarouselIndex].likes = likes;
        })
    }

    const getLikeCountByType = (type) => {
        let count = likes?.summary[type];
        if (count && count > 0) return count;
        return 0;
    }

    //po zmene like a comment v modálnom okne tej istej udalosti sa aktualizuju udaje v popupe  
    const onHideConeEventModal = (likesAndComments) => {
        if (likesAndComments?.likes) {
            setLikes(likesAndComments.likes);
            coneEvents[activeCarouselIndex].commentCount = likesAndComments.comments.length;
        }
        setOpenDetail(false);
    }

    useEffect(() => {
        setLoading(true);

        loadData(0);
    }, [])

    useEffect(() => {
        if (coneEvents[activeCarouselIndex]?.likes) {
            setLikes(coneEvents[activeCarouselIndex].likes);
        }
    }, [activeCarouselIndex, coneEvents])

    const onCarouselChangeHandler = (index) => {
        setActiveCarouselIndex(index);

        if (index > highestOpenedImage) {
            setHighestOpenedImage(index);
            if ((highestOpenedImage + 1) % 10 === 7) {
                let page = Math.ceil((highestOpenedImage + 1) / 10);
                loadData(page);
            }
        }
    }

    const loadData = (page) => {
        let groupId = Store.getObject("selectedGroup").id;
        let baseUrl = "/private/rest/kuzel";

        if (user) {
            baseUrl += "/by-kuzelnik/" + user.id + `${groupId ? `/${groupId}` : ''}`;
        }
        else {
            baseUrl += `/newest${groupId ? `/by-group/${groupId}` : ''}`;
        }

        axios.get(url + baseUrl + "?page=" + page + "&size=10")
            .then(res => {
                setNumberOfConeEvents(res.data.totalElements);
                setConeEvents([...coneEvents, ...res.data.data]);
                setLoading(false);
            })
            .catch(err => { });
    }

    return (
        <div className="justify-content-center row">
            {
                loading ? (
                    <div className="text-center">
                        <div className="d-inline-block">
                            <Loading marginTop='150px'></Loading>
                        </div>
                    </div>
                ) : (
                    <>
                        {
                            coneEvents.length > 0 && (
                                <div className="col-12 col-md-8 col-xl-4">
                                    <Carousel
                                        showThumbs={false}
                                        showIndicators={false}
                                        showCarousel={false}
                                        useKeyboardArrows={true}
                                        autoFocus={true}
                                        emulateTouch={true}
                                        transitionTime={300}
                                        onChange={(e) => onCarouselChangeHandler(e)}
                                        dynamicHeight={true}
                                    >
                                        {Array(numberOfConeEvents).fill(null).map((number, index) => {
                                            return (
                                                (coneEvents[index] && index < highestOpenedImage + 3) && (
                                                    <div key={index}>
                                                        <img alt={coneEvents[index].city} src={url + coneEvents[index].photo + (defaultWidth > 0 ? `?width=${defaultWidth}` : "")} />
                                                    </div>
                                                )
                                            )
                                        })}
                                    </Carousel>

                                    <div className="bg-kuzel" style={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                        <div className="position-relative w-100 m-0" style={{ top: '-32px', height: 0 }}>
                                            <div className="justify-content-between p-0 px-2" style={{ display: 'flex', backgroundColor: 'rgba(0, 0, 0, .5)', padding: '3px 10px 0' }}>
                                                <span>
                                                    <span className="opacityHover" role="button" onClick={() => likeClickHandler()}>
                                                        <span style={{ fontSize: '1.2rem' }}>
                                                            {getLikeCountByType("LIKE")}
                                                        </span>
                                                        <KuzelLikeIcon className={`opacityHover mb-1 ${likes.likeByMe === 'LIKE' ? " kuzelLikeActive" : ""} `} style={{ height: '1.4rem' }} />
                                                    </span>

                                                    <span className="opacityHover ms-2" role="button" onClick={() => setOpenDetail(true)}>
                                                        <span style={{ fontSize: '1.2rem' }}>
                                                            {coneEvents[activeCarouselIndex].commentCount}
                                                        </span>
                                                        <FontAwesomeIcon icon="fa-regular fa-comments" className="ms-1" style={{ height: '1.2rem' }} />
                                                    </span>
                                                </span>

                                                <span className="fs-6 mt-1">
                                                    {coneEvents[activeCarouselIndex].created ? format(new Date(coneEvents[activeCarouselIndex].created), 'dd.MM.yyyy') : ""}
                                                </span>
                                            </div>

                                            {
                                                openDetail && (
                                                    <ConeImageDetail detailData={coneEvents[activeCarouselIndex]} onHideModal={(e) => onHideConeEventModal(e)} ></ConeImageDetail>
                                                )
                                            }
                                        </div>

                                        <div className="m-2">
                                            {(coneEvents[activeCarouselIndex]?.city ?? '\u00A0') + (coneEvents[activeCarouselIndex]?.country ? ", " + coneEvents[activeCarouselIndex]?.country?.toUpperCase() : "")}
                                        </div>

                                        <div className="szh-menu__divider bg-white"></div>

                                        {
                                            coneEvents[activeCarouselIndex]?.description ? (
                                                <>
                                                    <div className="mx-2">
                                                        {
                                                            coneEvents[activeCarouselIndex]?.kuzelniks.map((kuzelnik, index) => {
                                                                return (
                                                                    <>
                                                                        <span key={index} className="me-1 d-inline-block" role="button" onClick={() => setUserDetailId(kuzelnik.id)}>{kuzelnik.nickname}{index + 1 !== coneEvents[activeCarouselIndex]?.kuzelniks?.length ? ',' : ''}</span>
                                                                        {
                                                                            userDetailId === kuzelnik.id && (
                                                                                <UserDetail user={kuzelnik} onHideModal={() => setUserDetailId(null)} ></UserDetail>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    <div className="szh-menu__divider bg-white"></div>

                                                    <div className="mx-2 pb-2">
                                                        <span>{coneEvents[activeCarouselIndex].description}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="mx-2 pb-2">
                                                    {
                                                        coneEvents[activeCarouselIndex]?.kuzelniks.map((kuzelnik, index) => {
                                                            return (
                                                                <>
                                                                    <span key={index} className="me-1 d-inline-block" role="button" onClick={() => setUserDetailId(kuzelnik.id)}>{kuzelnik.nickname}{index + 1 !== coneEvents[activeCarouselIndex]?.kuzelniks?.length ? ',' : ''}</span>
                                                                    {
                                                                        userDetailId === kuzelnik.id && (
                                                                            <UserDetail user={kuzelnik} onHideModal={() => setUserDetailId(null)} ></UserDetail>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
}